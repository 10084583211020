/*----------------------------------------------------------------*\
	404 TEMPLATE
\*----------------------------------------------------------------*/
body.page-error {
  .card form {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    margin: 0;

    label {
      position: absolute;
      top: 0;
      left: 2px;
      padding: 0;
      transform: translateY(calc(-100% - 8px));
    }

    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    button {
      min-width: 0;
      margin: 0;
      padding: 14px 16px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-color: var(--Blue);
      box-shadow: none;

      img {
        display: block;
        width: 20px;
        height: 20px;
      }
    }
  }
}
