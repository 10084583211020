.accordions {
  details {
    &:nth-child(odd) {
      border-color: var(--Blue);
    }

    &:nth-child(even) {
      border-color: var(--Purple);
    }
	
	  &:nth-child(3) {
      border-color: var(--Yellow);
    }
	
    summary {
      font-size: 28px;
      font-weight: normal;
      padding: var(--Large) 90px var(--Large) var(--Large);
      color: var(--BlueDark);
      font-family: 'lexia', serif;
    }

    > div {
      padding: 0 var(--Large) var(--Medium) var(--Large);
    }

    &.green-item {
      border-color: var(--Green) !important;
    }

    &.blue-dark-item {
      border-color: var(--BlueDark) !important;
    }

    &.blue-item {
      border-color: var(--Blue) !important;
    }

    &.red-item {
      border-color: var(--Red) !important;
    }

    &.yellow-item {
      border-color: var(--Yellow) !important;
    }

    &.purple-item {
      border-color: var(--Purple) !important;
    }

    &.top-ribbon {
      border-top: 12px solid;
      border-left: none;
    }
  }
}

.green-item details {
  border-color: var(--Green) !important;
}

.blue-item details {
  border-color: var(--BlueDark) !important;
}

.lightBlue-item details {
  border-color: var(--Blue) !important;
}

.red-item details {
  border-color: var(--Red) !important;
}

.yellow-item details {
  border-color: var(--Yellow) !important;
}

.purple-item details {
  border-color: var(--Purple) !important;
}

.accordion-rounded {
  border: 1px solid #ccc !important;
  border-radius: 40px;
  box-shadow: none;
  margin: 30px 20px;
}
.accordion-rounded summary {
  padding: 20px 30px 15px 30px !important;
  line-height: 1;
}
.accordion-rounded summary::after {
  box-shadow: none;
  top: 5px;
  background-color: transparent !important;
  color: var(--BlueDark) !important;
  content: '›'
}

details[open].accordion-rounded summary:after {
  content: '⌄'
}

