#resourcefilters {
	margin-bottom: 40px;

	h3 {
		margin-top: 0;
	}

	ul li {
		a {
			display: flex !important;
			align-items: center;
			padding-top: 10px !important;
			padding-bottom: 10px !important;
			cursor: pointer;

			span.close {
				display: none;
				margin-left: auto;
				margin-right: 0 !important;
				padding: 0 5px;
				font-size: 24px;
				height: 25px;
				align-items: center;
				justify-content: center;
			}

			&.selected {
				background-color: var(--Blue);
				color: white !important;

				span.close {
					display: flex;
				}
			}

			span {
				margin-right: 20px;
			}

			&.favorite {
				color: #e26456;
			}

			&.training,
			&.library {
				color: #7ac042;
			}

			&.comprehensive {
				color: #2f86d8;
			}

			&.intermidate {
				color: #b26cd5;
			}

			&.awareness {
				color: #e8a202;
			}

			span.img {
				min-width: 40px;
				width: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			span.line {
				min-width: 40px;
				width: 40px;
				height: 8px;
				display: block;

				&-comprehensive {
					background-color: #2f86d8;
				}

				&-intermidate {
					background-color: #b26cd5;
				}

				&-awareness {
					background-color: #e8a202;
				}
			}
		}
	}

	.border-bottom {
		border-bottom: 2px solid #d4d4d4;
	}

	.content-filters {
		display: flex;
		justify-content: space-between;
	}


	.radio-container {
		display: block;
		position: relative;
		padding-left: 35px;
		margin-bottom: 12px;
		cursor: pointer;
		color: var(--Black);
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/* Hide the browser's default radio button */
	.radio-container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}

	/* Create a custom radio button */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #eee;
		border-radius: 50%;
	}

	/* On mouse-over, add a grey background color */
	.radio-container:hover input~.checkmark {
		background-color: #ccc;
	}

	/* When the radio button is checked, add a blue background */
	.radio-container input:checked~.checkmark {
		background-color: #2196F3;
	}

	/* Create the indicator (the dot/circle - hidden when not checked) */
	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	/* Show the indicator (dot/circle) when checked */
	.radio-container input:checked~.checkmark:after {
		display: block;
	}

	/* Style the indicator (dot/circle) */
	.radio-container .checkmark:after {
		top: 7px;
		left: 7px;
		width: 11px;
		height: 11px;
		border-radius: 50%;
		background: white;
	}
}

.digital-product-wrapper {
	padding: 30px 30px 20px 30px;
	margin-bottom: 40px;
	height: fit-content;
	background-color: var(--GreyLight);

	@media screen and (max-width: $phone) {
		padding: 30px 15px;
	}
}

.digital-products {
	display: block !important;
	padding: 0 !important;

	.slick-track {
		display: flex !important;
	}

	.slick-slide {
		height: inherit !important;
	}

	.slick-arrow {
		min-width: 0 !important;
		margin: 0 !important;
		box-shadow: none;
		width: 40px;
		height: 100%;
		z-index: 99;

		&::before {
			font-size: 50px;
			color: #464646;
			box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 9px;
			width: 45px;
			height: 45px;
		}

		&.slick-next {
			right: -26px;
		}

		&.slick-prev {
			left: -30px;
		}
	}

	li {
		margin: 8px;
		width: 280px;
		box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.35);

		@media screen and (max-width: $phone) {
			width: 270px;
		}

		&::before {
			display: none;
		}

		a {
			display: flex;
			flex-direction: column;
			height: 100%;
			text-decoration: none;
			background-color: white;

			&:hover,
			&:focus {
				color: var(--White);
				background-color: var(--Red);

				h2,
				.product-meta,
				.price,
				.price del,
				.price ins {
					color: var(--White);
				}
			}
		}

		>*:not(h2) {
			flex-grow: 0;
		}

		span.onsale {
			display: flex;
			position: absolute;
			top: 8px;
			right: 8px;
			justify-content: center;
			align-items: center;
			padding: var(--Small) var(--Medium);
			border-radius: 4px;
			color: Var(--Black);
			font-weight: 800;
			font-size: 12px;
			line-height: 1;
			text-transform: uppercase;
			background-color: var(--Green);
		}

		img {
			width: 300px;
			height: 200px;
		}

		h2 {
			flex-grow: 1;
			margin: var(--Medium) 0 var(--Large) 0;
			padding: 0 var(--Medium);
			font-style: normal;
			font-weight: 800;
			font-size: 17px;
			line-height: 1.1;
			font-family: "Karla", sans-serif;
		}

		p {
			text-align: center;
			margin-bottom: 0;
		}

		.product-meta {
			padding: 0 var(--Medium);
			color: var(--Grey);
			font-size: 14px;
		}

		span.price {
			display: block;
			margin: var(--Small) 0 var(--Medium) 0;
			padding: 0 var(--Medium);
			color: var(--Black);
			font-style: normal;
			font-weight: 300;
			font-size: 24px;
			line-height: 1.1;
			font-family: "lexia", serif;

			@media screen and (min-width: $phone) {
				font-size: 28px;
			}

			del {
				display: block;
				margin: var(--xSmall) 0;
				color: var(--Grey);
				font-size: 14px;
			}

			ins {
				text-decoration: none;
			}
		}
	}

	&.full-width {
		.slick-list {
			padding: 0 5px;
		}

		li {
			width: 920px;

			@media screen and (max-width: $phone) {
				width: 240px;
			}
		}

		p {
			padding: 80px 20px;
		}
	}

	&.async-course-cards {
		.slick-list {
			padding: 0 5px;
		}

		li {
			border: none;
			padding: 0 20px;
			text-align: center;

			&::before {
				display: block;
				position: relative;
				left: -20px;
				width: calc(100% + 40px);
				height: 24px;
				margin-bottom: 18px;
				content: '';
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
			}

			&:nth-child(n)::before {
				background-image: url('https://s3.amazonaws.com/imse-qa-new/images/pattern_yellow_small.jpg');
			}

			&:nth-child(2n)::before {
				background-image: url('https://s3.amazonaws.com/imse-qa-new/images/pattern_green_big.png');
			}

			&:nth-child(3n)::before {
				background-image: url('https://s3.amazonaws.com/imse-qa-new/images/pattern_red_big.png');
			}

			h2 {
				font-size: 24px;
				flex-grow: inherit;
				margin-bottom: 4px;
			}

			button {
				margin: 10px auto 25px;
			}

			a {
				margin: 10px auto 25px;
				height: auto;

				&:hover,
				&:focus {
					background-color: var(--BlueDark);
				}
			}
		}
	}

	&.district-option-cards {
	    max-width: 100%;

		.slick-list {
			padding: 0 5px;
		}

		li {
			border: none;
			padding: 0 20px;
			text-align: center;
			width: 498px;
			background: #ffffff;

			&::before {
				display: block;
				position: relative;
				left: -20px;
				width: calc(100% + 40px);
				height: 24px;
				margin-bottom: 18px;
				content: '';
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
			}

			&:nth-child(n)::before {
				background-image: url('https://s3.amazonaws.com/imse-qa-new/images/pattern_red_big.png');
			}

			&:nth-child(2n)::before {
				background-image: url('https://s3.amazonaws.com/imse-qa-new/images/pattern_yellow_small.jpg');
			}

			&:nth-child(3n)::before {
				background-image: url('https://s3.amazonaws.com/imse-qa-new/images/pattern_blue_small.jpg');
			}

			&:nth-child(4n)::before {
				background-image: url('https://s3.amazonaws.com/imse-qa-new/images/pattern_green_big.png');
			}

			h2 {
				font-size: 24px;
				flex-grow: inherit;
				margin-bottom: 4px;
			}

			button {
				margin: 25px auto;
			}

			a {
				margin: 25px auto;
				height: auto;

				&:hover,
				&:focus {
					background-color: var(--BlueDark);
				}
			}
		}
	}
}

.product.digital img {
	width: 100%;
	height: auto;
}

.product.digital {
	border: 7px solid white;
	background-color: white;
	box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.35);

	a {
		color: black;
		background-color: white !important;

		h2 {
			color: black !important;
			padding-left: 10px;
		}
	}

	span.price {
		padding-left: 10px;
		padding-right: 10px;
		width: 100%;
		margin-bottom: 10px;
		margin-top: 0;
	}

	.media-wrapper {
		text-align: center;

		&>a {
			margin: 0 auto;
		}
	}

	&.digital-comprehensive {
		border: 7px solid #2f86d8;
	}

	&.digital-awareness {
		border: 7px solid #e8a202;
	}

	&.digital-morphology {
		border: 7px solid #b26cd5;
	}

	&.disabled {
		opacity: 0.4;
	}

	div.flex {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;

		&>button {
			min-width: 80px;
			min-height: 30px;
		}

		&>* {
			margin-right: 0;
		}
	}

	.in-library {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		color: #7ac042;
		font-size: 16px;
		text-transform: uppercase;
		font-weight: bold;

		img {
			width: 20px;
			height: 20px !important;
			object-fit: contain;
			margin-right: 10px;
		}
	}

	.product-title {
		flex: 1;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		.tools {
			padding-top: 16px;
			padding-right: 6px;
			padding-left: 10px;
			cursor: pointer;
			display: flex;

			a {
				margin-left: 10px;
				position: relative;
			}

			img {
				min-width: 20px;
				height: 20px !important;
				object-fit: contain;
			}
		}
	}
}

.digital-item {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.digital-resource-banner {
	box-shadow: 0 7px 27px 0 rgba(0, 0, 0, 0.13);
	padding: 15px 20px;
	font-size: 20px;
	border-left: 8px solid #e8a202;
}

.share-box {
	position: relative;

	button {
		position: absolute;
		right: 6px;
		margin: 0;
		top: 6px;
	}

	.tooltiptext {
		visibility: hidden;
		width: 165px;
		background-color: #555;
		color: #fff;
		text-align: center;
		border-radius: 6px;
		padding: 5px;
		position: absolute;
		z-index: 1;
		bottom: 150%;
		left: 50%;
		white-space: nowrap;
		margin-left: -80px;
		opacity: 0;
		transition: opacity 0.3s;

		&::after {
			content: "";
			position: absolute;
			top: 100%;
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: #555 transparent transparent transparent;
		}
	}

	&:hover .tooltiptext {
		visibility: visible;
		opacity: 1;
	}
}

.training-products {
	&.digital-products li {
		width: 296px;
	}

	.product.digital {
		border: none;
	}

	img {
		border-bottom: 8px solid var(--Blue);
		object-fit: cover;
		object-position: center;
		height: 200px !important;
	}

	h2 {
		margin: 20px auto;
		text-align: center;
	}

	button {
		margin: 0 auto 15px;
	}
}

.participants-list {
	.participant-item {
		font-size: 20px;
		padding: 20px 30px;
		box-shadow: 0 2px 6px 0 #70707045;
		line-height: 1.3;
		margin: 0px 25px 5px;
		background: #ffffff;
		min-height: 92px;
		display: flex !important;
		align-items: center;

		&.slick-slide {
			border: none;
			border-left: 8px solid #dddddd;
		}

		p {
			margin: 0;
		}

		b {
			color: var(--BlueDark);
		}

		&.green-item {
			border-color: var(--Green);
		}

		&.purple-item {
			border-color: var(--Purple);
		}

		&.red-item {
			border-color: var(--Red);
		}

		&.blue-item {
			border-color: var(--Blue);
		}

		&.darkblue-item {
			border-color: var(--BlueDark);
		}

		&.yellow-item {
			border-color: var(--Yellow);
		}

		&.slick-current+.slick-active {
			transform: scale(1.1);
			margin: -10px 25px -5px;
			position: relative;
			left: 28px;
			width: 86% !important;
		}
	}

	.slick-arrow {
		min-width: 0 !important;
		margin: 0 !important;
		box-shadow: none;
		left: auto;
		right: -38px;
		transform: rotate(90deg);

		&::before {
			font-size: 35px;
			color: var(--BlueDark);
		}

		&.slick-prev {
			top: 94px;
		}

		&.slick-next {
			top: 145px;
		}
	}
}


/* Decodable Readers*/

.decodable-readers {
	.dr-tabs {
		overflow: auto;
		margin-top: 20px;
		display: flex;
		align-items: flex-end;
		height: 80px;
	}

	.dr-footer {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-top: 52px;

		span {
			margin: 0 20px;
			white-space: nowrap;
		}
	}

	/* Style the buttons inside the tab */
	.dr-tabs button {
		background-color: none;
		float: left;
		outline: none;
		cursor: pointer;
		padding: 10px 40px;
		transition: 0.3s;
		font-size: 17px;
		border-radius: 4px 4px 0px 0px;
		border: 2px solid white;
		border-bottom: none;
		box-shadow: none;
		position: relative;
		min-width: 190px;

		&:hover {
			background-color: white;
		}

		&.blue {
			border-color: #2F86D8;
			color: #2F86D8;
		}

		&.blue.active {
			background-color: #2F86D8;
			color: white;
		}

		&.green {
			border-color: #82C34D;
			color: #82C34D;
		}

		&.green.active {
			background-color: #82C34D;
			color: white;
		}

		&.orange {
			border-color: #E8A202;
			color: #E8A202;
		}

		&.orange.active {
			background-color: #E8A202;
			color: white;
		}

		&.active {
			padding-bottom: 16px;
		}

		&.active::after {
			content: '';
			width: 0px;
			height: 0px;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-top: 8px solid white;
			position: absolute;
			bottom: 3px;
			left: calc(50% - 6px);
		}
	}

	/* Style the tab content */
	.dr-tabs-content {
		display: none;
		padding: 45px;
		border: 4px solid #ccc;

		&.blue {
			border-color: #2F86D8;

			a {
				color: #2F86D8
			}
		}
		
		&.green {
			border-color: #82C34D;

			a {
				color: #82C34D
			}
		}
		
		&.orange {
			border-color: #E8A202;

			a {
				color: #E8A202
			}
		}

		.links {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			a {
				display: block;
				margin-bottom: 15px;
				width: 30%;
				cursor: pointer;

				@media screen and (max-width: $tablet) {
					width: 100%;
					text-align: center;
				}
			}
		}

		.dr-bgs {
			display: flex;
			flex-wrap: wrap;
			margin-top: 80px;

			@media screen and (max-width: $tablet) {
				display: none;
			}

			img {
				width: 25%;
				margin: 0 40px;
			}
		}
	}

	.set-logo {
		text-align: center;
		margin: 30px 0;
	}
}