.virtual-coaching-table {
  width: 100%;
  border-collapse: collapse;

  tr {
    border-top: 4px solid var(--GreyLight);

    td {
      @media screen and (max-width: $phone) {
        display: block;
      }
    }
  }

  .button-group {
    @media screen and (max-width: $phone) {
      margin-bottom: 20px;
    }
  }
}


.postTraining-carousel {
  @media screen and (max-width: $phone) {
    display: none;
  }
}

.postTraining-carousel .testimonials-wrapper {
  background-image: none;
  background-color: #86b346;
  text-align: center;
  padding-top: 5rem;
}

.postTraining-carousel .story-content {
  background-color: transparent !important;
  color: #fff;
  text-align: center;
  min-height: 0px !important;
}

.postTraining-carousel .story-content div p {
  margin-bottom: 0px;
}

.postTraining-carousel .story-text,
.postTraining-carousel h3 {
  color: #fff !important;
  white-space: normal !important;
}

.courseDescription i {
  font-size: 14px;
  letter-spacing: 0.25px;
  color: #4f4f4f;
  margin-left: 0.5rem;
  font-style: normal;
  position: relative;
  top: -2px;

  @media screen and (max-width: $tablet) {
    margin-left: 0rem;
    display: block;
    top: 2px;
  }
}

.icon-List {
  display: flex;
  list-style-type: none !important;
  justify-content: space-between;
  margin: 0px 1rem 3rem 0px !important;
  padding: 0px;
  text-align: center;
}

.icon-List li {
  list-style-type: none !important;
  position: static;
  padding: 0px;
}