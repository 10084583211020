/*-----------------------------------*\
	NOTIFICATION BAR
\*-----------------------------------*/
.notification-bar {
  z-index: 999999;
  position: relative;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  background-image: url(../images/Banner-BG-Green.png);
  background-position: center;

  &.note-on {
    // max-height: 100vh;
  }

  &.top-notification {
    max-height: initial;

    >div {
      height: 42px;
    }
  }

  >div {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 95%;
    max-width: 1169px;
    margin: 0 auto;
    padding: var(--Small) 0;
  }

  p {
    margin: 0;
    color: var(--White);
    font-weight: 100;
    font-size: 14px;
    line-height: 1.1;
    letter-spacing: 0.5px;
  }

  a {
    color: var(--White);
    text-decoration: underline;
    margin-left: 16px;

    &:hover,
    &:focus {
      color: var(--White);
    }
  }

  button {
    flex-shrink: 0;
    min-width: 0;
    margin-right: var(--Medium);
    padding: 4px 8px;
    background-color: white;
    box-shadow: none;

    &:last-child {
      margin: 0 0 0 var(--Medium);
    }

    &:hover,
    &:focus {
      background-color: var(--Blue);
      box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.5);
    }

    &.is-icon {
      padding: 0;
      background-color: transparent;
    }

    svg {
      display: block;
      width: 20px;
      height: 20px;
      fill: var(--White);
    }
  }
}

/*-----------------------------------*\
	COOKIE BAR
\*-----------------------------------*/
.cookie-useage-notification {
  position: fixed;
  bottom: 0;
}

/*-----------------------------------*\
	Notification banner
\*-----------------------------------*/
.notification-banner {
  position: relative;
  display: flex;
  flex-direction: column;

  .text-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--Medium);
    color: white;
    background-color: #f2f2af;

    h5 {
      margin: 0;
    }

    a {
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.notification-banner-content {
  background: linear-gradient(to left, #62a472, #6b8b45);
  position: relative;
  transition: 0.3s all;
  overflow: hidden;
  height: 0;
  max-height: 0;

  &.is-collapse {
    height: auto;
    max-height: 100%;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }

  .close-btn,
  .footer button {
    color: white;
    text-decoration: none;
    cursor: pointer;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
  }

  .content {
    margin: 0 20px;
    display: flex;
    justify-content: center;
    ul {
      padding: 0;
      width: fit-content;
      margin-top: 40px;
      min-width: 640px;

      @media screen and (max-width: $tablet) {
        min-width: 0;
      }  
    }
    li {
      background-color: white;
      margin: 10px 0;
      border-radius: 40px;
      font-size: 18px;
      width: 100%;
      padding: 16px 20px 16px 40px;

      &::before {
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}