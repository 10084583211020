/*-----------------------------------*\
	TESTIMONIES
\*-----------------------------------*/
section.testimonies {
  position: relative;
  width: 100%;
  min-height: 735px;

  @media screen and (max-width: $tablet) {
    min-height: 800px;
  }

  @media screen and (min-width: $phone) {
    min-height: 606px;

    &.standard {
      width: 90%;
      max-width: 960px;
      margin-right: auto;
      margin-left: auto;
    }

    &.is-narrow {
      width: 90%;
      max-width: 640px;
      margin-right: auto;
      margin-left: auto;
    }

    &.is-wide {
      width: 90%;
      max-width: 1024px;
      margin-right: auto;
      margin-left: auto;
    }

    &.is-extra-wide {
      width: 90%;
      max-width: 1200px;
      margin-right: auto;
      margin-left: auto;
    }

    &.is-full-width {
      width: 100%;
      max-width: 100%;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &::before {
    display: block;
    z-index: 10;
    position: absolute;
    bottom: 20%;
    left: 0;
    width: 48px;
    height: 60px;
    content: '';
    background-color: var(--GreyLight);
    background-image: url(../images/arrow_left.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40px;
  }

  &::after {
    display: block;
    z-index: 10;
    position: absolute;
    right: 0;
    bottom: 20%;
    width: 48px;
    height: 60px;
    content: '';
    background-color: var(--GreyLight);
    background-image: url(../images/arrow_right.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40px;
  }

  @media screen and (min-width: $tablet) {
    padding-bottom: 0;

    &::before,
    &::after {
      display: none;
    }
  }

  h2 {
    text-align: center;
  }

  ul {
    display: flex;
    scroll-behavior: smooth;
    position: absolute;
    top: 80px;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: space-between;
    align-items: flex-end;
    height: 650px;
    margin: 0;
    padding: 0;
    overflow-x: scroll;
    list-style: none;

    scroll-snap-type: x mandatory;

    @media screen and (min-width: $phoneold) {
      height: 550px;
    }

    @media screen and (min-width: $phone) {
      top: 100px;
      height: 500px;
    }

    @media screen and (min-width: $tablet) {
      position: relative;
      top: 0;
      overflow-x: auto;
    }

    li {
      display: flex;
      position: relative;
      flex: 0 0 100%;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      cursor: pointer;

      scroll-snap-align: start;
      scroll-snap-stop: always;
      scroll-padding: 50%;

      @media screen and (min-width: $tablet) {
        flex: none;
        width: calc(33.33% - var(--Medium));
      }

      &::before {
        display: none;
      }

      > p {
        flex-shrink: 0;
        width: calc(100% - 120px);
        max-width: 400px;
        max-height: 400px;
        margin: 0 auto 48px auto;
        padding-top: var(--Large);
        border-top: 6px solid var(--Red);
        overflow: hidden;
        font-style: italic;
        transition: all 0.35s linear;

        @media screen and (min-width: $phone) {
          flex-grow: 1;
          width: 100%;
        }

        @media screen and (min-width: $tablet) {
          max-width: 100%;
          max-height: 0;
          margin: 0 0 48px 0;
          margin: 0;
          padding-top: 0;
          border-top: 0;
        }
      }

      > button {
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 120px);
        max-width: 400px;
        margin: 0 auto;
        padding: var(--Medium);
        border-radius: 0;
        color: var(--White);
        text-align: left;
        background-color: var(--Red);
        box-shadow: none;
        white-space: normal;

        @media screen and (min-width: $phone) {
          width: 100%;
        }

        @media screen and (min-width: $tablet) {
          max-width: 100%;
          margin: 0;
          color: var(--Black);
          background-color: var(--GreyLight);
        }

        &::before {
          display: block;
          z-index: 1;
          position: absolute;
          top: -15px;
          left: 50%;
          width: 0;
          margin-left: -15px;
          border-width: 0 15px 15px;
          border-style: solid;
          border-color: var(--Red) transparent;
          content: '';

          @media screen and (min-width: $tablet) {
            display: none;
          }
        }

        > img {
          width: 50px;
          height: 50px;
          padding: var(--xSmall);
          border-radius: 50%;
          background-color: hsl(0, 0%, 85%);

          @media screen and (min-width: $phoneold) {
            width: 64px;
            height: 64px;
          }
        }

        > div {
          width: calc(100% - 64px);

          @media screen and (min-width: $phoneold) {
            width: calc(100% - 88px);
          }

          p {
            margin: 0;
            color: var(--White);
            font-weight: 400;
            line-height: 1.2;

            @media screen and (min-width: $tablet) {
              color: var(--Black);
            }

            &:first-child {
              margin-bottom: var(--Small);
              color: var(--White);
              font-weight: 800;
              text-transform: uppercase;

              @media screen and (min-width: $tablet) {
                color: var(--BlueDark);
              }
            }
          }
        }

        &:hover,
        &:focus {
          color: var(--White);
          background-color: var(--Red);

          &::before {
            display: block;
            z-index: 1;
            position: absolute;
            top: -15px;
            left: 50%;
            width: 0;
            margin-left: -15px;
            border-width: 0 15px 15px;
            border-style: solid;
            border-color: var(--Red) transparent;
            content: '';
          }

          > div p {
            color: var(--White);
          }
        }
      }

      a.arrow {
        z-index: 20;
        position: absolute;
        bottom: 20%;
        width: 48px;
        height: 60px;

        &.prev {
          left: 0;
        }

        &.next {
          right: 0;
        }

        @media screen and (min-width: $tablet) {
          display: none;
        }
      }

      &.is-active {
        > p {
          max-height: 400px;
          margin: 0 auto 48px auto;
          padding-top: 48px;
          border-top: 6px solid var(--Red);

          @media screen and (min-width: $tablet) {
            margin: 0 0 48px 0;
          }
        }

        button {
          color: var(--White);
          background-color: var(--Red);

          &::before {
            display: block;
            z-index: 1;
            position: absolute;
            top: -15px;
            left: 50%;
            width: 0;
            margin-left: -15px;
            border-width: 0 15px 15px;
            border-style: solid;
            border-color: var(--Red) transparent;
            content: '';
          }

          > div p {
            color: var(--White);
          }
        }
      }
    }
  }
}
