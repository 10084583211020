/*-----------------------------------*\
	VARIABLES
\*-----------------------------------*/
$phoneold: 400px;
$phone: 500px;
$tablet: 800px;
$laptop: 1050px;
$desktop: 1400px;
/*-----------------------------------*\
	xSmall: 4px
	Small: 8px
	Medium: 16px
	Large: 32px
	xLarge: 64px
\*-----------------------------------*/
:root {
	--xSmall: .25rem;
	--Small: .5rem;
	--Medium: 1rem;
	--Large: 2rem;
	--xLarge: 4rem;

	--Blue: hsl(203, 77%, 53%);
	--BlueDark: hsl(206, 88%, 32%);
	--Yellow: hsl(42, 100%, 48%);
	--Green: hsl(93, 86%, 41%);
	--Red: hsl(7, 80%, 64%);
	--RedDark: hsl(7, 57%, 48%);
	--Purple: hsl(280, 56%, 63%);

	--White: hsl(0, 0%, 100%);
	--GreyLight: hsl(0, 0%, 95%);
	--Grey: hsl(240, 0%, 58%);
	--GreyDark: hsl(0, 0%, 31%);
	--Black: hsl(0, 0%, 0%);
}