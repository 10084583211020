/*----------------------------------------------------------------*\
	FORMS
\*----------------------------------------------------------------*/


/*---------------------------------------*\
  FORM STRUCTURE
\*---------------------------------------*/
.gform_wrapper {
	margin: 64px auto;
}

form {
	max-width: 600px;
	margin: 0 auto;

	&:focus {
		border: none;
		outline: none;
		box-shadow: none;
	}

	ul.gform_fields,
	ul.gfield_radio,
	ul.gfield_checkbox {
		position: relative;
		margin: 0;
		padding: 0;
		list-style: none;

		> li {
			position: relative;
			padding: 0;
			line-height: 1.5;
			background-image: none;

			&::before {
				display: none;
			}
		}
	}

	ul.gform_fields > li {
		margin: var(--Medium) 0;

		&:first-child {
			margin-top: 0;
		}
	}
}

.gform_footer button {
	margin: 24px 0 0 0;
}

.gfield_visibility_hidden {
	display: none;
}

/*---------------------------------------*\
	SCREEN READ TEXT HIDDEN
\*---------------------------------------*/
form .screen-reader-text {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	overflow: hidden;
	word-wrap: normal !important;

	clip-path: inset(50%);
}

/*---------------------------------------*\
	HIDDEN HONEYPOT FIELD
\*---------------------------------------*/
.gform_validation_container {
	display: none;
}

/*---------------------------------------*\
	FORM PAGINATION
\*---------------------------------------*/
.gf_page_steps {
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 0 0 32px 0;
	padding: 0 0 16px 0;
	border-bottom: 2px solid var(--GreyLight);

	.gf_step {
		padding: 0 8px;
	}

	.gf_step_number {
		color: var(--Grey);
		font-size: 24px;
		vertical-align: middle;
	}

	.gf_step_label {
		color: var(--Grey);
		font-weight: 400;
		font-size: 16px;
	}

	.gf_step_active .gf_step_number,
	.gf_step_active .gf_step_label {
		color: var(--Green);
		font-weight: 800;
	}

	.gf_step_clear {
		display: none;
	}
}

.gform_page_footer {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 48px auto 0 auto;

	input {
		width: auto;
		min-width: 120px;
		margin: 0 var(--Large) 0 0;
		padding: 10px var(--Medium);
		border: none;
		border-radius: 4px;
		color: var(--BlueDark);
		font-weight: 800;
		font-size: 16px;
		font-family: 'Karla', sans-serif;
		text-align: center;
		text-decoration: none;
		cursor: pointer;
		background-color: var(--White);
		box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.27);
		transition: all 0.2s linear;

		&:hover,
		&:focus {
			color: var(--White);
			background-color: var(--BlueDark);
		}
	}

	button {
		position: relative;
		min-width: 160px;
		border-radius: 0;
		color: var(--White);
		font-size: 12px;
		text-transform: uppercase;
		background: transparent;
		box-shadow: none;

		&::after {
			display: block;
			z-index: -1;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: 160%;
			letter-spacing: 0.5px;
			content: "";
			background-image: url(../images/brushstroke.png);
			background-repeat: no-repeat;
			background-position: 50%;
			background-size: contain;
			transition: all 0.2s linear;
			transform: translate(-50%, -50%);
		}

		&:hover,
		&:focus {
			background-color: transparent;

			&::after {
				background-image: url(../images/brushstroke-hover.png);
			}
		}
	}
}

/*---------------------------------------*\
    SECTION TITLES
\*---------------------------------------*/
.gsection_title {
	margin: 64px 0 0 0;
	padding: 4px 64px 4px 8px;
	font-size: 24px;
	background-color: var(--Grey);

	&+.gsection_description {
		padding: 8px 64px 0 0;
		font-size: 14px;
		line-height: 1.3;
	}
}

form ul>li:first-child .gsection_title {
	margin-top: 0;
}

/*---------------------------------------*\
  LABELS & Descriptions
\*---------------------------------------*/
form label {
	display: inline-block;
	margin-bottom: var(--xSmall);
	color: var(--Black);
	font-weight: 800;
	font-size: 14px;
	letter-spacing: 0.5px;

	span.gfield_required {
		display: none;
	}
}

.hidden_label>label {
	display: none;
}

.optional {
	display: inline-block;
	margin-left: var(--xSmall);
	color: var(--BlueDark);
	font-size: 12px;
	vertical-align: text-bottom;
}

::placeholder {
	color: var(--Grey);
	font-weight: 400;

	@media screen and (min-width: $tablet) {
		font-weight: 800;
	}
}

.gfield_description,
.ginput_counter,
.ginput_container .instruction {
	padding: 8px 0 0 8px;
	color: var(--GreyDark);
	font-weight: 400;
	font-size: 12px;
	line-height: 1;
	letter-spacing: 0.5px;
}

.field_description_above .gfield_description {
	color: var(--Black);
}

label+.gfield_description {
	margin: 0 0 8px 0;
	padding: 0;
	font-size: 14px;
	line-height: 1.3;
}

/*---------------------------------------*\
  VALIDATION MESSAGES
\*---------------------------------------*/
.gfield_error {
	label {
		color: var(--Red);
	}

	input,
	textarea,
	select {
		border-color: var(--Red);
	}

	.error,
	.validation_message {
		color: var(--Red);
	}

	.ginput_container {
		position: relative;

		&::after {
			display: block;
			z-index: 4;
			position: absolute;
			top: 10px;
			right: 10px;
			width: 24px;
			height: 24px;
			content: '';
			background-image: url(../images/input_error.svg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}
	}
}

.error,
.validation_message {
	padding: 8px 0 0 8px;
	color: var(--Red);
	font-size: 12px;
	line-height: 1;
	letter-spacing: 0.5px;

	ul {
		margin: 0;
		padding: 0;

		li {
			margin-bottom: var(--Small);
			padding: 0;
			color: var(--Red);
			line-height: 1.2;
			background-image: none;

			&::before {
				display: none;
			}
		}
	}
}

/*---------------------------------------*\
  INPUT
\*---------------------------------------*/
input {
	width: 100%;
	padding: 16px 24px 12px 24px;
	border: none;
	border-bottom: 4px solid var(--GreyLight);
	border-radius: 4px;
	color: var(--Black);
	font-weight: 800;
	background-color: var(--GreyLight);

	&:focus {
		border-color: var(--BlueDark);
		outline: none;
		box-shadow: none;
	}
}

/*---------------------------------------*\
  TEXTAREA
\*---------------------------------------*/
textarea {
	width: 100%;
	padding: 16px 24px 12px 24px;
	border: none;
	border-bottom: 4px solid var(--GreyLight);
	border-radius: 4px;
	color: var(--Black);
	font-weight: 800;
	line-height: 1.61;
	background-color: var(--GreyLight);

	&:focus {
		border-color: var(--BlueDark);
		outline: none;
		box-shadow: none;
	}

	&.small {
		max-height: 100px;
	}

	&.medium {
		max-height: 150px;
	}

	&.large {
		max-height: 200px;
	}
}

/*---------------------------------------*\
  SELECT
\*---------------------------------------*/
select {
	width: 100%;
	padding: 16px 24px 12px 24px;
	border: none;
	border-bottom: 4px solid var(--GreyLight);
	border-radius: 4px;
	color: var(--Black);
	font-weight: 800;
	background-color: var(--GreyLight);
	background-image: url(../images/select_arrow.svg);
	background-repeat: no-repeat;
	background-position: top 14px right 8px;
	background-size: 24px;

	-webkit-appearance: none;
	-moz-appearance: none;

	&:focus {
		border-color: var(--BlueDark);
		outline: none;
		box-shadow: none;
	}

	&.has-placeholder {
		color: var(--Grey);
		font-weight: 800;
	}
}

.gfield_error .ginput_container_select::after {
	right: 40px;
}

/*---------------------------------------*\
  RADIO
\*---------------------------------------*/
ul.gfield_radio {
	margin: var(--Small) 0 0 0;
	padding: 0 0 0 8px;

	li {
		display: table;
		align-items: center;
		width: auto;
		margin: 0 0 12px 0;
		padding: 0;
		line-height: 1;
		background-image: none;

		@media screen and (min-width: $phone) {
			width: 100%;
		}

		label {
			margin: 0;
			padding: 0;
			color: var(--Black);
			font-weight: 500;
			font-size: 14px;
			line-height: 1;
			vertical-align: middle;
			letter-spacing: 0.5px;
			cursor: pointer;
		}

		input[type="radio"] {
			flex-shrink: 0;
			width: 24px;
			height: 24px;
			margin-right: var(--Small);
			border: 4px solid var(--GreyLight);
			border-radius: 50%;
			vertical-align: middle;
			cursor: pointer;

			-webkit-appearance: none;
			-moz-appearance: none;

			&:checked {
				background-color: var(--Blue);
			}
		}

		input[type="text"] {
			width: auto;
			padding: 8px 12px 6px 12px;
			border: none;
			border-bottom: 3px solid var(--GreyLight);
			border-radius: 4px;
			color: var(--Black);
			font-weight: 800;
			background-color: var(--GreyLight);

			&:focus {
				border-color: var(--BlueDark);
				outline: none;
				box-shadow: none;
			}
		}
	}
}

.two-column ul.gfield_radio {
	column-count: 2;

	@media screen and (min-width: $phone) {
		column-count: 1;
	}

	li {
		width: 100%;
	}
}

.three-column ul.gfield_radio {
	column-count: 3;

	@media screen and (min-width: $phone) {
		column-count: 1;
	}

	li {
		width: 100%;
	}
}

/*---------------------------------------*\
  CHECKBOXES
\*---------------------------------------*/
ul.gfield_checkbox {
	margin: var(--Small) 0 0 0;
	padding: 0 0 0 8px;

	li {
		display: table;
		align-items: center;
		width: auto;
		margin: 0 0 12px 0;
		padding: 0;
		line-height: 1;
		background-image: none;

		@media screen and (min-width: $phone) {
			width: 100%;
		}

		label {
			position: relative;
			margin: 0;
			padding: 0;
			color: var(--Black);
			font-weight: 500;
			font-size: 14px;
			line-height: 1;
			vertical-align: middle;
			letter-spacing: 0.5px;
			cursor: pointer;

			&::before {
				display: block;
				opacity: 0;
				position: absolute;
				top: -2px;
				left: -27px;
				width: 16px;
				height: 16px;
				content: '';
				background-image: url(../images/checkmark-white.svg);
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				transition: all 0.15s linear;
			}
		}

		input[type="checkbox"] {
			flex-shrink: 0;
			width: 24px;
			height: 24px;
			margin-right: var(--Small);
			border: 2px solid var(--GreyLight);
			border-radius: 4px;
			vertical-align: middle;
			cursor: pointer;

			-webkit-appearance: none;
			-moz-appearance: none;

			&:checked {
				border-color: var(--Green);
				background-color: var(--Green);

				&+label {
					&::before {
						opacity: 1;
					}
				}
			}
		}

		input[type="text"] {
			padding: 4px;
			border: 1.5px solid var(--Black);
			border-radius: 4px;
			color: var(--Black);
			font-weight: 500;
			font-size: 14px;
			line-height: 1;
			background: transparent;
			box-shadow: none;
		}
	}
}

.two-column ul.gfield_checkbox {
	column-count: 2;

	@media screen and (min-width: $phone) {
		column-count: 1;
	}

	li {
		width: 100%;
	}
}

.three-column ul.gfield_checkbox {
	column-count: 3;

	@media screen and (min-width: $phone) {
		column-count: 1;
	}

	li {
		width: 100%;
	}
}

/*---------------------------------------*\
	SMART FIELDS
\*---------------------------------------*/
.ginput_complex,
.clear-multi {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;

	.name_first,
	.name_middle,
	.name_last {
		width: 31%;
	}

	&.no_middle_name .name_first,
	&.no_middle_name .name_last {
		width: 48%;
	}

	.address_line_1,
	.address_line_2 {
		width: 100%;
	}

	.address_city,
	.address_state,
	.address_zip {
		width: 31%;
	}

	.gfield_time_hour,
	.gfield_time_minute,
	.gfield_time_ampm {
		width: 31%;
	}

	span label:last-child,
	label {
		display: block;
		margin: 6px 0 12px 0;
		padding: 0 0 0 8px;
		font-weight: 400;
		font-size: 12px;
		line-height: 1;
		letter-spacing: 0.5px;
	}

	i {
		display: none;
	}
}

.ginput_container_address.has_city.has_state:not(.has_zip) {

	.address_city,
	.address_state,
	.address_zip {
		width: 48%;
	}
}

/*---------------------------------------*\
	FILE UPLOAD
\*---------------------------------------*/

.gform_drop_area {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100px;
	margin: 0 8px;
	border: 3px dashed var(--GreyLight);
	border-radius: 4px;

	input {
		display: inline-block;
		width: auto;
		min-width: 120px;
		margin: 0 0 0 var(--Medium);
		padding: 10px var(--Medium);
		border: none;
		border-radius: 4px;
		color: var(--White);
		font-weight: 800;
		font-size: 16px;
		font-family: 'Karla', sans-serif;
		text-align: center;
		text-decoration: none;
		cursor: pointer;
		background-color: var(--BlueDark);
		transition: all 0.2s linear;

		&:hover,
		&:focus {
			color: var(--White);
			background-color: var(--Blue);
		}
	}
}

.ginput_preview {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0 0 0 8px;
	font-size: 12px;
	line-height: 1;
	letter-spacing: 0.5px;

	strong {
		font-weight: 500;
	}

	img {
		margin-right: 6px;
		vertical-align: middle;
		cursor: pointer;
	}
}

.gform_fileupload_multifile+span.screen-reader-text {
	position: relative !important;
	clip: auto;
	overflow: visible;
}

/*---------------------------------------*\
  DATE
\*---------------------------------------*/
.ui-datepicker {
	background: var(--Blue);

	.ui-datepicker-header {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
		padding: var(--Small);

		> a {
			margin: 0 0 8px 0;
			color: var(--Black);
			font-size: 12px;
		}

		.ui-datepicker-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			margin-bottom: 8px;

			select {
				width: 48%;
				padding: 8px 4px;
				border: 1px solid var(--Black);
				font-size: 14px;
				background-position: center right;
			}
		}
	}

	.ui-datepicker-calendar {
		margin: 0 var(--Small) var(--Small) var(--Small);
		border: 1px solid var(--Black);
		border-collapse: collapse;
		font-weight: 800;
		background: var(--GreyLight);

		th,
		td {
			border: 1px solid var(--Black);
		}

		td {
			text-align: center;

			a {
				display: block;
				margin: 0;
				padding: 6px 4px;
				color: var(--GreyDark);
				line-height: 1;
				text-decoration: none;

				&:hover,
				&:focus {
					color: var(--Blue);
					text-decoration: underline;
				}
			}
		}
	}
}

.ginput_container_date img.ui-datepicker-trigger {
	position: absolute;
	top: 8px;
	right: 8px;
}

/*---------------------------------------*\
  CONSENT
\*---------------------------------------*/
.field_description_above .gfield_consent_description {
	margin-top: var(--xLarge);
}

.ginput_container_consent {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: var(--Medium);

	label {
		position: relative;
		margin: 0;
		padding: 0;
		color: var(--Black);
		font-weight: 500;
		font-size: 14px;
		line-height: 1;
		vertical-align: middle;
		letter-spacing: 0.5px;
		cursor: pointer;

		&::before {
			display: block;
			opacity: 0;
			position: absolute;
			top: -2px;
			left: -27px;
			width: 16px;
			height: 16px;
			content: '';
			background-image: url(../images/checkmark-white.svg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			transition: all 0.15s linear;
		}

		.gfield_required {
			display: none;
		}
	}

	input[type="checkbox"] {
		flex-shrink: 0;
		width: 24px;
		height: 24px;
		margin-right: var(--Small);
		border: 2px solid var(--GreyLight);
		border-radius: 4px;
		vertical-align: middle;
		cursor: pointer;

		-webkit-appearance: none;
		-moz-appearance: none;

		&:checked {
			border-color: var(--Green);
			background-color: var(--Green);

			+ label {
				&::before {
					opacity: 1;
				}
			}
		}
	}

	+ .gfield_consent_description {
		padding: 8px 0 0 24px;
		color: var(--Black);
	}
}

/*---------------------------------------*\
  reCaptcha
\*---------------------------------------*/
.grecaptcha-badge {
	z-index: 1000;
}

.cancel {
	text-decoration: none;
	color: var(--Black);
  font-weight: normal;
}

.warning {
	background: #EAEAEA;
	border: 1px solid #D4D4D4;
	border-radius: 2px;
	font-weight: normal;
	margin: 16px 0;
	padding: 16px;
	color: #161616;
}


/*---------------------------------------*\
  Login
\*---------------------------------------*/

.woocommerce-form-login {
	.d-flex {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media screen and (max-width: $phone) {
			flex-direction: column;
			.button {
				width: 100%;
				margin-right: 0;
			}
		}

		@media screen and (min-width: $phone) {
			#resend {
				margin-right: 12px;
			}
		}
	}
	.d-flex .button {
		margin-top: 8px;
		margin-right: 0;
	}
	.d-flex a {
		line-height: 1.15;
	}
	.blue-btn {
		background-color: var(--BlueDark);
		color: #ffffff;
		white-space: initial;
		line-height: 1.3;
		
		@media screen and (max-width: $phone) {
			margin: 24px auto auto;
		}
	}
	.resent {
		color: var(--Black);
		font-size: 14px;
		padding: 8px 0 0 8px;
	}
}