/*----------------------------------------------------------------*\
	BUTTONS
\*----------------------------------------------------------------*/
button,
.button {
  display: inline-block;
  min-width: 120px;
  margin: 0 var(--Medium) 0 0;
  padding: 10px var(--Medium);
  border: none;
  border-radius: 4px;
  color: var(--BlueDark);
  font-weight: 800;
  font-size: 16px;
  font-family: 'Karla', sans-serif;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  background-color: var(--White);
  box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.27);
  transition: all 0.2s linear;

  &:hover,
  &:focus {
    color: var(--White);
    background-color: var(--BlueDark);
  }

  &.is-red {
    color: var(--White);
    background-color: var(--Red);
    box-shadow: none;

    &:hover,
    &:focus {
      color: var(--White);
      background-color: var(--RedDark);
    }
  }

  &.is-text {
    padding: 14px 0;
    color: var(--Blue);
    text-decoration: underline;
    background-color: transparent;
    box-shadow: none;

    &:hover,
    &:focus {
      color: var(--BlueDark);
      text-decoration: underline;
      background-color: transparent;
    }
  }

  &.is-paint {
    z-index: 2;
    position: relative;
    min-width: 160px;
    border-radius: 0;
    color: var(--White);
    font-size: 12px;
    text-transform: uppercase;
    background: transparent;
    box-shadow: none;
    font-weight: bold !important;
    letter-spacing: 0.1rem !important;

    &::after {
      display: block;
      z-index: -1;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 114%;
      height: 120%;
      letter-spacing: 0.5px;
      content: "";
      background-image: url(../images/brushstroke.png);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100% 100% !important;
      transition: all 0.2s linear;
      transform: translate(-50%, -50%);
    }

    &:hover,
    &:focus {
      background-color: transparent;

      &::after {
        background-image: url(../images/brushstroke-hover.png);
      }
    }
  }

  &.reverse {
    color: white;
    background-color: var(--BlueDark);

    &:hover {
      background-color: var(--Blue);
    }
  }
  &.disabled {
    opacity: 0.4;
  }

  &.rounded {
    border-radius: 2rem;
  }

  &.outline {
    border: 2px solid var(--Blue);
    background-color: transparent;

    &.white {
      color: var(--White);
      border-color: var(--White);

      &:hover,
      &:focus {
        color: var(--BlueDark);
        background-color: var(--White);
      }
    }

    &.purple {
      color: var(--Purple);
      border-color: var(--Purple);

      &:hover,
      &:focus {
        color: var(--White);
        background-color: var(--Purple);
      }
    }

    &.blue {
      color: var(--Blue);
      border-color: var(--Blue);

      &:hover,
      &:focus {
        color: var(--White);
        background-color: var(--Blue);
      }
    }

    &.blueDark {
      color: var(--BlueDark);
      border-color: var(--BlueDark);

      &:hover,
      &:focus {
        color: var(--White);
        background-color: var(--BlueDark);
      }
    }

    &.redDark {
      color: var(--RedDark);
      border-color: var(--RedDark);

      &:hover,
      &:focus {
        color: var(--White);
        background-color: var(--RedDark);
      }
    }
  }

  &.no-shadow {
    box-shadow: none !important;
  }
}
