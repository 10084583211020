/*-----------------------------------*\
	POST FOOTER
\*-----------------------------------*/

footer .post-footer {
	padding: var(--Large);
	background-color: var(--GreyLight);

	.post-footer-inner {
		max-width: 1500px;
		margin: 0 auto;
	}

	> .post-footer-inner > div:first-child {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
		grid-column-gap: 40px;

		@media screen and (min-width: $phone) {
			grid-template-columns: repeat(6, 1fr);
			grid-template-rows: 1fr 1fr;
		}

		@media screen and (min-width: $laptop) {
			grid-template-rows: 1fr;
		}

		> div {
			&:first-child {
				grid-column: 1 / span 2;
				grid-row: auto / auto;
				margin-bottom: var(--Large);

				@media screen and (min-width: $phone) {
					grid-column: 1 / span 2;
					grid-row: 1 / span 1;
					margin-bottom: 0;
				}

				@media screen and (min-width: $laptop) {
					grid-column: auto / auto;
					grid-row: auto / auto;
				}
			}

			&:last-child {
				grid-column: 1 / span 2;
				grid-row: auto / auto;

				@media screen and (min-width: $phone) {
					grid-column: 1 / span 2;
					grid-row: 2 / span 1;
				}

				@media screen and (min-width: $laptop) {
					grid-column: auto / auto;
					grid-row: auto / auto;
				}
			}

			.title {
				display: block;
				margin: var(--Large) 0 var(--Large) 0;
				font-weight: 800;
			}

			img.logo {
				width: auto;
				height: 80px;
			}

			address {
				padding-left: var(--Medium);
				font-style: normal;

				a.button {
					margin: var(--Medium) 0;
				}
			}

			nav ul {
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				align-items: center;

				a {
					margin: 0 var(--Small) var(--Small) 0;
					padding: 0;
					border-radius: 50%;
					background-color: transparent;

					&:hover,
					&:focus {
						background-color: hsl(0, 0%, 80%);
					}

					img {
						display: block;
						width: 48px;
						height: 48px;
					}
				}
			}
		}

		details {
			margin: 0 0 var(--Medium) 0;
			border-left: 0;

			@media screen and (min-width: $phone) {
				margin: 0;
				background-color: transparent;
				box-shadow: none;
			}

			&:nth-of-type(1) {
				grid-column: 1 / span 2;
				grid-row: auto / auto;

				@media screen and (min-width: $phone) {
					grid-column: 3 / span 2;
					grid-row: 1 / span 1;
				}

				@media screen and (min-width: $laptop) {
					grid-column: auto / auto;
					grid-row: auto / auto;
				}
			}

			&:nth-of-type(2) {
				grid-column: 1 / span 2;
				grid-row: auto / auto;

				@media screen and (min-width: $phone) {
					grid-column: 5 / span 2;
					grid-row: 1 / span 1;
				}

				@media screen and (min-width: $laptop) {
					grid-column: auto / auto;
					grid-row: auto / auto;
				}
			}

			&:nth-of-type(3) {
				grid-column: 1 / span 2;
				grid-row: auto / auto;

				@media screen and (min-width: $phone) {
					grid-column: 3 / span 2;
					grid-row: 2 / span 1;
				}

				@media screen and (min-width: $laptop) {
					grid-column: auto / auto;
					grid-row: auto / auto;
				}
			}

			&:nth-of-type(4) {
				grid-column: 1 / span 2;
				grid-row: auto / auto;

				@media screen and (min-width: $phone) {
					grid-column: 5 / span 2;
					grid-row: 2 / span 1;
				}

				@media screen and (min-width: $laptop) {
					grid-column: auto / auto;
					grid-row: auto / auto;
				}
			}

			summary {
				font-weight: 800;
				font-size: 16px;

				@media screen and (min-width: $phone) {
					margin: var(--Large) 0 var(--Large) 0;
					padding: 0;
					pointer-events: none;

					&::after {
						display: none;
					}

					&::-webkit-details-marker {
						display: none;
					}
				}
			}

			nav {
				padding: 0 var(--Medium);

				@media screen and (min-width: $phone) {
					padding: 0;
				}

				li {
					padding: var(--Small) 0;
					border-top: 1px solid var(--GreyLight);

					@media screen and (min-width: $phone) {
						margin-top: none;
						padding: 0;
						border-top: 0;
					}

					&:first-child {
						margin-top: var(--Small);

						@media screen and (min-width: $phone) {
							margin-top: 0;
						}
					}
				}
			}

			a {
				padding: 0;
				color: var(--GreyDark);
				font-weight: 800;
				font-size: 16px;
				text-transform: none;

				&:hover,
				&:focus {
					text-decoration: underline;
				}

				@media screen and (min-width: $phone) {
					font-weight: 400;
					font-size: 14px;
				}
			}
		}
	}

	> .post-footer-inner > div:last-child {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		margin-top: var(--Large);

		.login {
			width: 100%;

			@media screen and (min-width: $phone) {
				width: 50%;
				padding-left: var(--Medium);
			}

			a.button {
				display: table;
				padding: 0;

				&.split {
					text-decoration: none;

					span.link {
						text-decoration: underline;
					}

					span.label {
						padding-right: var(--Small);
						color: var(--GreyDark);
						font-weight: normal;
						text-decoration: none;
					}
				}
			}
		}

		.badges {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			display: flex;
			justify-content: space-around;
			align-items: center;

			@media screen and (min-width: $phone) {
				width: 50%;
			}

			img {
				display: inline-block;
				margin: var(--Medium) 0;
				height: auto;
				object-fit: contain;

				&:first-child {
					width: 80px;
				}

				&:nth-child(2) {
					width: 120px;
				}

				&:last-child {
					width: 180px;
				}
			}
		}
	}
}

footer .simple-footer {
	position: relative;
	&::before {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 20px;
		content: '';
		background-image: url(../images/Banner-BG-Green.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	.post-footer-inner {
		padding-top: 25px;
	}

	.post-footer-content {
		margin-left: 20px;
		margin-top: 0 !important;
		display: flex;

		@media screen and (max-width: $tablet) {
			flex-direction: column;
		}

		& > div {
			flex: 1;
		}

		nav {
			padding: 10px 50px;
			ul {
				display: flex;

				li {
					padding: 0 5px;

					a {
						padding: 0;
					}
				}
			}
		}
	}

	small {
		text-align: center;
		width: 100%;
		display: block;
	}
}

/*-----------------------------------*\
	COPYRIGHT FOOTER
\*-----------------------------------*/
footer .copyright {
	padding: var(--Medium);
	background-image: url(../images/pattern_yellow_wide.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	> .copyright-inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		max-width: 1500px;
		margin: 0 auto;

		@media screen and (min-width: $phone) {
			flex-wrap: nowrap;
		}

		&>a {
			margin: 0 auto;

			@media screen and (min-width: $phone) {
				margin: 0;
			}
		}

		img {
			display: block;
		}

		> div {
			p {
				margin: 0;
				color: var(--Black);
				font-size: 14px;
				line-height: 1;
			}

			ul {
				display: block;
				justify-content: flex-start;
				align-items: center;
				margin: var(--Medium) 0;
				padding: 0;

				@media screen and (min-width: $phoneold) {
					display: flex;
				}

				@media screen and (min-width: $phone) {
					margin: 0;
				}

				li {
					padding: 0;

					&:before {
						display: none;
					}

					&:first-child a,
					&:first-child button {
						padding-left: 0;
					}

					&:last-child a,
					&:last-child button {
						border: none;
					}
				}
			}

			a,
			button {
				padding: 0;
				border-radius: 0;
				color: var(--Black);
				font-weight: 400;
				font-size: 14px;
				line-height: 1;
				text-align: left;
				text-decoration: none;
				background-color: transparent;
				box-shadow: none;

				@media screen and (min-width: $phoneold) {
					padding: 0 var(--Small);
					border-right: 1px solid var(--Black);
					text-align: center;
				}

				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}

		small {
			width: 100%;
			text-align: center;
		}
	}
}