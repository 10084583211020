/*-----------------------------------*\
	CHILD PAGE NAVIGATION
\*-----------------------------------*/
nav.child-page-navigation {
  width: 100%;
  box-shadow: 0 7px 27px 0 rgba(0, 0, 0, 0.13);

  > div {
    overflow: auto;
  }

  ul {
    display: flex;
    align-items: center;
    width: max-content;
    margin: 0 auto;
    white-space: nowrap;

    &.sub-menu {
      display: none;
    }

    > li {
      padding: 0 24px;

      &.current-menu-item a {
        padding: 24px 0 20px 0;
        border-bottom: 4px solid var(--Green);
      }

      a {
        padding: 24px 0;
        border-bottom: 0 solid var(--Green);
        color: var(--Black);
        font-size: 14px;
        text-transform: capitalize;

        &:hover,
        &:focus {
          padding: 24px 0 20px 0;
          border-width: 4px;
          color: var(--Black);
        }
      }
    }
  }
}
