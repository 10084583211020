/*-----------------------------------*\
	IMAGE + MEDIA
\*-----------------------------------*/
.media-text {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: var(--xLarge) auto;

  &.image-cover.has-image-on-left,
  &.image-cover.has-image-on-right {
    align-items: initial;

    & > div:first-child::after {
      display: none;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.has-image-on-left {
    & > div:first-child::after {
      display: block;
      z-index: -1;
      position: absolute;
      bottom: -150px;
      left: -200px;
      width: 500px;
      height: 500px;
    }
  }

  &.has-image-on-right {
    & > div:first-child::after {
      display: block;
      z-index: -1;
      position: absolute;
      right: -200px;
      bottom: -75px;
      width: 500px;
      height: 500px;
    }
  }

  &.has-image-on-right.is-extra-wide {
    padding-right: 100px;
  }

  & > div {
    position: relative;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &:first-child {
      margin: 0 0 var(--Large) 0;

      @media screen and (min-width: $tablet) {
        margin: 0;
      }
    }

    & > *:first-child {
      margin-top: 0;
    }

    & img {
      display: block;
      z-index: 2;
      position: relative;
      width: 100%;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: $tablet) {
    flex-wrap: nowrap;

    & > div {
      width: 45%;
    }
  }
}

/*-----------------------------------*\
	IMAGE ALIGNMENT
\*-----------------------------------*/
.media-text {
  @media screen and (min-width: $tablet) {
    &.has-image-on-right {
      & > div:first-child {
        order: 2;
      }

      & > div:last-child {
        order: 1;
      }
    }
  }
}

.text-block{
  display: flex;
  flex-direction: column;
  max-width: 433.29px;
}

.text-block p {
  font-weight: normal;
  font-size: 22px;
  letter-spacing: 0.01em;
  line-height: 30px;
  text-align: left;
  color: #4f4f4f;
  width: 90%;
}

.text-block p:last-of-type{
  text-align: center;
}

.text-block a {
  font-family: Karla;
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: inherit;
  text-align: center;
  z-index: 2;
  position: relative;
  min-width: 160px;
  border-radius: 0;
  color: var(--White);
  text-transform: uppercase;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-decoration: none;
  font-weight: bold;
}

.text-block a:after{
  display: block;
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 160%;
  letter-spacing: 0.5px;
  content: "";
  background-image: url(../images/brushstroke.png);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-size: 100% 100% !important;
  width: 240px;
  height: 53.17px;
}

.image-block {
  width: 440.95px !important;
  height: 440.95px;
}

.text-block.right {
  margin-left: auto;
}

.text-block.has-image-on-left{
  max-width: 550px;
  &  a:after{
    width: 194.98px;
  }

  & p:last-of-type{
    text-align: left;
    margin-left: 15px;
  }
}

.media-text + .media-text {
  border-top: 1px solid #ccd4dd;
  padding-top: 50px !important;
}

.is-wide{
  & .image-block{
    width: 224px !important;
    height: 272px;
  }

  & .text-block{
    max-width: 60%;
    width: 55%;

    p, h2 {
      text-align: left;
    }
    p {
      width: 120%;
    }
  }

  & .image-block::after{
    display: none !important;
  }
}