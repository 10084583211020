/*-----------------------------------*\
	PRICE CARD
\*-----------------------------------*/
main > article > section.price-card:last-child {
  margin-bottom: 150px;
}

section.price-card {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 120px auto;
  padding: 300px 0 0 0;
  background-color: var(--White);
  box-shadow: 0 7px 27px 0 rgba(0, 0, 0, 0.13);

  @media screen and (min-width: $tablet) {
    padding: 0 0 0 300px;
  }

  & > img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;

    @media screen and (min-width: $tablet) {
      right: auto;
      bottom: 0;
      width: 300px;
      height: 100%;
    }
  }

  & .description {
    width: 100%;
    margin: 48px 0 0 0;
    padding: 0 var(--Large);

    @media screen and (min-width: $phone) {
      margin: 48px 0;
    }

    @media screen and (min-width: $laptop) {
      width: calc(100% - 300px);
    }

    & > *:first-child {
      margin-top: 0;
    }

    & > *:last-child {
      margin-bottom: 0;
    }

    & a.button.is-text {
      text-align: left;
    }
  }

  & .price {
    width: 90%;
    margin: var(--Large) auto 0 auto;
    padding: 48px var(--Large);
    border-top: 2px solid var(--Grey);
    text-align: center;

    @media screen and (min-width: $tablet) {
      padding: var(--xLarge) var(--Large);
    }

    @media screen and (min-width: $laptop) {
      width: 300px;
      margin: var(--Large) 0;
      padding: var(--Large);
      border-top: 0;
      border-left: 2px solid var(--Grey);
    }

    & p {
      margin: 0 0 var(--Large) 0;
      color: var(--Black);
      font-style: normal;
      font-weight: 300;
      font-size: 32px;
      line-height: 1.1;
      font-family: 'lexia', serif;

      @media screen and (min-width: $phone) {
        font-size: 42px;
      }
    }

    & .button {
      margin: 0;
    }
  }
}
