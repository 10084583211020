/*-----------------------------------*\
	FONT FAMILIES
\*-----------------------------------*/
/*
	https://css-tricks.com/font-display-masses/
	TLDR: font-display: swap;
	https://css-tricks.com/snippets/css/using-font-face/
	TLDR: use woff and woff2 formats only
*/
@import url('https://fonts.googleapis.com/css?family=Karla:400,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

@import url("https://use.typekit.net/fxi2cyw.css");

/*-----------------------------------*\
	HEADER
\*-----------------------------------*/
h1,
h2,
h3 {
	margin-top: 1.618em;
	margin-bottom: 0.75em;
	color: var(--Black);
	font-style: normal;
	font-weight: 300;
	line-height: 1.1;
	font-family: 'lexia', serif;
}

h4,
h5,
h6 {
	margin-top: 1.618em;
	margin-bottom: 0.75em;
	color: var(--Black);
	font-style: normal;
	font-weight: 800;
	line-height: 1.1;
	font-family: 'Karla', sans-serif;
}

h1 {
	font-size: 32px;

	@media screen and (min-width: $phone) {
		font-size: 42px;
	}
}

h2 {
	font-size: 28px;

	@media screen and (min-width: $phone) {
		font-size: 36px;
	}
	
	&.blue {
    	color: var(--BlueDark);
	}
}

h3 {
	font-size: 24px;

	@media screen and (min-width: $phone) {
		font-size: 28px;
	}
}

h4 {
	color: var(--BlueDark);
	font-size: 18px;
}

h5 {
	font-size: 20px;

	@media screen and (min-width: $phone) {
		font-size: 24px;
	}
}

h6 {
	font-size: 18px;
}

.subheader {
	margin-top: 0;
	margin-bottom: 0.75em;
	color: var(--Grey);
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 1.2;
	font-family: 'Karla', sans-serif;
}

/*-----------------------------------*\
	INLINE LINKS
\*-----------------------------------*/
a,
p a,
ul a,
ol a {
	color: var(--Blue);
	font-size: inherit;
	text-decoration: underline;
	transition: all 0.2s linear;

	&:focus,
	&:hover {
		color: var(--BlueDark);
	}
}

b,
strong {
	font-weight: 800;
}

/*-----------------------------------*\
	HORIZONTAL RULE
\*-----------------------------------*/
hr {
	width: 80vw;
	max-width: 300px;
	height: 7px;
	margin: var(--xLarge) auto;
	border: none;
	border-radius: 2px;
	background-color: var(--Red);
}

/*-----------------------------------*\
	TABLE
\*-----------------------------------*/
table {
	margin: var(--Large) auto;
}

/*-----------------------------------*\
	QUOTE
\*-----------------------------------*/
blockquote {
	width: 100%;
	margin: var(--Large) auto;
	padding: 0 0 0 var(--Large);
	border-left: 7px solid var(--Red);
	color: var(--GreyDark);
	font-style: normal;
	font-size: 18px;

	@media screen and (min-width: $phone) {
		font-size: 22px;
	}

	> *:first-child {
		margin-top: 0;
	}

	> *:last-child {
		margin-bottom: 0;
	}

	p {
		color: inherit;
		font-size: inherit;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	footer {
		position: relative;
		left: -40px;
		width: 100%;
		padding: var(--Medium) 0 var(--Small) 40px;
		font-size: 14px;
		background-color: var(--White);

		.name {
			display: block;
			margin: 0;
			color: var(--BlueDark);
			font-weight: 800;
			font-size: 16px;
			text-transform: uppercase;
		}
	}
}

/*-----------------------------------*\
	DETAILS
\*-----------------------------------*/
details {
	margin: 40px 0;
	border-left: 8px solid var(--White);
	border-radius: 4px;
	background-color: var(--White);
	box-shadow: 0 7px 27px 0 rgba(0, 0, 0, 0.13);

	+ details {
		margin-top: -18px;
	}

	&[open] {
		summary:after {
			// transform: translateY(-50%) rotate(-180deg);
		    content: '−';
	        background: var(--BlueDark);
    		color: #ffffff;
		}
	}

	summary {
		position: relative;
		margin: 0;
		padding: var(--Small) var(--xLarge) var(--Small) var(--Medium);
		color: var(--Black);
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 1.3;
		font-family: 'Karla', sans-serif;
		cursor: pointer;
		display: block;

		@media screen and (min-width: $phone) {
			font-size: 20px;
		}

		&:focus {
			outline: none;
			box-shadow: none;
		}

		&:after {
			display: inline-block;
			position: absolute;
			width: 32px;
			height: 32px;
			top: 24%;
			right: var(--Large);
			content: '+';
			padding: 9px;
			border-radius: 50%;
			font-size: 2.4rem;
			color: var(--BlueDark);
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: 0 7px 27px 0 #00000052;
			font-family: 'Karla', serif;

			@media screen and (max-width: $phone) {
				width: 18px;
				height: 18px;
				top: 17%;
				right: var(--Medium);
				padding: 4px;
				font-size: 1.5rem;
				box-shadow: 0 4px 16px 0 #00000052;
			}
		}

		&::-webkit-details-marker {
			display: none;
		}
	}

	> div {
		padding: var(--Medium);
	}
}