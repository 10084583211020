/*-----------------------------------*\
	WYSIWYG + SIDEBAR CARDS
\*-----------------------------------*/
.text_sidebar {
  & > section {
    width: 100%;

    &.cover-image.right {
      padding-left: 3.5rem;
      margin-top: 0;
    }
  }

  & section.card-grid {
    align-items: flex-start;
    margin: var(--xLarge) auto;

    & .card {
      &::before {
        background-image: url(../images/pattern_blue_small.jpg);
      }

      &:nth-child(2n)::before {
        background-image: url(../images/pattern_red_small.jpg);
      }

      &:nth-child(3n)::before {
        background-image: url(../images/pattern_yellow_small.jpg);
      }
    }

    & .morph {
      &::before {
        background-image: url(../images/pattern_purple_small.jpg);
      }
    }
  }

  @media screen and (min-width: $tablet) {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 48px;

    &.reverse {
      grid-template-columns: 3fr 2fr;
    }

    & section.editor {
      order: 2;
    }
  }
}
