/*----------------------------------------------------------------*\
	ARCHIVES TEMPLATES
\*----------------------------------------------------------------*/
/*------------------------------*\
	TESTIMONIALS
\*------------------------------*/
body.post-type-archive-testimony {
	blockquote {
		margin-bottom: 96px;
	}
}

.testimonials-wrapper {
    background: url(https://s3.amazonaws.com/imse-qa-new/images/testimonials-bg-blue.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 120px 80px;
    position: relative;

    h3 {
    	color: #ffffff;
    	font-weight: bold;
        max-width: 960px;
        margin: 0 auto 10px;
    }

    .story-content {
        display: flex;
        background: #ffffff;
        border-radius: 2px;
        padding: 16px;
        min-height: 380px;

        img {
            width: 346px;
            height: 346px;
        }

        > div {
            margin: 24px 66px;
            h3 {
                line-height: 1.3;
                font-weight: bold;
                color: #3395d2;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 5;
                white-space: pre-wrap;
                line-height: 1.3;
            }
            p {
                font-size: 20px;
                line-height: 1.4;
                margin-bottom: 40px;
            }
            button {
                padding: 14px var(--Medium);
            }
        }
    }

    .testimonials-featured-stories {
        padding: 0;
        margin: auto;
        max-width: 960px;

        .slick-dots {
            bottom: -34px;

            li {
                &:before {
                    display: none;
                }
                button {
                    box-shadow: none;
                    &:before {
                        font-size: 14px;
                        color: #fff;
                    }
                }
                &.slick-active button:before {
                    color: #ffffff;
                }
            }
        }
    }
    .testimonials-filters {
    	position: absolute;
	    bottom: -16px;
	    padding: 0;
	    margin: 0;
	    display: flex;
	    justify-content: space-between;
        left: calc(50% - 480px);
	    width: 960px;

    	li {
			border-radius: 4px;
		    background: #ffffff;
			text-align: center;
			padding: 8px 50px;
			box-shadow: 0 4px 13px 0 #00000045;
			display: flex;
			align-items: center;
			position: relative;
            cursor: pointer;
            min-width: 220px;
            display: flex;
            justify-content: center;
            &:before {
                display: none;
            }

		    img {
				position: absolute;
				border-radius: 50%;
				width: 34px;
				left: 4px;
                transition: transform 0.2s linear;
		    }

		    span {
		    	font-weight: bold;
		    }

		    &.testimonial-test {
		    	span {
		    	 	color: #3395d2;
		    	}
		    	&.is-active, &:hover {
		    		background: #3395d2;
		    		img {
		    			transform: scale(1.77);
		    		}
		    		span {
		    			color: #ffffff;
		    		}
		    	}
		    }
            &.testimonial-video {
                span {
                    color: #9465a3;
                }
                &.is-active, &:hover {
                    background: #9465a3;
                    img {
                        transform: scale(1.77);
                    }
                    span {
                        color: #ffffff;
                    }
                }
            }
            &.testimonial-case-study {
                span {
                    color: #79b543;
                }
                &.is-active, &:hover {
                    background: #79b543;
                    img {
                        transform: scale(1.77);
                    }
                    span {
                        color: #ffffff;
                    }
                }
            }
            &.testimonial-love-letter {
                span {
                    color: #df6257;
                }
                &.is-active, &:hover {
                    background: #df6257;
                    img {
                        transform: scale(1.77);
                    }
                    span {
                        color: #ffffff;
                    }
                }
            }
    	}
    }
    .research-reports {
        margin: auto;
        max-width: 960px;
    }
}

.testimonials-items-wrapper {
    padding-top: var(--Medium);

    ul.list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 40px;
        padding: 0;

        li {
            border: 1px solid #dddddd;
            box-shadow: 0 4px 13px 0 #00000045;
            padding: 0 var(--Medium) var(--Medium);
            text-align: center;
            position: relative;
            display: none;

            img {
                width: 60px;
                position: absolute;
                top: -24px;
                left: calc(50% - 30px);
            }
            h3 {
                margin-top: 60px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                white-space: pre-wrap;
                line-height: 1.3;
            }
            button, .button {
                margin: var(--Medium);
            }
            &:before {
                left: -16px;
                width: calc(100% + 32px);
                display: block;
                position: relative;
                height: 16px;
                content: '';
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                border-radius: 0;
                top: 0;
            }
            &.testimonial-test:before {
                background-image: url(https://s3.amazonaws.com/imse-qa-new/images/pattern_blue_small.jpg);
            }
            &.testimonial-video:before {
                background-image: url(https://s3.amazonaws.com/imse-qa-new/images/pattern_purple_small.jpg);
            }
            &.testimonial-case-study:before {
                background-image: url(https://s3.amazonaws.com/imse-qa-new/images/pattern_green_big.png);
            }
            &.testimonial-love-letter:before {
                background-image: url(https://s3.amazonaws.com/imse-qa-new/images/pattern_red_big.png);
            }
        }
    }

    #testimonial-pagination {
        display: flex;
        margin-top: var(--Large);

        ul {
            display: flex;
            margin: auto;
            padding: 0;

            li {
                padding: 0;
                text-align: center;
                width: 38px;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-size: 20px;

                &.active {
                    color: var(--BlueDark);
                    border-radius: 50%;
                    box-shadow: 0 4px 13px 0 #00000045;

                    span {
                        font-weight: bold;
                    }
                }

                &:before {
                    display: none;
                }

                a {
                    text-decoration: none;
                    color: #666666;
                    font-weight: bold;
                    box-shadow: none;
                    outline: none;
                    
                    &.prev, &.next {
                        color: var(--BlueDark);
                    }
                }
            }
        }
    }
}

.accredited-partner-columns {
    padding: 0 var(--Large);
}

.accreditation-wrapper {
    padding: 120px 120px 70px;
    background: #efefef;
    margin-top: -180px !important;

    section {
        margin: auto;
    }

    > div {
        max-width: 960px;
        margin: auto;
    }

    .accredited-partner-map {
        width: 960px;
        height: 600px;
        text-align: center;
    }
    .imse-accreditations {
        position: relative;
        box-shadow: 0px 3px 10px 0px #00000059;
        margin-top: 110px;

        &:before {
            content: ' ';
            display: block;
            background: url(https://s3.amazonaws.com/imse-qa-new/images/testimonials-bg-blue.png);
            position: absolute;
            width: 100%;
            height: 100%;
            left: -42px;
            top: -42px;
        }

        .imse-accreditations-content {
            display: flex;
            align-items: center;
            background: #ffffff;
            padding: var(--Large) var(--xLarge);
            z-index: 1;
            position: relative;
        }

        img {
            width: 270px;
            margin-left: var(--xLarge);    
        }
    }
    .accreditation-info {
        display: flex;
        align-items: center;
        margin-top: var(--Large);

        img {
            width: 220px;
            margin-top: 90px;
            margin-left: 70px;
        }
    }
    .accreditation-certified-partners {
        padding: 0;
        margin: var(--Large) auto;
        width: 1200px;
        margin-left: -120px;

        li.accreditation {
            box-shadow: 0px 3px 10px 0px #00000059;
            margin: 10px 14px;
            padding: 24px;
            background: #ffffff;

            img {
                width: 210px;
                height: 210px;
                object-fit: contain;
            }
        }

        button.slick-arrow {
            min-width: 0 !important;
            margin: 0 !important;
            box-shadow: none;
            width: 40px;
            height: 100%;
            z-index: 99;

            &.slick-next {
                right: 14px;
            }

            &.slick-prev {
                left: 14px;
            }

            &:before {
                font-size: 54px;
                color: #0a5b99;
                background: #ffffff;
                box-shadow: 0 0 25px #0000004d;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 45px;
                height: 45px;
                padding-top: 9px;
            }
        }
    }
}

#accredited-partner-renewal {
    background: url(https://s3.amazonaws.com/imse-qa-new/images/testimonials-bg-blue.png);
    padding: var(--xLarge) var(--Large) 290px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.renewal-cards {
    margin-top: -310px !important;
    padding: 0 var(--Large);
    text-align: center;
    
    .card * {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
}

.support-parent-wrapper {
    &.has-bg {
        background: url(https://s3.amazonaws.com/imse-qa-new/images/testimonials-bg-blue.png);
        padding: var(--xLarge) var(--Large);
        background-size: 115%;
        background-repeat: no-repeat;
    }

    > div {
        max-width: 960px;
        margin: auto;

        &.d-flex {
            background-color: #ffffff;
            display: flex;
            padding: 0 0 0 var(--xLarge);
            align-items: center;
            justify-content: space-between;
        }
    }

    &.section-1 {
        padding: 300px 120px 70px;
        background: #efefef;
        margin-top: -350px !important;

        > div {
            max-width: 1120px;

            .animated-box {
                background-color: var(--White);
            }
        }
    }

    &.section-products-for-parents {
        > div {
            margin-bottom: -150px;

            button.slick-arrow {
                min-width: 0 !important;
                margin: 0 !important;
                box-shadow: none;
                width: 40px;
                height: 100%;
                z-index: 99;

                &.slick-next {
                    right: -20px;
                }

                &.slick-prev {
                    left: -26px;
                }

                &:before {
                    font-size: 54px;
                    color: #0a5b99;
                    background: #ffffff;
                    box-shadow: 0 0 25px #0000004d;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 45px;
                    height: 45px;
                    padding-top: 9px;
                }
            }
        }
    }
}

.support-parent-accordions {
    background: #efefef;
    margin-top: -100px;
    padding-bottom: var(--Large);
}

.sp-video-container {
    position: relative;
    margin-top: 60px;

    &:before {
        content: ' ';
        display: block;
        background: url(https://s3.amazonaws.com/imse-qa-new/images/testimonials-bg-blue.png);
        position: absolute;
        width: 100%;
        height: 100%;
        left: -42px;
        top: -42px;
    }

    > div {
        box-shadow: 0px 3px 10px 0px #00000059;
        z-index: 1;
        position: relative;
        background-color: #ffffff;
        height: 535px;

        .play-video {
            background-image: url(https://imse-qa-new.s3.amazonaws.com/images/play-video.png);
            width: 220px;
            height: 220px;
            position: absolute;
            background-color: #00000038;
            background-repeat: no-repeat;
            background-size: 180px 180px;
            display: block;
            top: calc(50% - 110px);
            left: calc(50% - 110px);
            border-radius: 50%;
            background-position: center;
        }

        iframe {
            height: 100%;
            border: none;
        }
    }
}

.sp-d-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sp-d-flex-start {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.sp-popups-container {
    position: relative;
    max-width: 1200px;
    margin: auto;

    .sp-popups-wrapper {
        position: absolute;

        &.step-1 {
            top: 26px;
            left: 235px;
        }

        &.step-2 {
            top: 26px;
            left: 396px;
        }

        &.step-3 {
            top: 172px;
            left: 477px;
        }

        &.step-4 {
            top: 292px;
            left: 396px;
        }

        &.step-5 {
            top: 292px;
            left: 557px;
        }

        &.step-6 {
            top: 292px;
            left: 798px;
        }

        .sp-marker {
            background-color: #2f96d7;
            display: flex;
            align-items: center;
            opacity: 0;
            width: 86px;
            height: 86px;

            &:hover {
                opacity: 1;
            }

            > div {
                color: #ffffff;
                margin: auto;
                font-size: 58px;
                border-radius: 50%;
                line-height: 1.1;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 4px 13px 0 #00000045;
                width: 60px;
                height: 60px;
            }
        }

        .sp-popups-body {
            padding: var(--Large);
            width: 400px;
            background: #ffffff;
            box-shadow: 0 4px 13px 0 #00000045;
            border-bottom: 6px solid #0a5b99;
            display: none;
            position: absolute;
            bottom: 93px;
            left: -154px;

            h3 {
                font-weight: bold;
                margin: auto;
                color: #0a5b99;
            }
            p {
                margin-bottom: 0;
            }
        }

        &:hover {
            .sp-marker {
                opacity: 1;
            }
            .sp-popups-body {
                display: block;

                &:before {
                    content: ' ';
                    position: absolute;
                    bottom: -16px;
                    left: 178px;
                    border-left: 20px solid transparent;
                    border-right: 20px solid transparent;
                    border-top: 16px solid #0a5b99;
                }

                &:after {
                    content: ' ';
                    position: absolute;
                    width: 100%;
                    height: 20px;
                    bottom: -20px;
                    left: 0;
                    display: block;
                }
            }
        }
    }
}

.steps-img {
    width: 1200px;
    max-width: initial;
}

#videos-and-webinars-join-community {
    background: url(https://s3.amazonaws.com/imse-qa-new/images/testimonials-bg-blue.png);
    padding: var(--xLarge) var(--Large) var(--Large);
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
}

#videos-and-webinars-register {
    margin-top: 36px;
    padding: 0;
    background: url(https://s3.amazonaws.com/imse-qa-new/images/testimonials-bg-blue.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-shadow: 0px 3px 10px 0px #00000059;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .vw-left {
        margin: var(--Large) 0 var(--Large) 12px;
        background-color: #ffffff;
        padding: var(--Large) var(--xLarge);
        width: 58%;
    }

    .vw-right {
        text-align: center;
        width: 42%;
        padding: var(--Large);

        img {
            width: 100%;
        }
    }
}

#videos-and-webinars-learn-latest {
    background-color: #013879;
    padding: var(--Small);
    position: relative;
    overflow: hidden;

    h2 {
        margin: var(--Medium);
        z-index: 1;
        position: relative;
    }

    .bg-icons {
        &:before {
            content: ' ';
            position: absolute;
            top: -36px;
            left: -44px;
            background: url(https://s3.amazonaws.com/imse-qa-new/images/learn-webinar-left.png);
            width: 414px;
            height: 431px;
        }

        &:after {
            content: ' ';
            position: absolute;
            top: -36px;
            right: -44px;
            background: url(https://s3.amazonaws.com/imse-qa-new/images/learn-webinar-right.png);
            width: 386px;
            height: 425px;
        }
    }
 
    .videos-and-webinars-items {
        padding: 0;
        margin: var(--Large) auto;
        width: 1132px;
        margin-bottom: 80px;

        li.vw-item {
            box-shadow: 0px 3px 10px 0px #00000059;
            margin: 6px 10px;
            background: #ffffff;
            text-align: center;
            width: 315px;

            img {
                width: 100%;
                height: 342px;
                object-fit: contain;
                background-color: #ffffff;
            }

            div {
                padding: 10px 15px;

                h4 {
                    margin-top: 10px;

                    a {
                        color: var(--BlueDark);
                        text-decoration: none;
                    }
                }
                h6 {
                    margin-top: 0;
                    margin-bottom: 10px;
                }
                .button {
                    margin: 6px;
                }
            }
        }

        button.slick-arrow {
            min-width: 0 !important;
            margin: 0 !important;
            box-shadow: none;
            width: 50px;
            height: 50px;
            z-index: 99;

            &.slick-next {
                top: 108%;
                left: 53%;
            }

            &.slick-prev {
                top: 108%;
                left: 43%;
            }

            &:before {
                font-size: 54px;
                color: #ffffff;
                background: #0a5b99;
                box-shadow: 0 0 25px #0000004d;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 45px;
                height: 45px;
                padding-top: 9px;
            }
        }
    }
}

.vw-list-section {
    background-color: #f2f2f2;
    padding: var(--xLarge);
    display: flex;
    flex-direction: column;
    margin-top: -64px;

    .button {
        margin-right: 0;
    }

    section {
        margin: 0 auto var(--Medium) auto;
    }

    #vw-form {
        display: flex;
        align-items: end;
        justify-content: space-between;

        select {
            width: 252px;
            background-color: #ffffff;
        }

        input {
            max-width: 90px;
            margin-right: 10px;
            background-color: #ffffff;
        }

        .button {
            margin-left: 30px;
            margin-bottom: 5px;
            height: 44px;
        }

        .d-flex {
            display: flex;
        }
    }

    .vw-list-items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 90%;
        max-width: 1024px;
        margin-right: auto;
        margin-left: auto;
        padding: 0;
        list-style: none;
        gap: 40px;

        li {
            background-color: #ffffff;
            padding: 0;
            box-shadow: 0px 3px 10px 0px #00000059;
            text-align: center;
            max-width: 315px;

            img {
                width: 100%;
                object-fit: contain;
            }

            &:before {
                display: none;
            }

            .vw-type {
                color: #ffffff;
                background-color: #013879;
                text-align: center;
            }

            .vw-title {
                padding: var(--Medium);

                h5 {
                    margin: 0 auto var(--Large);
                    font-size: 18px;
                    font-weight: 600;
                }

                h4 {
                    margin-top: 0px;

                    a {
                        color: var(--BlueDark);
                        text-decoration: none;
                    }
                }

                h6 {
                    margin-top: 0;
                    margin-bottom: 20px;
                }
            }

            &:hover {
                background-color: #efefef;
            }
        }
    }

    .view-more {
        margin: var(--Large) auto 0 auto;
    }
}

.district-elevate-block {
    position: relative;
    width: 85%;
    margin: auto;

    &:after {
        content: " ";
        border: 2px solid #78B52E;
        position: absolute;
        top: -20px;
        left: -24px;
        width: 100%;
        height: 100%;
    }
}

.videoWrapper.district-bordered {
    position: relative;

    &:before {
        content: " ";
        border: 2px solid #78B52E;
        position: absolute;
        top: 12px;
        left: 12px;
        width: 100%;
        height: 100%;
    }
}

.district-info-table {
    background: #ffffff;
    padding: var(--Medium);
    border-radius: 4px;
    box-shadow: 0 4px 13px 0 #00000045;

    h4 {
        margin-top: var(--Small);
        font-size: 20px;
        font-weight: 500;
    }

    table {
        width: 100%;
        margin: auto;
        border-spacing: 0 20px;

        thead {
            th {
                text-align: left;
                padding: 6px var(--Medium);
                color: #000000;
                font-weight: 500;
            }
        }

        tbody {
            tr {
                background-color: #f8f8f9;

                td {
                    padding: 6px 0 !important;

                    &:first-child {
                        padding: 6px 0 6px var(--Medium);
                    }

                    &:last-child {
                        padding: 6px var(--Medium);
                    }

                    p {
                        border-right: 2px solid #d7d7d7;
                        padding-left: var(--Medium);
                        padding-right: var(--Medium);

                        &.has-left {
                            border-left: 2px solid #d7d7d7;
                        }

                        &:first-child {
                            padding-bottom: 6px;
                            margin-bottom: 0;
                            margin-top: 6px;
                        }

                        &:last-child {
                            margin-top: 0;
                            margin-bottom: 6px;
                            padding-top: 6px;
                            border-top: 2px solid #d7d7d7;
                        }
                    }

                    h5 {
                        margin: auto;
                        font-size: 16px;
                        font-weight: 500;
                        color: #78B52E;
                        border-right: 2px solid #d7d7d7;
                        line-height: 2.6;

                        &.no-border {
                            border: none;
                        }
                    }
                }
            }
        }
    }

    &.stripe {
        table {
            tbody {
                tr {
                    &:nth-child(even) {
                        background-color: #e0e6ef;
                    }
                }
            }
        }
    }

    .d-flex {
        display: flex;

        .right-block {
            margin-left: var(--Large);
            padding-left: var(--Large);
            border-left: 1px solid #d7d7d7;
        }

        .p-header {
            font-weight: 500;
            line-height: 2.8;
            color: #000000;
        }
        
        .p-body {
            line-height: 1.3;
        }
    }
}

.district-mark-info {
    margin: var(--xLarge) var(--Large) 0;

    p {
        background: url(https://s3.amazonaws.com/imse-qa-new/images/testimonials-bg-blue.png);
        padding: 42px var(--Large) var(--Large);
        background-repeat: no-repeat;
        color: #ffffff;
        font-size: 15px;
        position: relative;

        &:before {
            content: " ";
            width: 110px;
            height: 110px;
            position: absolute;
            left: -16px;
            top: -54px;
            background: url(https://s3.amazonaws.com/imse-qa-new/images/blue-quote-marker.png);
            background-size: 100%;
            z-index: 1;
        }

        &:after {
            content: " ";
            border: 2px solid #5FC20E;
            padding: 0 12px;
            position: absolute;
            width: calc(100% + 24px);
            height: 100%;
            left: -12px;
            top: -12px;
        }
    }
}

#district-contact-bottom {
    background: url(https://s3.amazonaws.com/imse-qa-new/images/testimonials-bg-blue.png);
    padding: var(--xLarge) var(--Large);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: auto;

    a.button {
        margin: 6px 24px;
    }
}

#voucher-product-section {
    h3 {
        margin-bottom: var(--xLarge);
    }

    h5 {
        font-weight: 500;
    }

    .voucher-type-select {
        width: auto;
        padding-right: var(--Large);
        margin-bottom: var(--Large);
        background-color: #E8E7E7;
    }

    .voucher-product-block {
        display: none;

        .inline-block {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: var(--xLarge);
        }

        .voucher-product-table {
            background: #ffffff;
            padding: 0 var(--Medium);
            border-radius: 4px;
            box-shadow: 0 4px 13px 0 #00000045;

            table {
                width: 100%;
                margin: auto;
                border-spacing: 0 20px;

                thead {
                    th {
                        color: #003B7C;
                        font-weight: 500;
                        font-size: 18px;
                        text-align: center;
                    }
                }

                tbody {
                    tr {
                        background-color: #f8f8f9;

                        &:nth-child(even) {
                            background-color: #e0e6ef;
                        }

                        td {
                            padding: 8px 0;
                            text-align: center;

                            h5 {
                                margin: auto;
                                font-size: 16px;
                                font-weight: 500;
                                color: #78B52E;
                                line-height: 2;
                            }

                            &:nth-child(2) {
                                h5 {
                                    border-left: 2px solid #d7d7d7;
                                    border-right: 2px solid #d7d7d7;
                                }
                            }
                        }
                    }
                }
            }
        }

        .voucher-product-action {
            .button-group {
                display: flex;
                flex-direction: column;
                margin-bottom: var(--Medium);
                
                label {
                    color: #2F96D7;
                    font-size: 18px;
                    margin-bottom: var(--Small);
                }

                .button {
                    margin: auto auto var(--Medium) 0;
                }

                input {
                    background-color: var(--White);
                }
            }
        }
    }
}

.district-full-with {
    background: #efefef;
    padding: 1px 0;

    .card-grid.columns-2 {
        margin: var(--xLarge) auto;
    }
}

.global-search-result {
    .d-flex {
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .result-filter {
        select {
            width: auto;
            margin-right: 32px;
            text-transform: capitalize;
            padding: 16px 48px 12px 24px;
        }
    }
    .result-card {
        margin: var(--xLarge) 0;
        .result-title {
            font-size: 24px;
            color: #0A5B99;
            font-weight: bold;
            font-family: 'Karla', sans-serif;
            margin: auto;
            text-decoration: none;
            letter-spacing: -1px;
        }
        .result-url {
            opacity: 0.5;
            font-size: 16px;
            font-weight: 500;
            margin: 1rem 0;
            line-height: 1.65;
        }
        .result-description {
            p {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                line-height: 1.65;
            }
        }
    }
    .result-video-card {
        padding: var(--Large) var(--Large) var(--Medium) var(--Large);
        background-color: var(--GreyLight);
        border-radius: 8px;
        margin: var(--Large) 0;
        max-width: 950px;

        h6 {
            font-size: 16px;
            font-weight: 500;
            margin: 0;
        }
        .result-video-carousel {
            padding: 8px 16px;
            margin: 8px 0 0;

            li {
                display: flex;
                flex-direction: column;
                margin: 4px 8px;
                width: 200px;
                position: relative;

                .v-card {
                    position: relative;
                    height: 115px;
                    background: #0093cbe8;

                    iframe {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        transition-duration: 0.5s;
                        opacity: 0.3;
                    }

                    &:hover {
                        iframe {
                            opacity: 1;
                            transition-duration: 0.5s;
                        }
                    }
                }


                a {
                    text-decoration: none;
                    color: var(--BlueDark);
                    font-weight: 500;
                    padding: 8px;
                    font-size: 14px;
                    height: 54px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    line-height: 1.5;
                }
            }

            .slick-arrow {
                min-width: 0 !important;
                margin: 0 !important;
                box-shadow: none;
                width: 36px;
                height: 36px;
                z-index: 99;
                
                &.slick-next {
                    right: -6px;
                }

                &.slick-prev {
                    left: -6px;
                }

                &:before {
                    font-size: 42px;
                    color: #ffffff;
                    background: var(--BlueDark);
                    box-shadow: 0 0 25px #0000004d;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 36px;
                    height: 36px;
                    padding-top: 6px;
                }
            }
        }
    }
    .result-pagination {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: var(--xLarge) 0;

        button {
            height: 50px;
            border-radius: 24px;
            padding: 10px var(--Large);
            font-size: 18px;
            margin: 0;
        }

        select {
            width: auto;
            margin-left: 16px;
            text-transform: capitalize;
            padding: 16px 48px 12px 24px;
        }
    }
}

.page-template-1 {
    main {
        padding: 0 !important;
    }
    .d-flex {
        display: flex;
        align-items: center;

        &.d-center {
            justify-content: center;
        }
    }

    .hero-content {
        background: linear-gradient(to left, #78b52e, #ffa300);
        padding: 0 var(--Medium);

        > div {
            padding: 0 1.5rem;
            justify-content: space-between;
        }

        .hero-content-title {
            padding: var(--Medium) var(--Large) var(--Medium) 0;

            h1 {
                margin: auto;
                font-weight: bold;
                color: #ffffff;
                font-family: 'Rubik', sans-serif;
                font-size: 36px;
                line-height: 1.3;
            }

            p {
                color: #ffffff;
                font-family: 'Karla', sans-serif;
                font-size: 18px;
                line-height: 1.3;
            }
        }

        img {
            width: 340px;
        }
    }

    section {
        h1 {
            text-align: center;
            font-size: 18px;
            font-family: 'Rubik', sans-serif;
            color: #003b7a;
            font-weight: bold;
        }

        h2 {
            text-align: center;
            line-height: 1.2;
            margin: 1rem;
            font-size: 42px;
        }

        h3 {
            text-align: center;
            font-size: 28px;
            line-height: 1.2;
        }

        p {
            font-size: 18px;
            text-align: center;
            margin: auto;
        }

        .process-steps {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 24px;
            padding: 0;
            margin-top: var(--Large);

            li {
                padding: 0;
                text-align: center;

                img {
                    width: 144px;
                    margin-top: var(--Medium);
                }

                h4 {
                    font-size: 18px;
                    margin-top: 1.5rem;
                }

                p {
                    font-size: 16px;
                    line-height: 1.5;
                }

                &:before {
                    display: none;
                }
            }
        }

        &.grey-block {
            background-color: var(--GreyLight);
            padding: 3rem var(--Medium);
        }

        .option-cards {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 24px;
            margin: 3rem auto;

            .option-card {
                background-color: #ffffff;
                border-radius: 12px;
                box-shadow: 0 7px 27px 0 rgba(0, 0, 0, 0.13);
                position: relative;

                .option-card-body {
                    background-color: #ffffff;
                    border-radius: 12px;
                    padding: 2rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: baseline;
                    margin-top: 0.75rem;
                    height: 100%;
                }

                > div {
                    background: #ffffff;
                    border-radius: 12px;
                }

                h5 {
                    margin: auto;
                    font-size: 20px;
                }

                p {
                    text-align: left;
                    margin: 1.3rem auto 0;
                    font-size: 16px;
                    line-height: 1.5;
                }

                &.blue-card {
                    background-color: var(--Blue);
                    h5 {
                        color: var(--Blue);
                    }
                }

                &.purple-card {
                    background-color: #8a4fba;
                    h5 {
                        color: #8a4fba;
                    }
                }

                &.yellow-card {
                    background-color: #ffa300;
                    h5 {
                        color: #ffa300;
                    }
                }
            }
        }

        .training-cards {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1px;
            align-items: baseline;
            position: relative;
            background: #ededed;

            &.col-2 {
                grid-template-columns: 1fr 1fr;
            }

            &.col-3 {
                grid-template-columns: 1fr 1fr 1fr;
            }

            > div {
                display: flex;
                flex-direction: column;
                padding: 2.5rem;
                position: relative;
                height: 100%;
                justify-content: space-between;
                text-align: center;
                background-color: #ffffff;

                &:last-child {
                    border-right: none;
                }

                .started-card {
                    padding: var(--Large) var(--Large) 3rem;
                    border-radius: 2rem;
                    color: #ffffff;
                    max-width: 430px;

                    &.og-card {
                        background: linear-gradient(to left, #006bcc, #003b7a);
                        margin-left: auto;
                    }
    
                    &.morphology-card {
                        background: linear-gradient(to left, #a32bc1, #671578);
                    }

                    h5 {
                        line-height: 1.3;
                        text-align: center;
                        font-size: 1.8rem;
                        margin-top: 0;
                        color: #ffffff;
                    }

                    .desc {
                        font-size: 1.5rem;
                        line-height: 1.3 !important;
                        max-width: 100% !important;
                    }

                    .btn1 {
                        color: #ffffff;
                        text-decoration: none;
                        margin: auto;
                        padding: 0.6rem 2rem;
                        border: 3px solid #ffffff;
                        border-radius: 2rem;
                        font-weight: bold;
                        font-size: 14px;
                        box-shadow: none;
                        background-color: transparent;

                        &:hover {
                            background-color: #ffffff;
                            color: var(--BlueDark);
                        }
                    }

                    p {
                        margin-bottom: 2rem !important;
                    }
                }
            }

            h4 {
                font-size: 28px;
                margin: 1rem auto;
                text-align: center;
                font-family: 'lexia', serif;
            }

            h5 {
                line-height: 1.3;
                text-align: center;
                font-size: 18px;
                margin-top: 0;
            }

            img {
                width: 340px;
                height: 100px;
                object-fit: contain;
                margin: auto;
                
                &.top {
                    margin: -6.5rem auto auto;
                }
            }

            .text-blue {
                color: var(--Blue);
            }

            .text-purple {
                color: #7e53b5;
            }

            .text-red {
                color: #d91f5c;
            }

            .btn2 {
                color: #000000;
                text-decoration: none;
                margin: var(--Large) auto var(--Small);
                padding: 0.6rem 2rem;
                border: 3px solid #bc94f0;
                border-radius: 2rem;
                font-weight: bold;
                font-size: 14px;
                box-shadow: none;

                &:hover, &:focus {
                    background-color: #bc94f0;
                    color: #ffffff;
                }
            }

            .link {
                font-weight: 600;
                text-decoration: none;
                margin: 0
            }

            .cta-btn {
                padding: 0.6rem 2rem;
                border: 2px solid #dfdfdf;
                border-radius: 2rem;
                font-weight: bold;
                font-size: 14px;
                margin: var(--Small) auto;
                text-decoration: none;

                &:hover {
                    background-color: #dfdfdf;
                }
            }

            ul {
                padding: 0;
                margin: 1rem 0;
                text-align: left;

                li {
                    padding: var(--Medium) var(--Small);
                    display: flex;
                    align-items: flex-start;
                    gap: 1.5rem;
                    font-size: 16px;

                    &:not(:last-child) {
                        border-bottom: 1px solid #ededed;
                    }

                    &:before {
                        display: none;
                    }

                    svg {
                        min-width: 1.2rem;
                        max-width: 1.2rem;
                    }
                }
            }            
        }

        #tsum-tabs {
            text-align: center;
            min-width: 320px;
            max-width: 100%;
            padding: 0;
            margin: 0 auto;

            h1 {
              padding: 50px 0;
              font-weight: 400;
              text-align: center;
            }

            p {
                line-height: 1.5;
                max-width: 70%;
                margin: 1rem auto;
            }

            section {
              display: none;
              padding: 0;
              margin: 4rem 0 -4rem 0;
              background: #ffffff;
            }

            input {
              display: none;
            }

            .title {
                color: #003b7a;
                padding: 2rem 1rem 2rem;
                margin: auto;
                border-bottom: 1px solid #ededed;
                border-top: 1px solid #ededed;
                display: none;
                font-size: 2rem;
                background-color: #f8f8f8;
            }

            label {
                display: inline-block;
                font-size: 18px;
                text-align: center;
                margin: 0.5rem 0.2rem;
                color: #ffffff;
                border: 1px solid #ffffff;
                padding: var(--Small) 1.5rem;
                border-radius: 2rem;
                cursor: pointer;

                &:hover {
                    background-color: #ffffff;
                    color: #003b7a;
                }
            }

            input:checked + label {
                background-color: #ffffff;
                color: #003b7a;
            }

            #tab1:checked ~ #content1,
            #tab2:checked ~ #content2,
            #tab3:checked ~ #content3,
            #tab4:checked ~ #content4 {
                display: block;
            }

            #tab5:checked ~ #content1,
            #tab5:checked ~ #content2,
            #tab5:checked ~ #content3,
            #tab5:checked ~ #content4 {
                display: block;

                .title {
                    display: block;
                }
            }
        }

        .form-block {
            margin-top: -4rem;
            display: grid;
            grid-template-columns: 1fr 1fr;

            form {
                margin: var(--Large) 3rem;
                max-width: 420px;

                .d-flex {
                    gap: 16px;
                }

                .field-item {
                    margin-bottom: 0.5rem;
                    label {
                        color: #003b7a;
                    }
                    span {
                        font-size: 14px;
                        color: #003b7a;
                    }
                }

                h3 {
                    text-align: left;
                    margin: var(--Medium) auto;
                }

                p {
                    text-align: left;
                    margin-bottom: var(--Medium);
                }

                .button {
                    background: #8bad59;
                    color: #ffffff;
                    margin: 1rem auto;
                    padding: 1rem 2rem;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                max-height: 810px;
            }

            img.submitted {
                max-height: 440px;
            }
        }

        .find-out-banner {
            background: linear-gradient(to left, #b46647, #df505f);
            margin-bottom: -4rem;
            z-index: 1;
            position: relative;

            .d-flex {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 3rem 0rem;

                h3 {
                    color: #ffffff;
                    margin: auto;
                    text-align: left;
                    font-family: 'Rubik', sans-serif;
                    font-weight: bold;
                    font-size: 28px;
                }

                h2 {
                    color: #ffffff;
                    font-family: 'Rubik', sans-serif;
                    font-weight: bold;
                    margin: auto;
                    font-size: 40px;
                    line-height: 1.3;
                }

                a {
                    margin: auto var(--xLarge) auto var(--Large);
                    font-size: 18px;
                    padding: 0.6rem 1.2rem;
                }
            }
        }

        &.blue-block {
            position: relative;
            background: linear-gradient(to left, #006bcc, #78b52e);
            padding: 3rem 0;
            margin: 7rem auto -4rem;

            .path-img {
                width: 130px;
                left: calc(50% - 65px);
                top: -50px;
                position: absolute;
            }

            h3 {
                color: #ffffff;
            }
        }

        &.impact-block {
            border-top: 1px solid #ededed;
            padding-top: var(--Large);

            ul {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                gap: 1rem;
                padding: 0;
                margin: var(--Medium) auto;

                li {
                    padding: var(--Small);
                    text-align: center;

                    h1 {
                        font-size: 3rem;
                        margin: var(--Medium) auto var(--Small);
                        font-weight: 400;
                    }

                    h5 {
                        font-size: 16px;
                        font-weight: 300;
                        color: var(--GreyDark);
                        margin: auto;
                    }

                    p {
                        line-height: 1.4;
                        font-size: 16px;
                    }

                    &:before {
                        display: none;
                    }
                }
            }
        }

        &.consulting-block {
            background: linear-gradient(to left, #be3561, #2e68c4);

            .hero-content-title {
                padding: var(--Medium) var(--Large) var(--Medium) 0;
            }

            h1 {
                margin: auto;
                font-weight: bold;
                color: #ffffff;
                font-family: 'Rubik', sans-serif;
                font-size: 36px;
                text-align: left;
                line-height: 1.3;
            }

            h4 {
                margin: auto;
                font-weight: bold;
                color: #ffffff;
                font-family: 'Rubik', sans-serif;
                text-align: left;
                font-size: 24px;
                line-height: 1.5;
            }

            p {
                color: #ffffff;
                font-family: 'Karla', sans-serif;
                font-size: 18px;
                line-height: 1.4;
                text-align: left;
                margin: 1.5rem auto;
            }

            a {
                margin: auto;
                font-size: 18px;
                padding: 0.6rem 1.2rem;
            }

            img {
                width: 390px;
                margin: -32px auto -24px;
            }
        }

        &#program-desc {
            p {
                text-align: left;
                margin: 1rem 0;
            }
        }
    }

    &.district-training-page, &.imse-lab {
        .hero-content {
            background: linear-gradient(to left, #7e53b5, #2e68c4);
        }
    }

    &.imse-lab {
        .warning-text {
            font-family: 'Karla', sans-serif;
            font-weight: bold;
            color: #dd386f;
            font-size: 26px;
            line-height: 1.5;
            margin-top: 5rem;
        }

        .subscribe-list {
            margin: var(--Large) auto;
            max-width: 65%;
            font-size: 18px;
        }

        .imse-lab-carousel {
            .slick-dots {
                bottom: -46px;
            }

            .slick-slide {
                text-align: center;
            }
            
            .slick-dots li {
                &:before {
                    display: none;
                }

                button {
                    box-shadow: none;

                    &:before {
                        font-size: 1rem;
                    }
                }
            }
        }

        .green-btn {
            color: #ffffff;
            text-decoration: none;
            margin: var(--Small);
            padding: 0.6rem 2rem;
            border: 3px solid #86b346;
            border-radius: 2rem;
            font-weight: bold;
            font-size: 14px;
            box-shadow: none;
            background-color: #86b346;

            &:hover {
                background-color: #ffffff;
                color: #86b346;
            }
        }

        .purple-bordered-btn {
            color: #000000;
            text-decoration: none;
            margin: var(--Small);
            padding: 0.6rem 2rem;
            border: 3px solid #bc94f0;
            border-radius: 2rem;
            font-weight: bold;
            font-size: 14px;
            box-shadow: none;

            &:hover, &:focus {
                background-color: #bc94f0;
                color: #ffffff;
            }
        }

        .subscribe-block {
            display: flex;
            background: linear-gradient(to left, #78b52e, #ffa300);
            position: relative;
            margin-bottom: -4rem;
            box-shadow: 0 4px 13px 0 #00000045;

            > div {
                background: #ffffff;
                margin: var(--xLarge) auto;
                position: relative;

                > img {
                    width: 290px;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }

                .subscribe-desc {
                    padding: var(--Large);

                    h3 {
                        margin-top: 1rem;
                        text-align: left;
                    }

                    p {
                        text-align: left;
                    }
                }

                .subscribe-action {
                    border-left: 2px solid var(--Grey);
                    padding: var(--Large);
                    text-align: center;
                }
            }
        }
    }

    &.programs-page {
        .hero-content {
            background: linear-gradient(to left, #78b52e, #52800d);
        }

        section {
            #tsum-tabs {
                section {
                    border-top: 1px solid #ededed;
                }

                margin-top: 2rem;

                label {
                    color: #4984d2;
                    border-color: #4984d2;
                }

                input:checked + label {
                    background-color: #4984d2;
                    color: #ffffff;
                }
            }

            #tab5:checked ~ #content1,
            #tab5:checked ~ #content2,
            #tab5:checked ~ #content3,
            #tab5:checked ~ #content4 {
                border-top: none;
            }
        }
    }

    .breadcrumbs {
        color: #ffffff;

        a {
            color: #ffffff;
            text-decoration: none;
        }
    }
}

@media screen and (max-width: $tablet) {
    .is-wide {
        width: 100%;
    }

    #videos-and-webinars-register {
        flex-direction: column;

        .vw-left {
            width: calc(100% - 2 * var(--Medium));
            margin: var(--Medium);
            padding: var(--Large);
        }

        .vw-right {
            width: calc(100% - 2 * var(--Medium));
            margin: var(--Medium);
            padding: var(--Medium);
        }

        .button {
            margin-top: var(--Medium);
        }
    }

    #videos-and-webinars-learn-latest {
        padding-left: 0;
        padding-right: 0;
        
        h2 {
            text-align: center;
            padding: 0 var(--Medium);
        }

        .bg-icons {
            display: none;
        }

        .videos-and-webinars-items {
            width: 100%;

            li.vw-item {
                width: 320px;
            }

            button.slick-arrow {
                &.slick-next {
                    top: 110%;
                    right: 10%;
                    left: auto;
                }

                &.slick-prev {
                    top: 110%;
                    left: 10%;
                }
            }
        }

    }

    .vw-list-section {
        padding-left: 0;
        padding-right: 0;

        #vw-form {
            flex-direction: column;
            padding: 0 var(--Medium);
            
            > div {
                width: 100%;

                select {
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: var(--Medium);
                }
            }

            .button {
                margin: var(--Medium) auto 0;
            }
        }

        .vw-list-items {
            grid-template-columns: 1fr;
            width: 100%;
            padding: var(--Medium);

            li {
                max-width: 100%;
            }
        }
    }

    #videos-and-webinars-join-community {
        background-size: cover;
    }

    .global-search-result {
        .result-pagination {
            flex-direction: column;

            #load-more-btn {
                margin-bottom: var(--Large);
            }
        }
    }

    .accreditation-wrapper {
        padding: 70px 24px;

        .accredited-partner-map {
            width: 100%;
            height: 320px;
        }

        .imse-accreditations {
            margin-top: 24px;
            margin-left: 24px;

            &:before {
                left: -24px;
                top: -24px;
            }

            .imse-accreditations-content {
                flex-direction: column;
                padding: 8px 24px 24px;
            }

            img {
                margin: auto;
            }
        }

        .accreditation-info {
            flex-direction: column;

            img {
                margin: 24px auto;
            }
        }
    }

    #accredited-partner-renewal {
        background-size: 100% 90%;
    }

    .renewal-cards {
        padding: 0;
    }

    .accredited-partner-columns {
        padding: 0;
    }

    #district-anchor,
    #schedule-training-section {
        padding: 24px;
    }

    #voucher-product-section {
        .voucher-type-select {
            width: 100%;
        }
        .voucher-product-block {
            .inline-block {
                grid-template-columns: 1fr;
                grid-row-gap: var(--Large);
            }
        }
    }

    .district-info-table {
        .d-flex {
            flex-direction: column;

            .right-block {
                margin-left: 0;
                padding-left: 0;
                border: none;
            }
        }

        table {
            thead {
                th {
                    font-size: 12px;
                    padding: 6px;
                    width: 100%;
                    max-width: 86px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            tbody {
                tr {
                    td {
                        font-size: 12px;
                        padding: 6px 0;
                        h5 {
                            font-size: 12px;
                        }
                        p {
                            width: 100%;
                            max-width: 86px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            padding: 0 6px;
                        }

                        &:first-child {
                            padding: 6px 0 6px 6px;
                        }

                        &:last-child {
                            padding: 6px;
                        }
                    }
                }
            }
        }
    }

    .district-elevate-block {
        margin-top: 64px !important;
    }

    .district-full-with {
        padding: 8px 16px;
        margin-top: 24px;
    }

    .page-template-1 {
        .hero-content {
            .hero-content-title {
                text-align: center;
                padding: var(--Medium) 0;

                h1 {
                    font-size: 32px;
                }

                p {
                    font-size: 18px;
                }
            }

            > div {
                flex-direction: column-reverse;
                padding: var(--Small) 0;
            }
        }

        .white-block {
            padding: 0;
        }

        section {

            h2 {
                font-size: 32px;
            }
            .process-steps {
                grid-template-columns: 1fr;
                gap: 16px;
            }
            .option-cards {
                grid-template-columns: 1fr;
                width: 100%;
                margin: var(--Large) auto;
                gap: 32px;

                .option-card {    
                    .option-card-body {
                        align-items: center;
                        h5, p {
                            text-align: center;
                        }
                    }
                }
            }

            .form-block {
                grid-template-columns: 1fr;

                form {
                    margin: auto var(--Medium);
                }

                img {
                    height: 320px;
                }
            }

            .find-out-banner {
                .d-flex {
                    flex-direction: column;
                    padding: 3rem 0;

                    h3 {
                        text-align: center;
                        font-size: 22px;
                        margin-bottom: 0.5rem;
                    }

                    h2 {
                        font-size: 36px;
                        margin: 0 0 1.5rem 0;
                    }

                    a {
                        margin: auto;
                        font-size: 1rem;
                        padding: 10px var(--Medium);
                    }
                }
            }

            &.blue-block {    
                .path-list {
                    flex-direction: column;
                }
            }

            .training-cards {
                &.col-2, &.col-3  {
                    grid-template-columns: 1fr;
                }

                > div {
                    padding: var(--Large) var(--Medium) !important;
                    border-right: none;

                    .started-card {
                        margin: auto;
                    }

                    &.bordered {
                        border: none;
                        padding: var(--Large) 0;
                        margin: auto;
                    }
                }

                ul {
                    margin: var(--Medium) 0;
                }

                img {
                    &.top {
                        margin: auto;
                    }
                }
            }

            #tsum-tabs {
                p {
                    max-width: 100%;
                }
            }

            &.impact-block  {
                flex-direction: column;

                ul {
                    grid-template-columns: 1fr 1fr;
                }
            }

            &.consulting-block {
                padding: var(--Medium) 0;

                .hero-content-title {
                    text-align: center;
                    padding: var(--Medium);
                    margin-top: var(--Large);
                }

                > .d-flex {
                    flex-direction: column-reverse;
                }

                h4, h1, p {
                    text-align: center;
                }

                a {
                    font-size: 1rem;
                    padding: 10px var(--Medium);    
                    margin-bottom: var(--Large);
                }
            }
        }

        &.imse-lab {
            .subscribe-list {
                max-width: 100% !important;
            }

            .subscribe-block {
                > div {
                    flex-direction: column;
                    margin: var(--Large);

                    > img {
                        width: 100%;
                    }

                    .subscribe-action {
                        border-left: none;
                        border-top: 2px solid var(--Grey);
                    }
                }
            }
        }
    }
}
