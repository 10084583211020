body.login {
  header {
    &.post-head {
      padding: var(--Large) 0 var(--Medium) 0;
    }
  }

  main {
    > article {
      margin-top: 0;
    }
  }
}

.register-templates {
  .standard {
    .flexbox {
      @media screen and (min-width: $tablet) {
        margin: 0 auto var(--xLarge);
      }

      > div {
        margin-bottom: var(--xLarge);

        @media screen and (min-width: $tablet) {
          margin-bottom: 0;
        }

        h2,
        h1 {
          margin-top: 0;
        }
      }
    }

    .editor {
      @media screen and (min-width: $tablet) {
        margin-right: 2.84259%;
        padding-right: 25px;
      }

      form {
        max-width: 100%;

        .field-item {
          margin-bottom: 20px;
        }

        input[type="checkbox"] {
          width: 16px;
          height: 16px;
          margin-right: var(--Small);
          vertical-align: middle;
        }
      }
    }
  }
}