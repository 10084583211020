.checkout-templates {
  #card-number-element,
  #card-expiry-element,
  #card-cvc-element {
    background-color: #f2f2f2;
    padding: 16px 24px;
  }

  hr.classic {
    border-top: 1px solid rgba(0, 0, 0, 0.26);
    width: 100%;
    max-width: 100%;
    background: no-repeat;
    margin: 20px 0;
  }

  .mobile-inline {
    @media screen and (max-width: $tablet) {
      flex-direction: column;
      gap: 0 !important;
    }
  }

  .checkout-layout {
    display: flex;

    @media screen and (max-width: $tablet) {
      flex-direction: column;
    }

    .checkout-address {
      .flex {
        display: flex;
        gap: 30px;

        & > div {
          flex: 1;
        }
      }

      .flex.mobile-inline {
        @media screen and (max-width: $tablet) {
          flex-direction: column;
          gap: 0;
        }
      }
    }
  }

  .selected_shipping_address {
    margin-top: 50px;
    font-size: 18px;
    color: black;
  }

  #update_address {
    cursor: pointer;
    margin-left: 30px;
    text-align: right;
  }

  .district-autocomplete {
    position: relative;

    span.search-icon {
      position: absolute;
      top: 15px;
      left: 20px;
    }

    input {
      padding-left: 60px;
    }

    .autocomplete-items {
      position: absolute;
      z-index: 9;
      background: white;
      border: 1px solid var(--BlueDark);
      width: 100%;
      top: 60px;
      padding: 16px;

      .autocomplete-item {
        padding: 6px 16px;
        cursor: pointer;

        &:hover {
          background-color: #EDEDED;
        }

        .autocomplete-item-title {
          color: black;
          font-size: 14px;
          list-style: 16px;
        }
        .autocomplete-item-content {
          font-size: 14px;
          display: flex;
          line-height: 16px;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }

  .billing-address-type-selector {
    margin-bottom: 20px;
    div {
      font-size: 14px;
      border: 1px solid var(--BlueDark);
      padding: 2px 26px;
      max-width: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--BlueDark);
      cursor: pointer;

      @media screen and (max-width: $tablet) {
        width: 50%;
      }

      &.active {
        color: white;
        background-color: var(--BlueDark);
      }
    }
  }

  .close_address_box {
    cursor: pointer;
  }

  .address-add-or-edit,
  .address-listbox {
    // background-color: #F9F9F9;
    border: 1px solid #0082A0;
    border-radius: 4px;
    margin-bottom: 20px;

    label {
      letter-spacing: initial !important;
      font-weight: normal !important;
      font-size: 16px !important;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      padding: 26px 26px 30px 26px;
      
      h4 {
        font-size: 20px;
        margin: 0;
      }
    }

    &__content {
      padding: 26px 26px 0 26px;
    }

    &__footer {
      padding: 0 26px 26px 26px;
      text-align: right;

      button {
        margin: 0;
      }
    }

    input[type=radio] {
      width: 17px;
      height: 17px;
    }

    .add_new_address {
      margin-left: auto;
      display: block;
      width: fit-content;
      cursor: pointer;
    }

    #close_address_box {
      cursor: pointer;
    }
  }
  .editor {
    form {
      max-width: 100%;

      .field-item {
        margin-bottom: 20px;
      }

      .product-item {
        margin-bottom: 20px;
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: $tablet) {
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          gap: 20px;
        }

        .assign-btn {
          min-width: 135px;
          margin-left: 20px;
        }

        .product {
          display: flex;
          align-items: center;
          width: 100%;

          .image-wrapper {
            width: 103px;
            min-width: 103px;
            height: 70px;
            border-radius: 14px;
            background-color: var(--GreyLight);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            margin-right: 30px;

            img {
              object-fit: contain;
              height: 100%;
              width: auto;
            }
          }

          &__name {
            color: var(--Black);
            font-style: normal;
            font-weight: 800;
            font-size: 20px;
            line-height: 1.1;
            font-family: "Karla", sans-serif;
            text-decoration: none;

            @media screen and (max-width: $tablet) {
              font-size: 16px;
            }
          }

          &__type {
            font-size: 15px;
            font-weight: 200;
            color: var(--GreyDark);
          }
        }
      }

      input[type="checkbox"] {
        width: 16px;
        height: 16px;
        margin-right: var(--Small);
        vertical-align: middle;
      }
    }
  }
  .continue-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;

    @media screen and (max-width: $tablet) {
      button,
      .button {
        width: 100%;
        margin: 0;
        margin-bottom: 15px;
      }
    }
  }

  .checkout-sidebar {
    margin-left: 50px;
    max-width: 400px;
    width: 100%;

    @media screen and (max-width: $tablet) {
      margin-left: 0;
      margin-top: 55px;
      max-width: 100%;
      width: 100%;
      border-top: 1px solid var(--GreyDark);
    }

    .checkout_step {
      details {
        border: none;
        background: none;
        margin: 0;
        box-shadow: none;

        &[open] {
          span.drop {
            transform: rotate(180deg);
          }
        }
      }
      summary {
        font-family: 'Karla';

        &:after {
          width: 19px;
          height: 19px;
          top: 0;
          right: 13px;
          content: none;
        }
      }
      &_title {
        border-radius: 8px;
        padding: 8px 16px;
        font-size: 16px;
        position: relative;
        color: var(--GreyDark);
        display: flex;
        align-items: center;

        span.drop {
          position: absolute;
          top: 7px;
          right: 25px;
        }

        a {
          font-size: 14px;
          font-weight: normal;
          margin-left: 15px;
        }

        &.passed {
          color: black;
          font-weight: bold;
          padding-right: 45px;
        }

        &.active {
          font-weight: bold;
          color: white;
          background-color: var(--BlueDark);
        }
      }
      &_content {
        padding: 10px 25px;
        font-size: 14px;

        .item {
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: black;

          span {
            line-height: 16px;
          }

          span.title {
            margin-right: 20px;
          }

          span.quantity {
            color: var(--GreyDark);
            white-space: nowrap;
          }

          span.price {
            margin-left: auto;
            font-weight: 600;
          }
        }
      }
    }

    &__footer {
      padding: 16px;
      border-top: 1px solid var(--GreyDark);
      margin-bottom: 20px;
      color: black;

      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .checkout-pay {
    p {
      font-size: 18px;
      color: black;
      margin: 0;

      @media screen and (max-width: $tablet) {
        font-size: 15px;
      }
    }
    .step-title {
      font-size: 20px;
      color: black;
      font-weight: 600;
      white-space: nowrap;

      @media screen and (max-width: $tablet) {
        font-size: 18px;
      }
    }
    .step-content {
      padding-left: 30px;
      padding-top: 10px;

      @media screen and (max-width: $tablet) {
        padding-left: 10px;
      }

      .address {
        flex: 1;
        text-align: right;

        @media screen and (max-width: $tablet) {
          text-align: left;
        }
      }
    }

    table {

      .total-amount {
        font-size: 32px;

        @media screen and (max-width: $tablet) {
          font-size: 25px;
        }
      }

      th {
        font-weight: 400;
        font-size: 18px;
        padding: 0 30px;
      }

      td {
        font-size: 22px;
        font-weight: 400;
        color: black;
        padding: 0px 30px;

        .discount-price {
          color: var(--Green);
          margin-bottom: -20px;
          line-height: 17px;
        }

        .voucher {
          color: var(--Green);
          font-size: 18px;
        }
      }

      @media screen and (max-width: $tablet) {
        th, td {
          font-size: 15px !important;
          padding: 0 3px !important;
        }

        td.product-name {
          position: relative;
          padding-bottom: 30px !important;
        }

        .voucher {
          font-size: 14px !important;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 250px;
        }
    
        td {
          padding: var(--Small) 7px !important;
        }
    
        .product-name {
          span.product_type {
            font-size: 12px !important;
          }
        }
      }
    }

    .product-name {
      color: var(--Black);
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      font-family: Karla, sans-serif;
      text-decoration: none;

      @media screen and (max-width: $tablet) {
        font-size: 14px;
        line-height: 17px;
      }
      
      .flex {
        display: flex;
        align-items: center;
        padding: 10px 0;

        @media screen and (max-width: $tablet) {
          flex-direction: column-reverse;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 0;
        }
      }

      .product_type {
        font-size: 15px;
        font-weight: 200;
        color: var(--GreyDark);
      }

      .image-wrapper {
        width: 170px;
        min-width: 170px;
        height: 120px;
        border-radius: 14px;
        background-color: var(--GreyLight);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        margin-right: 30px;

        @media screen and (max-width: $tablet) {
          width: 120px;
          min-width: 120px;
          height: 80px;
        }

        img {
          object-fit: contain;
          height: 100%;
          width: auto;
        }
      }
    }
  }

  .scroll {
    width: 100%;
    overflow-y: auto;
  }
}