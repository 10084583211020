/*-----------------------------------*\
	LISTS
\*-----------------------------------*/

ul {
  margin: var(--Medium) 0;
  list-style: none;

  > li {
    position: relative;
    padding: var(--xSmall) 0 var(--xSmall) 24px;

    &::before {
      display: block;
      position: absolute;
      top: 11px;
      left: 0;
      width: 11px;
      height: 11px;
      margin-right: var(--Medium);
      border-radius: 50%;
      content: '';
      background-color: var(--Yellow);
    }
  }

  &.normal {
    li {
      color: var(--White);
      &::before {
        top: 14px;
        width: 4px;
        height: 4px;
        background-color: var(--White);
      }
    }
  }
}

ol {
  margin: var(--Medium) auto;
  list-style: none;
  counter-reset: number-counter;

  > li {
    position: relative;
    padding: var(--xSmall) 0 var(--xSmall) 26px;
    counter-increment: number-counter;

    &::before {
      display: block;
      position: absolute;
      top: 9px;
      left: 0;
      min-width: 20px;
      margin-right: var(--Small);
      color: var(--Black);
      font-weight: 800;
      line-height: 1;
      content: '';
      content: "\2022";
      content: counter(number-counter);
    }
  }
}

/*-----------------------------------*\
	COLUMNS
\*-----------------------------------*/
.has-2-columns {
  column-count: 1;

  @media screen and (min-width: $phone) {
    column-count: 2;

    > li {
      padding-right: 1em;
    }
  }
}

.has-3-columns {
  column-count: 1;

  @media screen and (min-width: $phone) {
    max-width: 1024px;
    margin: 4em auto;
    column-count: 3;

    > li {
      padding-right: 1em;
    }
  }
}

.has-4-columns {
  column-count: 1;

  @media screen and (min-width: $phone) {
    column-count: 2;

    > li {
      padding-right: 1em;
    }
  }

  @media screen and (min-width: $tablet) {
    max-width: 1024px;
    margin: 4em auto;
    column-count: 4;
  }
}
