.sign-in .button.is-text {
  padding: 0;
  margin: 0;
}

.sign-in .notification {
  position: fixed;
  right: 32px;
  bottom: 32px;
  animation: fadeIn 0.3s ease forwards;
  padding: 4px 8px;
  border: 1px solid var(--GreyLight);
  border-radius: 3px;
  padding-left: 20px;
  padding-right: 25px;
  background: var(--Green);
  z-index: 10;
  font-weight: 800;
}

.sign-in .notification__text {
  color: var(--White);
  margin: var(--Small) 0;
}

.sign-in .notification__close {
  position: absolute;
  top: 10px;
  right: 6px;
  font-family: Helvetica;
  font-style: normal;
  color: var(--White);
  margin-top: -8px;
  cursor: pointer;
}

.sign-in .tabular-group__field-list-admin-edit p .signin_icon {
  font-family: Helvetica;
  font-style: normal;
  font-size: 1.25em;
}

.sign-in .tabular-group__field-list-admin-edit p .signin_icon.in {
  color: var(--Green);
}

.sign-in .tabular-group__field-list-admin-edit p .signin_icon.out {
  color: var(--RedDark);
}

.sign-in .tabular-group-admin-edit {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--Large);
}

.sign-in .tabular-group__field-admin-edit {
  border-top: 1px solid var(--Grey);
  margin-top: var(--Large);
  padding-top: var(--Large);
}

.sign-in .tabular-group__field-admin-edit .signin_name {
  margin-bottom: 0;
  margin-top: 0;
}

.sign-in .tabular-group__field-admin-edit .signin_email {
  margin-top: 0;
}

.sign-in .signin__delete {
  display: flex;
  flex-direction: column;
  margin-top: var(--Large);
  margin-bottom: var(--Large);
}

.sign-in .signin__delete a:first-child {
  margin-bottom: var(--Medium);
}

.signature_pad {
  width: 100%;
  border: 1px solid black;
}

.signin__header {
  padding-top: var(--Large);
  padding-bottom: var(--Large);
  padding-left: var(--Medium);
  padding-right: var(--Medium);
}

.signin__button-group {
  text-align: center;
  margin-top: var(--Large);
  margin-bottom: var(--Large);
}

.signin__button-group a:last-child {
  margin-right: 0;
}

.signin__header-list {

}

.signin__header-list p {
  margin: 0;
}

.signin__header-list p:first-child {
  font-weight: 800;
  letter-spacing: 0.5px;
  font-size: 1rem;
  color: var(--Black);
}

.signin__header .logo {
  width: 100%;
  height: 110px;
}

.signin__title {
  margin: 0;
  padding-top: var(--Small);
  padding-bottom: var(--Small);
  background-color: var(--BlueDark);
  border-bottom: 1px solid var(--White);
  color: var(--White);
  font-size: 18px;
  font-weight: 800;
  text-align: center;
}

.signin__container {
  margin-top: var(--Large);
  margin-bottom: var(--Large);
}

.signin__container form {
  max-width: 100%;
}

.signin__container .field-item {
  margin-bottom: 20px;
}

.signin__search-header {
  margin-bottom: var(--Large);
}

.signin__search-header p {
  color: var(--Black);
}

.signin__search-header-view-day {
  margin-bottom: var(--Large);
  padding-left: var(--Large);
  padding-right: var(--Large);
}

.signin__search-header-view-day p {
  margin: 0;
  color: var(--Black);
}

.signin__action-header {
  display: flex;
}

.signin__action-header div {
  flex: 1;
  text-align: center;
}

.signin__action-header div:first-child button {
  margin-right: 0;
}

.signin__action-header div:last-child a {
  padding-top: 7px;
  padding-bottom: 6px;
  margin-right: 0;
}

.sign-in__overflow-table {
  overflow-x:auto
}

.sign-in__table {
  width: 100%;
  margin-top: var(--Large);
  margin-bottom: var(--Large);
  border-collapse: collapse;
  border-spacing: 0;
}

.sign-in__table th {
  color: var(--Black);
  padding-left: var(--Large);
  padding-right: var(--Large);
}

.sign-in__table td {
  padding-left: var(--Large);
  padding-right: var(--Large);
  padding-top: var(--Medium);
  padding-bottom: var(--Medium);
}

.sign-in__table .odd td {
  background: var(--GreyLight);
}

.sign-in-checkmark {
  text-decoration: none;
}

.sign_in_legend__icon {
  width: 20px;
  height: 20px;
  background: #ffcf9a;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.signin__action-button {
  padding-left: var(--Large);
  padding-right: var(--Large);
  display: flex;
  justify-content: center;
}

.signin__action-button a:last-child {
  margin-right: 0;
}

@media screen and (min-width: 800px) {
  .signin__header .logo {
    width: auto;
    margin-left: -26px;
  }

  .signin__search-header p.sign_in_legend,
  .signin__header-list,
  .signin__button-group {
    text-align: right;
  }

  .signin__action-header div {
    flex: 1;
    text-align: left;
  }

  .signin__action-header div:last-child {
    text-align: end;
  }

  .sign-in .tabular-group-admin-edit {
    flex-direction: row;
    border-top: 1px solid var(--Grey);
    margin-top: var(--Large);
    margin-bottom: var(--Large);
    padding-top: var(--Large);
    padding-bottom: var(--Medium);
  }

  .sign-in .tabular-group__field-admin-edit {
    width: calc(100% / 3);
    margin-bottom: 0;
    padding-right: 2vw;
  }

  .sign-in .tabular-group__field-admin-edit {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }

  .sign-in .signin__delete {
    flex-direction: row;
    justify-content: flex-end;
    margin-top: var(--Large);
    margin-bottom: 0;
  }

  .sign-in .signin__delete a:first-child {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sign-in .signin__delete a:last-child {
    margin-right: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}