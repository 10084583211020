.account-templates {
  .standard {
    max-width: 1400px;
    .inner-wrapper {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: $tablet) {
        flex-direction: row;
      }

      .sidebar {
        @media screen and (min-width: $tablet) {
          flex-basis: 25vw;
        }

        h2 {
          margin-top: 0;
        }

        nav {
          margin-bottom: 17px;

          ul {
            display: flex;
            flex-wrap: wrap;
            margin-left: -10px;
            margin-right: -10px;

            @media screen and (min-width: $tablet) {
              flex-direction: column;
              flex-wrap: nowrap;
            }

            li {
              margin-top: 0;
              margin-left: 10px;
              margin-right: 10px;
              margin-bottom: 10px;

              a {
                padding: 0;
                color: var(--Black);
                font-weight: normal;
                text-decoration: underline;
                text-transform: none;

                @media screen and (min-width: $tablet) {
                  text-decoration: none;
                }

                &:hover {
                  text-decoration: underline;
                  color: var(--BlueDark);

                  @media screen and (min-width: $tablet) {
                    text-decoration: none;
                  }
                }
              }
            }
          }
        }
      }

      .editor {
        margin: 0 0 38px;

        @media screen and (min-width: $tablet) {
          flex-basis: 75vw;
        }

        h2 {
          margin-top: 0;
        }

        form {
          max-width: 100%;

          .field-item {
            margin-bottom: 20px;
          }

          input[type="checkbox"] {
            width: 16px;
            height: 16px;
            margin-right: var(--Small);
            vertical-align: middle;
          }
        }

        .grid {
          @media screen and (min-width: $tablet) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
          }
        }

        .product {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: var(--Medium);
          margin-bottom: var(--Medium);

          img {
            width: 100%;
            height: auto;
          }

          div {
            padding-right: var(--Small);
          }

          h2 {
            margin: var(--Medium) 0 0;
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 1.1;
            font-family: "Karla", sans-serif;

            a {
              text-decoration: none;
            }
          }

          p {
            color: var(--Grey);
            font-size: 14px;
          }
        }

        .red-text-alert {
          color: var(--Red);
          font-weight: bold;
        }
      }

      .online_training__item {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: var(--Large);
        box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.35);
        padding: 24px 27px;

        &__title {
          display: flex;
          flex-wrap: wrap;
          & > * {
            flex: 1;
            margin-right: 20px;
            margin-top: 0;
            margin-bottom: 20px;
          }

          & > p {
            text-align: center;
          }

          @media screen and (max-width: $tablet) {
            flex-direction: column;

            & > p {
              text-align: left;
            }
          }
        }

        &__description {
          margin-bottom: 20px;
        }

        &__info {
          padding: 0 20px;
          
          p {
            margin-top: 0;
            margin-bottom: 20px;
          }

          @media screen and (max-width: $desktop) {
            padding-left: 0;
          }
        }

        &__buttons > div {
          text-align: right;

          @media screen and (max-width: $phone) {
            text-align: center;
          }
        }

        & > span:first-child {
          flex: 3;
          min-width: 300px;

          @media screen and (max-width: $desktop) {
            min-width: 100%;
          }
        }

        & > span:not(:first-child) {
          flex: 1;
          // min-width: 150px;
        }

        .button {
          margin-bottom: var(--Medium);
          margin-right: 0;
          min-width: 180px;
          min-height: 46px;

          @media screen and (max-width: $phone) {
            min-width: 100%;
          }
        }
      }

      .certification_account__status {
        margin-bottom: var(--Large);
        margin-top: var(--Large);
      }

      .iog2_info_title {
        display: flex;
        justify-content: space-between;
        padding: 16px 27px;
        margin-bottom: var(--Medium);

        > strong {
          min-width: 30%;
        }
      }

      .iog2_info_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 27px;

        > div {
          min-width: 30%;
        }

        .green {
          color: var(--Green);
        }

        .red {
          color: var(--Red);
        }

        .button {
          background-color: var(--Blue);
          color: #ffffff;
          margin-left: 30px;
          padding: 10px 32px;
        }
      }

      .lab-announcement-banner {
        margin: var(--Large) var(--xLarge) -2.4rem;
        width: calc(100% - var(--xLarge)* 2);

        @media screen and (max-width: $tablet) {
          margin: var(--Large) 0 -2rem;
          width: 100%;
        }
      }
    }
  }
}