.gc_customRadioControl {
  margin: 5px;
  margin-left: 0px;
  display: inline-flex;
  position: relative;
  cursor: pointer;
}
.gc_radioBtn {
  position: absolute;
  top: 0px;
  visibility: hidden;
  display: none;
}
.gc_customLabel {
  border-radius: 20px;
  min-width: 60px;
  padding: 10px;
  text-align: center;
  border: 1px solid grey;
  cursor: pointer;
}
.gc_radioBtn:checked {
  border-color: red;
  color: red;
 }
.gc_radioBtn:checked~.gc_customLabel {
  border-color: red;
  color: red;
}
.gc_amountEditor {
  margin: 5px;
  margin-left: 0px;
  padding: 5px 5px 5px 5px;
  max-width: 100px;
  border: 1px solid #ced4da;
  border-radius: 0.1875rem;
}
.gc_amount {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}