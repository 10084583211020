/*-----------------------------------*\
	ANIMATED BOXES
\*-----------------------------------*/
.animated-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;

  @media screen and (min-width: $phone) {
    justify-content: space-between;
  }

  .animated-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    min-width: 150px;
    margin: 0 0 var(--Large) 0;
    padding: 0;
    border-bottom: 8px solid var(--Blue);
    overflow: hidden;
    color: var(--Black);
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    font-family: 'Karla', sans-serif;
    text-align: center;
    cursor: pointer;
    background-color: var(--GreyLight);

    .description {
      font-size: 0.8em;
      font-weight: normal;
      color: var(--GreyDark);
    }

    &:before {
      display: none;
    }

    .content {
      position: absolute;
      top: 100%;
      right: 0;
      bottom: 0;
      left: 0;
      padding: var(--Medium);
      color: var(--White);
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2;
      text-align: left;
      background-color: var(--Blue);
      transition: all 0.2s linear;

      b,
      strong {
        display: block;
        margin-bottom: var(--Small);
      }
    }

    &:hover,
    &:focus {
      .content {
        top: 0;
      }
    }

    &:nth-child(2n) {
      border-color: var(--BlueDark);

      .content {
        background-color: var(--BlueDark);
      }
    }

    &:nth-child(3n) {
      border-color: var(--Red);

      .content {
        background-color: var(--Red);
      }
    }

    &:nth-child(4n) {
      border-color: var(--Yellow);

      .content {
        background-color: var(--Yellow);
      }
    }

    &:nth-child(5n) {
      border-color: var(--Green);

      .content {
        background-color: var(--Green);
      }
    }
  }

  &.standard {
    width: 100%;
  }

  &.grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--GreyLight);
  }
  &.grid .animated-box {
    margin: 0;
    border-bottom: none;
    border-top: 1px solid var(--GreyLight);
    background-color: white;
    flex: 1;
    max-width: 20%;

    @media screen and (max-width: $tablet) {
      max-width: 100%;
    }  
  }
  &.grid .animated-box:not(:last-child) {
    border-right: 1px solid var(--GreyLight);
  }
}
