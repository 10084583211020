/*----------------------------------------------------------------*\
	STYLES APPLIED TO VARIOUS WOOCOMMERCE ELEMENTS, SECTIONS, AND TEMPLATES
\*----------------------------------------------------------------*/
.woocommerce form .form-row .required { visibility: visible; }

.screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(1px,1px,1px,1px);
  overflow: hidden;
  word-wrap: normal!important;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
}

.woocommerce-button.button {
	display: block;
	margin-top: 24px;
}

.books-in-series {
  a {
    color: var(--BlueDark);
    font-weight: normal;
    text-decoration: none;

    &:hover,
    &:focus {
      color: var(--Blue);
      text-decoration: underline;
    }
  }
}

/*------------------------------*\
	WOO NOTICES
\*------------------------------*/
.clear {
  clear: both;
}

.woocommerce img,
.woocommerce-page img {
  height: auto;
  max-width: 100%;
}

.woocommerce-notices-wrapper {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;

  .woocommerce-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto var(--xLarge) auto;
    padding: var(--Medium) var(--Large);
    border-top: 4px solid var(--Blue);
    color: var(--Black);
    font-style: normal;
    font-size: 18px;
    font-family: "Karla", sans-serif;
    box-shadow: 0 7px 27px 0 rgba(0, 0, 0, 0.13);

    .button {
      order: 2;
      margin: 0;
      padding: 14px 0;
      padding: var(--Small);
      color: var(--Blue);
      background-color: transparent;
      box-shadow: none;

      &::after {
        display: inline-block;
        margin-left: var(--xSmall);
        content: ">";
      }

      &:hover,
      &:focus {
        color: var(--BlueDark);
        text-decoration: underline;
        background-color: transparent;
      }
    }
  }

  li::after {
    display: block;
    content: "";
    clear: both;
  }
}

/*------------------------------*\
	ARCHIVE
\*------------------------------*/
/* page layout */
body.post-type-archive-product,
body.tax-product_cat,
body.page-template-archive-product {
  main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    grid-auto-flow: column dense;
    grid-column-gap: 40px;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto 96px auto;

    header.woocommerce-products-header {
      grid-column: 1 / span 4;
    }

    .page-title-header,
    .woocommerce-notices-wrapper,
    ul.products {
      grid-column: 1 / span 4;

      @media screen and (min-width: $tablet) {
        grid-column: 2 / span 3;
      }
    }

    .woocommerce-info {
      grid-column: 2 / span 3;
      text-align: center;
    }

    #sidebar {
      grid-column: 1 / span 4;

      &.products-leftbar {
        background: white;
        width: 360px;
        padding: 48px 32px;
        border-right: 1px solid var(--GreyLight);
        display: none;
      }

      @media screen and (min-width: $tablet) {
        grid-column: 1 / span 1;
        grid-row: 2 / span 5;

        &.products-leftbar {
          grid-row: auto;
          display: block;
        }
      }

      ul.menu {
        column-count: 1;

        @media screen and (min-width: $phone) {
          column-count: 2;
        }

        @media screen and (min-width: $tablet) {
          column-count: 1;
        }
      }
    }
  }

  &.products {
    main#main-content {
      width: 100%;
      max-width: 1500px;
      padding: 0 15px;
    }
    
  }
  &.digital {
    main {
      max-width: 1400px;
    }
  }
}

.woocommerce-error {
  margin: 0 auto var(--xLarge) auto;
  padding: var(--Medium) var(--Large);
  border-top: 4px solid var(--Red);
  color: var(--Black);
  font-style: normal;
  font-size: 14px;
  font-family: "Karla", sans-serif;
  box-shadow: 0 7px 27px 0 rgba(0, 0, 0, 0.13);

  li {
    padding: var(--xSmall) 0;

    &:before {
      display: none;
    }
  }
}

/* page header */
header.woocommerce-products-header {
  padding: var(--Large) 0 var(--xLarge) 0;

  h2 {
    margin: 0 0 var(--xSmall) 0;
  }

  nav.woocommerce-breadcrumb {
    float: left;
    width: 100%;
    color: var(--Black);
    font-weight: bold;
    line-height: 34px;

    @media screen and (min-width: $tablet) {
      width: calc(100% - 300px);
    }

    a {
      color: var(--Black);
      font-weight: normal;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  form.woocommerce-ordering {
    display: flex;
    justify-content: space-between;
    justify-content: flex-start;
    align-items: center;
    float: none;
    width: 100%;

    @media screen and (min-width: $phone) {
      justify-content: space-between;
      float: right;
      width: auto;
    }

    &::before {
      display: inline-block;
      margin-right: var(--Medium);
      color: var(--Black);
      font-weight: 800;
      line-height: 1;
      content: "Sort By";
    }

    select {
      width: auto;
      padding: 8px 32px 8px 8px;
      border: none;
      color: var(--Black);
      font-weight: 400;
      background-color: transparent;
      background-position: top 5px right 4px;
    }
  }
}

/* page title */
.page-title-header {
  h2,
  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    font-family: "Karla", sans-serif;

    @media screen and (min-width: $phone) {
      font-size: 28px;
    }
  }
}

/* products */
ul.products {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 24px;
  width: 100%;
  // margin: var(--Large) 0;
  padding: 0;

  @media screen and (min-width: $phone) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: $laptop) {
    grid-template-columns: repeat(3, 1fr);

    &.digital {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &::before,
  &::after {
    display: none !important;
  }

  li {
    padding: 0;
    border-radius: 10px;

    .image-wrapper {
      background-color: var(--GreyLight);
      padding: 20px;
      height: 270px;
      min-height: 270px;
      display: flex;

      img {
        height: auto;
        width: 100%;
        object-fit: contain;
      }
    }

    .product-content {
      padding: 16px;
    }

    &::before {
      display: none;
    }

    a {
      text-decoration: none;
    }

    div.woocommerce-LoopProduct-link {
      display: flex;
      flex-direction: column;
      height: 100%;
      text-decoration: none;
      background-color: var(--White);
      box-shadow: 0 4px 13px 0 #00000045;
      border-radius: 15px;
      padding: 15px;
      transition: 0.3s all;

      &:hover,
      &:focus {
        color: var(--White);
        background-color: var(--Red);

        h2,
        .product-meta,
        .price,
        .price del,
        .price ins {
          color: var(--White);
        }
      }

      & div.product-content {
        padding: 20px;
        background: var(--GreyLight);
        border-radius: 15px;
        height: 280px;
        text-align: center;
        width: 100%;
  
        img {
          width: auto;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    > *:not(h2) {
      flex-grow: 0;
    }

    span.onsale {
      display: flex;
      position: absolute;
      top: 8px;
      right: 8px;
      justify-content: center;
      align-items: center;
      padding: var(--Small) var(--Medium);
      border-radius: 4px;
      color: Var(--Black);
      font-weight: 800;
      font-size: 12px;
      line-height: 1;
      text-transform: uppercase;
      background-color: var(--Green);
    }

    .titles {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin: var(--Medium) 0 var(--Medium) 0;

      span {
        width: 22px;
        min-width: 22px;
        height: 18px;
        margin-left: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;

        &.like {
         background-image: url('https://imse-qa-new.s3.amazonaws.com/images/like.png');
        }
        &.unlike {
         background-image: url('https://imse-qa-new.s3.amazonaws.com/images/unlike.png');
        }
      }
    }

    h2 {
      flex-grow: 1;
      margin: 0 0 16px 0;
      padding: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 1.1;
      font-family: "Karla", sans-serif;
    }

    .product-meta {
      padding: 0 !important;
      color: var(--Grey);
      font-size: 16px;
    }

    .action {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto 0 0;

      button {
        padding: 7px 14px;
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        background-color: var(--BlueDark);
        min-width: auto;
        color: #ffffff;
      }
    }

    span.price {
      display: flex;
      padding: 0;
      color: var(--Black);
      font-style: normal;
      font-size: 24px;
      line-height: 1.1;
      font-family: "lexia", serif;

      .amount {
        display: flex;
      }

      @media screen and (min-width: $phone) {
        font-size: 28px;
      }

      del {
        display: block;
        margin: var(--xSmall) 0;
        color: var(--Grey);
        font-size: 14px;
      }

      ins {
        text-decoration: none;
      }
    }
  }
}

/* sidebar */
body.post-type-archive-product #sidebar,
body.tax-product_cat #sidebar,
body.page-template-archive-product #sidebar {
  > ul {
    margin: 0;
    padding: 0;
  }

  > h3 {
    margin: 0;
    font-family: "Karla", sans-serif;
    font-weight: 700;
  }

  ul.menu {
    margin: var(--Medium) 0;
    padding: 0;

    li {
      padding: var(--xSmall) 0;

      &:before {
        display: none;
      }

      &.current-menu-item a {
        background-color: var(--GreyLight);
      }

      a {
        display: block;
        padding: var(--xSmall) var(--Medium);
        border-radius: 4px;
        color: var(--Black);
        font-weight: 800;
        text-decoration: none;
        cursor: pointer;

        &:hover,
        &:focus {
          background-color: var(--Blue);
          color: white;
        }
      }
    }
  }

  &.products-leftbar {
    select {
      padding-left: 15px;
      margin-bottom: 25px;
      border-bottom: none;
      background-color: #ffffff;
      border-bottom: 4px solid var(--GreyLight);
      border-top: 4px solid var(--GreyLight);
      border-color: var(--GreyLight);
    }

    .menu-item {
      a {
        box-shadow: none;
        outline: none;
        border-radius: 6px;

        &.active {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: var(--Blue);
          color: #ffffff !important;

          span {
            margin-left: 8px;
            font-weight: bold;
            cursor: pointer;
          }
        }
        &.all {
          background-color: var(--Green);
          color: #ffffff;
          border-radius: 6px;
          padding: var(--Small);
          color: #ffffff;
          text-align: center;
        }
        &.like {
          color: #e26456;
          display: flex;
          align-items: center;
          span {
            width: 20px;
            height: 18px;
            margin-right: 20px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url('https://imse-qa-new.s3.amazonaws.com/images/like.png');
          }
        }
        &.library {
          color: var(--Green);
          div {
            display: flex;
            align-items: center;
          }
          span.icon {
            margin-left: 0;
            margin-right: 15px;
          }
        }
        &.comprehensive {
          div {
            display: flex;
            align-items: center;

            span {
              background-color: var(--Blue);
              width: 20px;
              height: 5px;
              display: block;
              margin-right: 15px;
            }
          }
        }
        &.awareness {
          div {
            display: flex;
            align-items: center;

            span {
              background-color: #e8a202;
              width: 20px;
              height: 5px;
              display: block;
              margin-right: 15px;
            }
          }
        }
        &.morphology {
          div {
            display: flex;
            align-items: center;

            span {
              background-color: #b26cd5;
              width: 20px;
              height: 5px;
              display: block;
              margin-right: 15px;
            }
          }
        }
        &.favorite {
          color: #e26456;
          div {
            display: flex;
            align-items: center;
          }
          span.icon {
            margin-left: 0;
            margin-right: 15px;
          }
        }
      }
    }

    h3 {
      border-bottom: 4px solid var(--GreyLight);
      padding: var(--Medium) 0;
    }

    .accordions {
      margin: auto;

      &.level-1 {
        border-bottom: 4px solid var(--GreyLight);

        > details > summary:after {
          background-image: url('https://s3.amazonaws.com/imse-qa-new/images/arrow-up.svg') !important;
          content: ' ';
          width: 24px;
          height: 10px;
          top: 12px;
          right: 8px;
          padding: 0;
          background-repeat: no-repeat;
          border-radius: 0;
          background: inherit;
          box-shadow: none;
        }

        > details[open] > summary:after {
          transform: rotate(180deg);
        }
      }

      &.level-2 {
        padding-left: var(--Small);

        ul {
          padding-left: var(--Small);
        }

        > details > summary:after {
          background-image: url('https://s3.amazonaws.com/imse-qa-new/images/arrow-up.svg') !important;
          content: ' ';
          width: 24px;
          height: 10px;
          top: 12px;
          right: 8px;
          padding: 0;
          background-repeat: no-repeat;
          border-radius: 0;
          background: inherit;
          box-shadow: none;
        }
        > details[open] > summary:after {
          transform: rotate(180deg);
        }
      }
      
      details {
        box-shadow: none;
        border-left: none;
        padding: var(--xSmall);

        ul {
          margin: auto;
        }
        
        summary {
          color: var(--Black);
          font-weight: 800;
          font-size: 16px;
          line-height: 1.618;
          font-family: 'Karla', sans-serif;
          border-radius: 6px;
          padding: var(--xSmall) var(--Large) var(--xSmall) var(--Medium);

          &:hover {
            background-color: var(--Blue);
          }
        }
      }
    }
  }
}

.article-content {
  grid-column: 1 / span 4;

  @media screen and (min-width: $tablet) {
    grid-column: 2 / span 3;
  }

  section {
    width: 100%;
    margin: var(--Large) auto;

    &.price-card {
      .description {
        width: 100%;
        margin: var(--Large) 0 0;

        a {
          padding-top: 0;
        }
      }

      .price {
        width: 100%;
        border-left: 0;
        border-top: 0;
        margin: 0;
        padding-top: 0;
      }
    }
  }
}

/*------------------------------*\
	SINGLE
\*------------------------------*/
/* page layout */
body.single-product {
  header.woocommerce-products-header {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
  }

  main > .product {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 40px;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto 96px auto;

    .woocommerce-product-gallery {
      grid-column: 1 / span 6;

      @media screen and (min-width: $tablet) {
        grid-column: 1 / span 3;
      }
    }

    .product-image-wrapper {
      padding: 25px;
      background: var(--GreyLight);
      border-radius: 15px;
      border: 1px solid var(--Grey);
      height: 430px;
      text-align: center;
      margin-bottom: 16px;

      img {
        height: 100%;
        width: auto;
        object-fit: contain;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }
    }

    .product-image-thumbnails {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      grid-column-gap: 8px;
      width: 100%;

      & > div {
        background: var(--GreyLight);
        border: 1px solid var(--Grey);
        border-radius: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 104px;
        padding: 8px;
        cursor: pointer;

        &.active {
          border: 3px solid #0082A0;
        }

        img {
          height: 100%;
          width: auto;
          object-fit: contain;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
      }
    }

    .summary {
      grid-column: 1 / span 6;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: $tablet) {
        grid-column: 4 / span 3;
      }
    }

    .details.products,
    .related.products {
      grid-column: 1 / span 6;
      padding: 40px 0;
      width: 100%;
      margin: 0;
    }
    
    .bordered {
      border-top: 2px solid var(--GreyLight);
    }
  }
}

/* gallery */
.product .woocommerce-product-gallery {
  // display: flex;
  // width: 100% !important;
  // margin: 0;

  .flex-viewport {
    order: 2;
    max-width: 600px !important;
  }

  figure {
    margin: 0;
  }

  ol.flex-control-nav {
    flex-shrink: 0;
    margin: var(--xSmall) 0 0 0;
    margin: 0 40px 0 0;
    padding: 0;
    list-style: none;

    li {
      width: 48px;
      height: 48px;
      margin: 0 0 var(--Medium) 0;
      padding: 0;

      &::before {
        display: none;
      }

      img {
        display: block;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        cursor: pointer;
        object-fit: cover;
        object-position: center;

        &.flex-active {
          box-shadow: 0 0 0 3px var(--Blue);
        }
      }
    }
  }
}

/* summary */
.product .summary {
  width: 100% !important;
  margin: var(--Large) 0 0 0 !important;

  @media screen and (min-width: $tablet) {
    margin: 0 !important;
  }

  span.onsale {
    display: inline-block;
    margin: 0 var(--Small) 0 0;
    padding: var(--Small) var(--Medium);
    border-radius: 4px;
    color: Var(--Black);
    font-weight: 800;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    background-color: var(--Green);
  }

  .product_title_wrapper {
    display: flex;
    justify-content: space-between;

    .product-actions{
      display: flex;
      & > span {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  h1 {
    margin: 0 0 var(--Small) 0;
    font-size: 24px;
    font-weight: bold;

    @media screen and (min-width: $phone) {
      font-size: 28px;
    }
  }

  h4.product-author {
    font-size: 18px;
    margin: 0;
    color: var(--Grey);
    font-weight: lighter;
  }

  .product-description {
    font-size: 20px;
    color: black;
    margin-top: 60px;
    min-height: 200px;
    line-height: 24px;
    flex: 1;
  }

  .price {
    display: block;
    color: var(--Grey);
    margin: 30px 0;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 1.1;

    span.product-price-value {
      margin-left: 20px;
      font-size: 24px;
      font-weight: bold;
      color: black;
      font-family: "lexia", serif;
    }

    del {
      display: block;
      margin: var(--xSmall) 0;
      color: var(--Grey);
      font-size: 14px;
    }

    ins {
      text-decoration: none;
    }
  }

  ul {
    margin: var(--Medium) 0;
    padding: 0;
  }

  table {
    th, td {
      padding: 0px var(--Medium);
      text-align: center;
    }
  }

  form {
    max-width: 100%;
    margin: initial;

    &:not(.variations_form) {
      display: flex;
    }

    table {
      width: 100%;

      td {
        vertical-align: top;
      }
    }

    select.has-placeholder {
      color: var(--Black);
    }

    .woocommerce-variation-add-to-cart {
      display: flex;
    }

    input.qty {
      width: 80px;
      margin-right: var(--Medium);
      padding: 16px 16px 12px 16px;
    }

    a.view,
    button {
      min-width: 200px;
      height: 50px;
      margin: 0;
      color: var(--White);
      background-color: var(--Blue);
      box-shadow: none;

      &:hover,
      &:focus {
        color: var(--White);
        background-color: var(--BlueDark);
      }
    }

    .quantity-button-wrapper {
      display: flex;
      flex-direction: column;

      .quantity {
        input {
          width: 80px;
          margin-right: var(--Medium);
          padding: 16px 16px 12px;
        }
      }

      .button-group {
        margin: 16px 0;

        input {
          min-width: 200px;
          height: 100%;
          margin: 0;
          color: var(--White);
          background-color: var(--Red);
          box-shadow: none;
        }
      }

      &.flex-row {
        flex-direction: row;
        flex-wrap: wrap;

        & > div {
          margin: 8px 0;
        }

        .button-group {
          margin: 8px 0;
        }
      }
    }
  }
}

.checkout-title {
  @media screen and (max-width: $tablet) {
    text-align: center;
  }
}

.discount-price {
  color: var(--Green);
  margin-bottom: -20px;
  line-height: 17px;
}

.cart-items-table {
  tr {
    position: relative;
  }
  
  .remove-voucher {
    color: var(--Blue);
    margin-left: 15px;
    font-size: 16px;
  }

  .product-name {
    a.remove {
      display: none;
      position: absolute;
      bottom: 19px;
      left: 85vw;
    }
  }
  
  @media screen and (max-width: $tablet) {

    th, td {
      font-size: 15px !important;
    }

    td {
      padding: var(--Small) 7px !important;
    }

    .product-name {
      span.product_type {
        font-size: 12px !important;
      }

      a.remove {
        display: block;
      }
    }

    .product-remove {
      display: none;
      width: 48px;
      position: absolute;
      left: calc(100vw - 70px);
      bottom: 10px;

      a {
        background-color: transparent !important;
      }
    }
  }
}

.product_cat-ticket .summary,
.product-type-grouped .summary {
  form.cart {
    display: block;

    table td {
      padding-bottom: 16px;
    }
  }
}

.product-addon-totals li::before {
  display: none;
}

/* related */
.related.products {
  margin: 96px 0 0 0;

  h2.title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 25px;
  }

  .slick-track {
    margin: initial !important;
  }

  ul.products {
    grid-template-columns: repeat(2, 1fr);

    @media screen and (min-width: $tablet) {
      grid-template-columns: repeat(4, 1fr);
    }

    li {
      overflow: hidden;

      .product-content {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      h2 {
        font-weight: bold;
      }

      span.price {
        font-size: 32px;
        font-weight: bold;
        margin: 16px auto 0 auto;
        justify-content: center;
      }
    }
  }
}

.scroll {
  width: 100%;
  overflow-y: auto;
}

/* details */
.details.products {
  margin-top: 30px !important;
  h2.title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 25px;
  }

  .details-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 40px;
    width: 100%;

    @media screen and (min-width: $tablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    & > div {
      display: flex;
      margin-bottom: 16px;

      span {
        font-size: 18px;
        line-height: 21px;
        display: block;
      }
    }

    .details-title {
      color: var(--Grey);
      min-width: 190px;
    }
    .details-content {
      color: black;
    }
  }
}


/* hide 'add to cart' for ticket products */
body.single-product .product_cat-ticket .summary form.cart {
  display: none;
}

/*------------------------------*\
	CART
\*------------------------------*/
/* header */
body.product-search header.post-head,
body.woocommerce-cart header.post-head {
  height: auto;
  min-height: 0;
  padding: var(--Large) 0 0 0;

  @media screen and (min-width: $tablet) {
    padding: var(--Large) 0 var(--xLarge) 0;
  }

  .flex-wrapper {
    display: flex;
    flex-direction: column;
    position: initial;
    justify-content: space-between;
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;

    @media screen and (min-width: $tablet) {
      flex-direction: row;
      align-items: center;
    }

    .left-col,
    .right-col {
      @media screen and (min-width: $tablet) {
        flex: 1;
      }
    }

    .left-col {
      margin-bottom: var(--xLarge);

      @media screen and (min-width: $tablet) {
        margin-bottom: 0;
      }

      nav.woocommerce-breadcrumb {
        width: 100%;
        color: var(--Black);
        font-weight: bold;
        line-height: 34px;

        a {
          color: var(--Black);
          font-weight: normal;
          text-decoration: none;

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }

    .right-col {
      width: 100%;

      form {
        display: flex;

        input[type=text] {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          background-color: var(--White);
          border-bottom-color: var(--GreyLight);
        }

        input[type=submit] {
          width: 50%;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }


  h1 {
    position: initial;
    padding: 0;
    text-align: left;
    transform: none;
    min-width: auto;

    a.button {
      position: relative;
      top: -8px;
    }
  }
}

/* table */
body.woocommerce-cart main {
  article {
    margin-top: 0;
  }

  form {
    max-width: 100%;

    table {
      display: table;
      width: 100%;
      max-width: 100%;
      margin: 0;
      table-layout: fixed;
      border-spacing: 0;
      border-collapse: collapse;

      thead {
        display: none;
      }

      tbody {
        display: table;
        width: 100%;

        tr {
          display: table-row;
          width: 100%;
          font-size: 18px;

          &:not(:first-child) {
            border-top: 4px solid var(--GreyLight);
          }

          td {
            padding: var(--Large) var(--Small);
            background-color: var(--White) !important;
            color: black;
            font-size: 18px;
          }
        }

        .product-remove {
          width: 48px;

          @media screen and (min-width: $tablet) {
            width: 64px;
          }

          a {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            border: 4px solid transparent;
            border-radius: 50%;
            color: var(--White);
            font-size: 28px;
            line-height: 1;
            text-decoration: none;
            background-color: var(--Red);
            transition: all 0.2s linear;

            @media screen and (min-width: $tablet) {
              width: 40px;
              height: 40px;
              color: var(--Red);
              background-color: transparent;
            }

            &:hover,
            &:focus {
              border: 4px solid var(--RedDark);
              color: var(--RedDark);

              svg path {
                fill: var(--RedDark);
              }
            }
          }
        }

        .product-thumbnail {
          width: 150px;

          img {
            width: 120px;
            height: 120px;
          }
        }

        .product-name {
          color: var(--Black);
          font-style: normal;
          font-weight: 800;
          font-size: 18px;
          line-height: 1.1;
          font-family: Karla, sans-serif;
          text-decoration: none;
          width: 55%;

          @media screen and (max-width: $tablet) {
            padding-bottom: 70px !important;
            position: relative;
            padding-top: 24px !important;
          }

          .voucher {
            display: flex;
            align-items: center;
            margin-top: 5px;
            color:#0A5B99;

            @media screen and (max-width: $tablet) {
              position: absolute;
              bottom: 4px;
              width: 300px;
              height: 60px;

              summary {
                font-size: 14px !important;
              }
            }

            details {
              box-shadow: none;
              margin: 0;
              padding: 0;
              border: 0;

              summary {
                font-size: 18px;
                font-weight: bold;
                padding: 0;
                display: flex;
                align-items: center;
                color:#0A5B99;

                &::after {
                  display: none;
                }
              }

              & > div {
                padding: 0;

                button {
                  min-width: 50px;
                }
              }
            }

            &.applied {
              color: var(--Green);
            }

            svg {
              margin-right: 5px;
            }
          }

          & > div {
            display: flex;
            align-items: center;
            
            @media screen and (max-width: $tablet) {
              flex-direction: column-reverse;
              justify-content: flex-start;
              align-items: flex-start;
            }

            .image-wrapper {
              width: 170px;
              min-width: 170px;
              overflow: hidden;
              height: 120px;
              border-radius: 14px;
              background-color: var(--GreyLight);
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 8px;
              margin-right: 30px;

              @media screen and (max-width: $tablet) {
                width: 120px;
                min-width: 120px;
                height: 80px;
              }

              img {
                object-fit: contain;
                height: 100%;
                width: auto;
              }

              &.online {
                padding: 0;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }

          span.product_type {
            font-size: 15px;
            font-weight: 200;
            color: var(--GreyDark);
          }

          a {
            color: var(--Black);
            font-style: normal;
            font-weight: 800;
            font-size: 20px;
            line-height: 1.1;
            font-family: "Karla", sans-serif;
            text-decoration: none;

            @media screen and (max-width: $tablet) {
              font-size: 14px;
            }
          }

          dl {
            font-size: 14px;
            font-weight: normal;
            margin: 0 0 0 8px;

            dt {
              float: left;
              clear: both;
              margin: 0 6px 0 0;
            }

            p {
              margin: 0;
            }

            dd {
              margin: 8px 0;
            }
          }
        }

        .product-price {
          // display: none;
        }

        .product-quantity {
          input {
            width: 96px;

            @media screen and (max-width: $tablet) {
              width: 60px;
              padding: 10px;
              height: 50px;
              text-align: center;
            }
          }
        }
      }

      td.actions {
        padding: var(--Medium) 0;

        .button,
        button {
          display: flex;
          margin-left: auto;
        }
      }

      .coupon {
        margin-top: 24px;
        margin-bottom: 24px;

        @media screen and (min-width: $tablet) {
          width: 420px;
        }

        input {
          margin-right: var(--Small);
          padding: 10px var(--Medium) 6px var(--Medium);

          @media screen and (min-width: $tablet) {
            min-width: 200px;
          }
        }
      }

      .certify {
        width: 200px;
      }
    }

    .cart-collaterals {
      width: 100%;
      margin-bottom: 45px;

      @media screen and (min-width: $tablet) {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 50px;
      }

      .cart_totals {
        width: 48%;
        max-width: 400px;

        @media screen and (max-width: $tablet) {
          width: 100%;
        }
      }
    }

    .cart_conditions {
      padding-right: 80px;
      flex: 2;

      @media screen and (max-width: $tablet) {
        padding-right: 0;
      }

      .tax_exempt__ask {
        display: flex;
        align-items: center;

        .tax_exempt__label {
          font-size: 18px;
          margin: 0;
        }
      }

      .tax_exempt__radio {
        font-size: 18px;
        display: flex;
        align-items: center;
        margin-left: 30px;

        @media screen and (max-width: $tablet) {
          margin-left: 15px;
        }

        input {
          width: 20px;
          height: 20px;
          margin-right: 13px;
        }

        label {
          font-size: 16px;
          margin: 0;
        }
      }
    }
  }
}

/* totals */
body.woocommerce-cart main {
  .cart_totals {
    max-width: 400px;

    table {
      display: table;
      width: 100%;
      max-width: 100%;
      margin: 0;
      table-layout: fixed;
      border-spacing: 0;
      border-collapse: collapse;

      tr {
        display: table-row;
        width: 100%;
        border-top: none !important;

        &.order-total {
          border-top: 2px solid #D7D7D7 !important;
        }

        &.green {
          th, td {
            color: var(--Green);
          }
        }

        // &:nth-child(even) {
        //   background-color: var(--GreyLight);
        // }

        th {
          // padding: var(--Small) var(--Medium);
          text-align: left;
        }

        td {
          padding: var(--Small) var(--Medium);
          text-align: right;
          background-color: transparent !important;
        }
      }

      ul {
        margin: 0;
        padding: 0;

        li {
          &:before {
            display: none;
          }
        }
      }

      .woocommerce-shipping-destination {
        margin: 0;
        font-size: 14px;

        & + form {
          display: none;
        }
      }
    }

    .wc-proceed-to-checkout {
      margin-top: var(--Medium);

      a.button {
        position: relative;
        min-width: 160px;
        border-radius: 0;
        color: var(--White);
        font-size: 12px;
        text-transform: uppercase;
        background: transparent;
        box-shadow: none;

        &::after {
          display: block;
          z-index: -1;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 160%;
          letter-spacing: 0.5px;
          content: "";
          background-image: url(../images/brushstroke.png);
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: contain;
          transition: all 0.2s linear;
          transform: translate(-50%, -50%);
        }

        &:hover,
        &:focus {
          background-color: transparent;

          &::after {
            background-image: url(../images/brushstroke-hover.png);
          }
        }
      }
    }
  }
}

/*------------------------------*\
	CHECKOUT
\*------------------------------*/
.select2-results {
  ul li::before {
    display: none;
  }
}

/* header */
body.woocommerce-checkout {
  header.post-head {
    height: auto;
    min-height: 0;
    padding: var(--Large) 0 var(--xLarge) 0;

    h1 {
      display: flex;
      position: initial;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      max-width: 720px;
      margin: 0 auto;
      padding: 0;
      text-align: left;
      transform: none;

      a.button {
        position: relative;
        top: -8px;
      }
    }
  }

  &.woocommerce-order-received header.post-head h1 {
    max-width: 960px;
  }

  article > section.editor {
    max-width: 960px;
  }
}

/* form */

.woocommerce-form__label-for-checkbox {
  input {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: var(--Small);
    border: 2px solid var(--GreyLight);
    border-radius: 4px;
    vertical-align: middle;
    cursor: pointer;

    -webkit-appearance: none;
    -moz-appearance: none;

    &:checked {
      border-color: var(--Green);
      background-color: var(--Green);

      & + span::before {
        opacity: 1;
      }
    }

    & + span {
      position: relative;
      cursor: pointer;

      &::before {
        display: block;
        opacity: 0;
        position: absolute;
        top: 1px;
        left: -31px;
        width: 16px;
        height: 16px;
        content: "";
        background-image: url("../images/checkmark-white.svg");
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;
        transition: all 0.15s linear;
      }
    }
  }
}

body.woocommerce-checkout main {
  article {
    margin-top: 0;

    form.checkout {
      width: 90%;
      max-width: 720px;
      margin: 0 auto;

      .col-1,
      .col-2 {
        width: 100% !important;
      }

      .col-1 {
        margin-bottom: var(--xLarge);
      }

      .required {
        display: none;
      }

      .select2-selection {
        height: auto;
        border: none;

        .select2-selection__arrow {
          display: none;
        }

        > span {
          width: 100%;
          padding: 16px 24px 12px 24px;
          border: none;
          border-bottom: 4px solid var(--GreyLight);
          border-radius: 4px;
          color: var(--Black);
          font-weight: 800;
          line-height: 1 !important;
          background-color: var(--GreyLight);
          background-image: url(../images/select_arrow.svg);
          background-repeat: no-repeat;
          background-position: top 14px right 8px;
          background-size: 24px;

          -webkit-appearance: none;
          -moz-appearance: none;

          &:focus {
            border-color: var(--BlueDark);
            outline: none;
            box-shadow: none;
          }
        }
      }

      label span.optional {
        margin-left: var(--xSmall);
        color: var(--BlueDark);
        font-size: 12px;
        vertical-align: text-bottom;
      }

      input[type="file"] {
        position: relative;
        padding: 0;
        border: none;
        border-radius: 4px;
        font-weight: 400;
        background-color: transparent;
      }

      .alg_checkout_files_upload_file_input::-webkit-file-upload-button {
        display: none;
        visibility: hidden;
      }

      .alg_checkout_files_upload_file_input::before {
        display: inline-block;
        width: auto;
        min-width: 120px;
        padding: 10px var(--Medium);
        border: none;
        border-radius: 4px;
        color: var(--Grey);
        font-weight: 800;
        font-size: 16px;
        font-family: Karla, sans-serif;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        content: "Upload Purchase Order";
        background-color: var(--GreyLight);
        transition: all 0.2s linear;

        -webkit-user-select: none;
      }

      #alg_checkout_files_upload_result_1 {
        span a {
          padding: 6px 12px;
          border-radius: 4px;
          color: var(--White);
          text-decoration: none;
          background: var(--Blue);
        }

        a.alg_checkout_files_upload_result_delete {
          padding: 5px 12px;
          border-radius: 4px;
          color: var(--White) !important;
          font-size: 18px;
          text-decoration: none;
          background-color: var(--Red);
        }
      }
    }
  }

  .woocommerce-billing-fields {
    h3:first-child {
      margin-top: 0;
    }
  }

  .woocommerce-shipping-fields {
    h3 {
      @media screen and (min-width: $tablet) {
        margin-top: 0;
      }

      label {
        position: relative;
        color: var(--Black);
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 1.1;
        font-family: "lexia", serif;

        @media screen and (min-width: $phone) {
          font-size: 28px;
        }

        input {
          flex-shrink: 0;
          width: 24px;
          height: 24px;
          margin-right: var(--Small);
          border: 2px solid var(--GreyLight);
          border-radius: 4px;
          vertical-align: middle;
          cursor: pointer;

          -webkit-appearance: none;

          &:checked {
            border-color: var(--Green);
            background-color: var(--Green);

            & + span::before {
              opacity: 1;
            }
          }
        }

        span::before {
          display: block;
          opacity: 0;
          position: absolute;
          top: 6px;
          left: -34px;
          width: 16px;
          height: 16px;
          content: "";
          background-image: url(../images/checkmark-white.svg);
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: contain;
          transition: all 0.15s linear;
        }
      }
    }
  }

  .woocommerce-additional-fields {
    .tax-exempt-option {
      .woocommerce-input-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .tax-exempt > label {
          margin-bottom: var(--Small);
        }

        input {
          flex-shrink: 0;
          width: 24px;
          height: 24px;
          margin-right: var(--Small);
          border: 4px solid var(--GreyLight);
          border-radius: 50%;
          vertical-align: middle;
          cursor: pointer;

          -webkit-appearance: none;
          -moz-appearance: none;

          &:checked {
            background-color: var(--Blue);
          }

          & + label {
            margin: 0 var(--Large) 0 0;
            padding: 0;
            color: var(--Black);
            font-weight: 500;
            font-size: 14px;
            line-height: 1;
            vertical-align: middle;
            letter-spacing: 0.5px;
            cursor: pointer;
          }
        }
      }

      & + h3 {
        display: none;
      }
    }
  }
}

/* order review */
.woocommerce-checkout-review-order-table {
  display: table;
  width: 100%;
  max-width: 100%;
  margin: 0;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;

  @media screen and (min-width: $phone) {
    max-width: 60%;
  }

  @media screen and (min-width: $tablet) {
    max-width: 48%;
  }

  tfoot {
    border-top: 2px solid var(--GreyLight);
  }

  tr {
    display: table-row;
    width: 100%;

    ul {
      margin: 0;
      padding: 0;

      li {
        padding: var(--xSmall) 0;

        &::before {
          display: none;
        }
      }
    }

    th.product-name {
      width: 75%;
    }

    th,
    td.product-name {
      padding: var(--Small) var(--Medium);
      text-align: left;

      dl {
        font-size: 14px;
        font-weight: normal;
        margin: 0 0 0 8px;

        dt {
          float: left;
          clear: both;
          margin: 0 6px 0 0;
        }

        p {
          margin: 0;
        }

        dd {
          margin: 8px 0;
        }
      }
    }

    th.product-total,
    td {
      padding: var(--Small) var(--Medium);
      text-align: right;
    }
  }
}

/* credit card */
.woocommerce-checkout-payment {
  margin-top: var(--xLarge);

  ul {
    margin: 0;
    padding: 0;

    li {
      padding: 0;

      &::before {
        display: none;
      }

      > label {
        display: flex;

        img:first-of-type {
          margin-left: var(--Small);
        }
      }
    }
  }

  .payment_method_stripe {
    p {
      font-size: 14px;
    }
  }

  .wc-credit-card-form {
    .wc-stripe-elements-field {
      width: 100%;
      padding: 16px 24px 12px 24px;
      border: none;
      border-bottom: 4px solid var(--GreyLight);
      border-radius: 4px;
      color: var(--Black);
      font-weight: 800;
      background-color: var(--GreyLight);

      &.focused {
        border-color: var(--BlueDark);
        outline: none;
        box-shadow: none;
      }
    }
  }

  .woocommerce-SavedPaymentMethods-saveNew {
    input[type="checkbox"] {
      flex-shrink: 0;
      width: 24px !important;
      height: 24px;
      margin-right: var(--Small);
      border: 2px solid var(--GreyLight);
      border-radius: 4px;
      vertical-align: middle;
      cursor: pointer;

      -webkit-appearance: none;
      -moz-appearance: none;

      &:checked {
        border-color: var(--Green);
        background-color: var(--Green);

        & + label {
          &::before {
            opacity: 1;
          }
        }
      }
    }

    label {
      position: relative;
      margin: 0;
      padding: 0;
      color: var(--Black);
      font-weight: 500;
      font-size: 14px;
      line-height: 1;
      vertical-align: middle;
      letter-spacing: 0.5px;
      cursor: pointer;

      &::before {
        display: block;
        opacity: 0;
        position: absolute;
        top: 0;
        left: -30px;
        width: 16px;
        height: 16px;
        content: "";
        background-image: url(../images/checkmark-white.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transition: all 0.15s linear;
      }
    }
  }
}

.place-order {
  button {
    display: table;
    position: relative;
    float: none !important;
    min-width: 160px;
    margin: var(--xLarge) auto;
    border-radius: 0;
    color: var(--White);
    font-size: 12px;
    text-transform: uppercase;
    background: transparent;
    box-shadow: none;

    &::after {
      display: block;
      z-index: -1;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 160%;
      letter-spacing: 0.5px;
      content: "";
      background-image: url(../images/brushstroke.png);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: contain;
      transition: all 0.2s linear;
      transform: translate(-50%, -50%);
    }

    &:hover,
    &:focus {
      background-color: transparent;

      &::after {
        background-image: url(../images/brushstroke-hover.png);
      }
    }
  }
}

/*------------------------------*\
	ORDER CONFIRMATION
\*------------------------------*/
body.woocommerce-order-received {
  .woocommerce-order {
    margin-top: -56px;
  }

  p.payment-instructions {
    margin: 0;
  }

  ul.woocommerce-thankyou-order-details {
    margin: 0;
    padding: 0;

    @media screen and (min-width: $phone) {
      display: flex;
      flex-wrap: wrap;

      li {
        padding: var(--xSmall) var(--Medium) var(--xSmall) 0;

        &:before {
          display: none;
        }
      }
    }
  }

  .woocommerce-table--order-details {
    width: 400px;
    max-width: 100%;
    margin: 0;
    text-align: left;

    tr {
      vertical-align: top;
    }

    .wc-item-meta {
      margin: 8px 0 8px 8px;
      padding: 0;

      li {
        padding: 0;
        font-size: 14px;
        display: flex;
        align-items: center;

        p {
          margin: 0;
        }

        &::before {
          display: none;
        }
      }
    }

    tfoot {
      display: table;
      width: 100%;
      margin-top: var(--Large);
    }
  }

  .woocommerce-order-overview__purchase-order strong {
    margin-left: var(--Small);
  }

  .woocommerce-customer-details h2 {
    margin-top: 0;
  }
}

section.register-attendees {
  margin-top: var(--Medium);

  > div {
    display: inline-block;
    padding: 0 24px 32px 24px;
    background-color: var(--White);
    box-shadow: 0 7px 27px 0 rgba(0, 0, 0, 0.13);

    &::before {
      display: block;
      position: relative;
      left: -24px;
      width: calc(100% + 48px);
      height: 24px;
      margin-bottom: 32px;
      content: "";
      background-image: url("../images/pattern_blue_small.jpg");
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
    }
  }

  h3 {
    margin-top: 0;
  }

  form {
    max-width: 450px;
    margin: 0;

    label {
      width: 100%;

      span {
        display: block;
        font-weight: normal;
        font-style: italic;
      }
    }

    textarea {
      min-height: 150px;
    }

    p {
      font-size: 12px;

      span {
        display: block;
      }
    }

    button {
      margin: var(--Medium) 0 0 0;
    }
  }
}

/*------------------------------*\
	MY ACCOUNT
\*------------------------------*/
/* header */
body.login header.post-head,
body.woocommerce-account header.post-head {
  height: auto;
  min-height: 0;
  padding: var(--Large) 0 var(--xLarge) 0;

  h1 {
    display: flex;
    position: initial;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    text-align: left;
    transform: none;

    a.button {
      position: relative;
      top: -8px;
    }
  }
}

/* article */
body.woocommerce-account article {
  margin-top: 0;

  section.editor > .woocommerce {
    position: relative;

    &::after {
      display: block;
      clear: both;
      content: "";
    }
  }

  #customer_login {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 48px;

    @media screen and (min-width: $tablet) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 64px;
    }

    &::before,
    &::after {
      display: none;
    }

    > div {
      width: 100%;

      h2:first-child {
        margin-top: 0;
      }
    }
  }

  form.woocommerce-form-login button {
    margin-top: 32px;
  }

  form.woocommerce-form-register {
    > p:not(.form-row) {
      font-size: 14px;
    }

    .woocommerce-privacy-policy-text {
      font-size: 14px;
    }

    button {
      margin-top: 32px;
    }
  }
}

/* dashbaord */
nav.woocommerce-MyAccount-navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--xLarge);

  @media screen and (min-width: $tablet) {
    display: block;
  }

  li {
    margin: 8px 0;

    @media screen and (min-width: $tablet) {
      margin: 0;
    }
  }

  li.is-active a {
    background-color: var(--GreyLight);
  }

  @media screen and (min-width: $tablet) {
    li.woocommerce-MyAccount-navigation-link--customer-logout::after {
      display: block;
      width: 100%;
      max-width: 250px;
      height: 2px;
      margin: 24px 0;
      content: "";
      background-color: var(--GreyLight);
    }
  }
}

.woocommerce-MyAccount-content > .woocommerce-notices-wrapper + * {
  margin-top: 0;
}

body.woocommerce-account main article {
  table {
    width: 100%;
    margin: 0;
    border-spacing: 0;
    border-collapse: collapse;

    thead {
      background-color: var(--GreyLight);
    }

    th {
      padding: 8px 16px 8px 8px;
      text-align: left;
    }

    td {
      padding: 8px 16px 8px 8px;
      vertical-align: top;

      &.woocommerce-orders-table__cell-order-actions {
        width: 100px;
      }

      .wc-item-meta {
        padding: 0;
        margin: 4px 0;
        font-size: 14px;

        li {
          display: flex;
          align-items: center;

          &::before {
            display: none;
          }

          p {
            margin: 0;
          }
        }
      }
    }

    .button {
      min-width: 0;
      margin: 0;
      padding: 6px 16px;

      &.delete {
        float: right;
        color: var(--White);
        background-color: var(--Red);
        box-shadow: none;

        &:hover,
        &:focus {
          background-color: var(--RedDark);
        }
      }
    }

    tfoot {
      text-align: left;
    }

    & + button,
    & + .button {
      margin-top: 48px;
    }
  }

  form {
    margin: 0;
  }
}

ul.payment_methods {
  > li {
    display: flex;
    flex-wrap: wrap;
    margin: var(--Small) 0;
    padding: var(--Medium);
    border-left: 8px solid var(--White);
    border-radius: 4px;
    background-color: var(--White);
    box-shadow: 0 7px 27px 0 rgba(0, 0, 0, 0.13);

    > label {
      margin: 0;
      line-height: 1;

      &.payment_method_stripe {
        padding-top: 6px;
      }
    }

    > input[type="radio"] {
      display: none;
      width: auto;
    }

    > div:last-child > *:last-child {
      margin-bottom: 0;
    }
  }

  &::before {
    display: none;
  }

  .payment_method_woocommerce_gateway_purchase_order fieldset label {
    display: none !important;
  }

  fieldset {
    padding: 0;
    border: 0;

    &.wc-credit-card-form {
      margin: 0 36px;
    }

    p {
      margin: 0;
    }
  }

  ul.woocommerce-SavedPaymentMethods {
    li {
      margin-bottom: var(--Small);
    }
  }

  label {
    width: 100%;
    cursor: pointer;
  }

  input[type="radio"] {
    flex-shrink: 0;
    width: 24px !important;
    height: 24px;
    margin-right: var(--Small);
    border: 4px solid var(--GreyLight);
    border-radius: 50%;
    vertical-align: middle;
    cursor: pointer;

    -webkit-appearance: none;
    -moz-appearance: none;

    &:checked {
      background-color: var(--Blue);
    }
  }

  img {
    margin: -4px 4px 0 4px !important;
    padding: 0 !important;
    vertical-align: middle;

    &:first-of-type {
      margin-left: var(--Medium) !important;
    }
  }

  .woocommerce-PaymentBox {
    width: 100%;
  }
}
