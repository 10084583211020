/*-----------------------------------*\
	NAVIGATION
\*-----------------------------------*/
nav {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0;
      padding: 0;

      &::before {
        display: none;
      }

      a {
        display: inline-block;
        padding: 10px var(--Medium);
        color: var(--BlueDark);
        font-weight: 800;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        &:hover,
        &:focus {
          color: var(--Blue);
          text-decoration: none;
        }
      }
    }
  }
}

/*-----------------------------------*\
	UTILITY
\*-----------------------------------*/
.utility {
  display: none;
  padding: 0 var(--Medium);
  background-image: url(../images/pattern_yellow_wide.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (min-width: $tablet) {
    display: block;
  }

  > div {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    max-width: 1500px;
    margin: 0 auto;

    nav {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &.has-items li a.cart {
        position: relative;

        &::before {
          display: block;
          position: absolute;
          top: 12px;
          right: 4px;
          left: auto;
          width: 8px;
          height: 8px;
          margin: 0;
          border-radius: 50%;
          content: "";
          background-color: var(--RedDark);
        }
      }
    }

    ul {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      padding: 0;

      li {
        padding: 0;

        &:before {
          display: none;
        }

        a {
          color: var(--Black);
          text-transform: none;
          letter-spacing: 0;

          &:hover,
          &:focus {
            text-decoration: underline;
          }

          &.contact-menu {
            background-color: var(--BlueDark);
            color: var(--White);
          }
        }
      }
    }

    button.search {
      min-width: 0;
      margin-left: var(--Medium);
      padding: 4px;
      border-bottom: 1px solid transparent;
      border-radius: 0;
      background-color: transparent;
      box-shadow: none;

      img {
        display: block;
        width: 20px;
        height: 20px;

        &:first-child {
          display: block;
        }

        &:last-child {
          display: none;
        }
      }

      &:focus,
      &:hover {
        border-color: var(--Black);
        text-decoration: underline;
        background-color: transparent;
      }

      &.form-is-open {
        img {
          &:first-child {
            display: none;
          }

          &:last-child {
            display: block;
          }
        }
      }
    }

    form {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-width: 0;
      margin: 0;
      overflow: hidden;
      transition: all 0.35s linear;

      &.is-open {
        max-width: 600px;
      }

      input {
        padding: 11px 24px 6px 24px;
        border-color: var(--White);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background-color: var(--White);

        &:active,
        &:focus {
          border-color: var(--Blue);
        }
      }

      button {
        min-width: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-color: var(--Blue);
        box-shadow: none;

        img {
          display: block;
          width: 20px;
          height: 20px;
        }

        &:hover,
        &:active {
          background-color: var(--GreyLight);
        }
      }
    }
  }
}

/*-----------------------------------*\
	PRIMARY
\*-----------------------------------*/
.primary-navigation {
  display: none;
  z-index: 11;
  position: relative;
  padding-bottom: 24px;

  @media screen and (min-width: $tablet) {
    display: block;
  }

  img.logo {
    display: block;
    width: auto;
    height: 88px;
    margin-top: -16px;

    @media screen and (min-width: $laptop) {
      height: 110px;
      margin-top: -20px;
    }

    @media screen and (min-width: $desktop) {
      height: 136px;
      margin-top: -24px;
    }
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 98vw;
    max-width: 1500px;
    margin: 0 auto;
    margin: 0;
    align-items: center;

    @media screen and (min-width: $laptop) {
      width: 95vw;
      margin: 0 auto;
    }

    > div {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        padding: 0;

        // &::after {
        //   display: inline-block;
        //   width: 1px;
        //   height: 48px;
        //   margin-left: var(--Medium);
        //   content: '';
        //   background-color: var(--Grey);
        // }

        // li.current-page-ancestor,
        // li.current_page_item,
        // li.current-menu-item {
        //   a {
        //     border-top: 4px solid var(--Green);
        //   }
        // }

        a {
          padding: var(--Large) var(--Medium);
          border-top: 4px solid transparent;
          font-size: 14px;
          transition: all 0.2s linear;
          white-space: nowrap;

          &.cart {
            position: relative;

            span {
              position: absolute;
              background: #E8A202;
              border-radius: 50%;
              color: white;
              height: 18px;
              min-width: 18px;
              font-size: 14px;
              font-weight: 100;
              top: 26px;
              right: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          @media screen and (min-width: $desktop) {
            font-size: 16px;
          }

          &.cart:hover,
          &.cart:focus {
            border-top: 4px solid white;
          }

          // &:hover,
          // &:focus {
          //   border-top: 4px solid var(--Green);
          // }
        }
      }

      button,
      .button {
        min-width: 0;
        padding: 6px 12px;

        @media screen and (min-width: $desktop) {
          min-width: 120px;
        }
      }

      a img {
        width: 48px;
        height: 48px;

        @media screen and (min-width: $desktop) {
          width: auto;
          height: auto;
        }
      }

      img {
        vertical-align: middle;
      }
    }

    #down-to-content {
      width: 36px;
      height: 36px;
      z-index: 6;
      border-radius: 50%;
      font-size: 28px;
      text-align: center;
      text-decoration: none;
      font-family: monospace;
      font-weight: bold;
      line-height: 1.8;
      border: 2px solid #ffffff;
      color: #ffffff;
      background: var(--Blue);
      box-shadow: 0px 0 0 2px #ffffff60;
    }
  }
}

/*-----------------------------------*\
	PRIMARY - DROPDOWN
\*-----------------------------------*/
// .primary-navigation ul li a:focus,
// .primary-navigation ul li a:hover,
// .primary-navigation ul li:hover a {
//   + ul.sub-menu {
//     max-height: 600px;
//     border-top: 4px solid var(--Green);
//   }
// }

// .primary-navigation ul li ul.sub-menu:focus-within {
//   max-height: 600px;
//   border-top: 6px solid var(--Green);
// }

// .primary-navigation ul.sub-menu {
//   // display: block;
//   z-index: 999999;
//   position: absolute;
//   top: 100%;
//   left: 0;
//   min-width: 325px;
//   max-height: 0vh;
//   margin: 0;
//   overflow: hidden;
//   background-color: var(--White);
//   transition: max-height 0.5s linear;

//   &::after {
//     display: none;
//   }

//   li {
//     border-bottom: 1px solid var(--Grey);

//     a {
//       display: block;
//       padding: 10px var(--Medium);
//       border-top: none !important;
//       color: var(--Black);
//       text-transform: capitalize;

//       &:hover,
//       &:focus {
//         background-color: var(--GreyLight);
//       }
//     }
//   }
// }

.header-menu {
  > li {
    margin: 0;
    white-space: nowrap;
    &:before {
      display: none;
    }
    &:hover {
      background-color: #2f86d8;

      &.logged-out {
        background-color: #ffffff;
      }

      .menu-content {
        display: grid;
      }
      .sub-menu {
        display: block;
      }

      > a {
        color: #ffffff;
      }

      &.menu-cart {
        a {
          path {
            fill: #ffffff;
          }
        }
      }
    }

    > a {
      cursor: pointer;
      padding: 20px !important																																																																																									;
      border: none !important;
    }

    .menu-content {
      display: none;
      z-index: 999999;
      position: absolute;
      margin-top: 0px;
      padding: 0px;
      background: rgb(255, 255, 255);
      box-shadow: 8px 8px 8px 0 #00000026;
      top: 60px;
      border-top: 7px solid #2f86d8;
      border-radius: 2px;
      padding: 2rem;
      min-width: 750px;
      gap: 2rem;

      
      * {
        text-wrap: auto;
      }

      > a {
        cursor: pointer;
        padding: 0;
        text-transform: capitalize;
        border: none !important;
      }

      .top-level {
        text-transform: initial;
        padding: 0px;

        &:hover {
          text-decoration: underline;
        }
      }

      h3 {
        font-size: 20px;
        font-weight: bold;
        font-family: 'Karla', sans-serif;
        line-height: 1.3;
        color: #2f86d8;
        margin: 8px 8px 16px 8px;

        .arrow-icon {
          width: 16px;
          height: 16px;
          margin-left: 6px;
        }

        
      }
      ul {
        display: block;
        margin: auto;

        li {
          border-bottom: 1px solid #d1d1d1;
          padding: 10px 8px;
          display: flex;
          align-items: center;

          &:hover {
            a {
              color: #2f86d8;
            }
          }

          &:last-child {
            border: none;
          }
        }

        a {
          color: #111111;
          text-decoration: none;
          cursor: pointer;
          padding: 0;
          text-transform: capitalize;
          font-weight: bold;
          border: none !important;

          &.title {
            font-weight: bold;
          }
        }

        &:after {
          display: none;
        }
      }

      .button {
        margin: auto;
        padding: 8px 24px !important;
        text-transform: capitalize;
        border: none;
        min-width: auto;
      }

      .link {
        margin: auto;
        color: #ffffff;
        padding: 6px 20px;
        text-transform: capitalize;
        border: none;

        &:hover {
          text-decoration: underline;
          text-underline-offset: 4px;
        }
      }
    }

    &.menu-three-cols {
      > div {
        grid-template-columns: 1.1fr 1fr 1fr;
      }

      .menu-content {
        width: 1060px;

        .menu-left {
          margin: -32px 0 -32px -32px;
          padding: 32px;
        }
      }
    }

    &.menu-program {
      .menu-content {
        left: 0;
      }

      > div {
        grid-template-columns: 1fr 1.2fr;
      }
      .menu-left {
        margin: -32px 0 -32px -32px;
        background-color: #2f86d8;
        padding: 32px;

        h1 {
          color: #ffffff;
          font-family: system-ui;
          font-weight: 600;
          margin: auto;
          position: relative;
          font-size: 48px;
          letter-spacing: -9px;

          span {
            font-weight: 500;
            font-size: 44px;
            position: absolute;
            top: -26px;
            left: 50px;

            &:before {
              content: " ";
              width: 5px;
              height: 5px;
              position: absolute;
              background-color: #2f86d8;
              z-index: 20;
              top: 26px;
              left: 13px;
            }
          }
        }

        h3 {
          font-size: 20px;
          font-weight: bold;
          font-family: 'Karla', sans-serif;
          color: #ffffff;
          text-wrap: auto;
          line-height: 1.5;
          margin: 24px 0 18px;
        }
      }
    }

    &.menu-dev {
      .menu-content {
        left: -300px;

        .menu-left {
          background-color: #f3f3f3;

          .d-flex {
            display: flex;
            align-items: center;
            margin: 2px auto;
            padding: 0;
            border: none;

            img {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              margin-right: 12px;
            }

            p {
              font-weight: bold;
              font-size: 18px;
              color: #000000;
              margin: auto;
            }
            h4 {
              font-weight: bold;
              font-size: 20px;
              color: var(--BlueDark);
              margin: auto auto 16px auto;
            }

            &:hover {
              h4 {
                color: #000000;
              }
            }
          }
        }
      }
    }

    &.menu-resource {
      
      .menu-content {
        right: -230px;

        .menu-left {
          background-color: #6dac29;

          h2 {
            color: #ffffff;
            font-family: 'Karla', sans-serif;
            font-weight: 600;
            margin: auto;
            position: relative;
            font-size: 24px;
          }
          p {
            color: #ffffff;
            font-weight: 600;
            margin: 2px auto 28px;
          }
          a {
            padding: 0;
            margin: auto -32px -32px;
            max-width: calc(100% + 64px);
            height: 280px;
            width: calc(100% + 64px);
            border: none;
          }
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }

    &.menu-shop {
      .menu-content {
        right: -340px;

        .menu-left {
          text-align: center;
          background-color: #895a99;

          img {
            width: 150px;
          }
          h2 {
            color: #ffffff;
            font-family: 'Karla', sans-serif;
            font-weight: 600;
            margin: auto;
            position: relative;
            font-size: 24px;
            margin-top: 24px;
          }
          p {
            color: #ffffff;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.2;
          }
          a {
            margin-bottom: 24px;
          }
        }

        .menu-right {
          text-align: center;
          a {
            padding: 0;
            border: none;
          }
          .gift-card-img {
            width: 240px;
            height: auto;
          }
        }
      }
    }

    &.menu-services {
      .menu-content {
        right: -196px;
        grid-template-columns: 1fr 1fr;
        padding: 0;
        gap: 0;
        min-width: 690px;

        > div {
          text-align: center;
          padding: var(--Medium) var(--Medium) var(--Large);

          img {
            width: 220px;
          }
          h2 {
            color: #ffffff;
            font-weight: bold;
            font-family: 'Karla', sans-serif;
            margin: var(--Small) auto var(--Medium);
            font-size: 1.5rem;
          }
          .button {
            font-size: 0.875rem;
            color: #ffffff;
            background-color: transparent;
            border-radius: 2rem;
            border: 2px solid #ffffff;
            padding: var(--Small) 1.3rem !important;
            box-shadow: initial;

            &:hover {
              background-color: #ffffff;
              color: var(--Black);
            }
          }

          &.menu-left {
            background: linear-gradient(to right, #006bcc, #d91f5c);
          }
          &.menu-right {
            background: linear-gradient(to right, #006bcc, #8a4fba);
          }
        }

        .menu-center {
          text-align: center;
          background-color: #895a99;

          img {
            width: 150px;
          }
          h2 {
            color: #ffffff;
            font-family: 'Karla', sans-serif;
            font-weight: 600;
            margin: auto;
            position: relative;
            font-size: 24px;
            margin-top: 24px;
          }
          p {
            color: #ffffff;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.2;
          }
          a {
            margin-bottom: 24px;
          }
        }
      }
    }

    &.menu-about {
      .menu-content {
        right: -220px;

        .menu-left {
          background-color: #003370;

          h2 {
            color: #ffffff;
            font-family: 'Karla', sans-serif;
            font-weight: 600;
            margin: auto;
            position: relative;
            font-size: 26px;
            margin-top: 24px;
          }
          p {
            color: #ffffff;
          }
          a {
            margin: 16px auto;
          }
        }
      }
    }

    &.menu-cart {
      > a {
        padding: 12px !important;
      }
    }

    &.menu-user {
      margin-left: 64px;
      display: flex;
      align-items: center;

      &:before {
        content: '';
        display: block;
        position: relative;
        top: 0;
        left: -32px;
        width: 1px;
        height: 50px;
        border-radius: 0;
        background-color: #949494;
        margin: 0;
      }

      > div {
        grid-template-columns: 1fr 1fr;

        > a {
          border: none;
          width: auto;
          margin: auto;
        }
      }

      .menu-content {
        right: -40px;

        .arrow-icon {
          width: 16px;
          height: 16px;
          margin-left: 6px;
        }

        .menu-right {
          ul {
            li {
              &:before {
                content: '';
                display: block;
                width: 16px;
                height: 15px;
                background-color: inherit;
                background-size: 100%;
                background-repeat: no-repeat;
                border-radius: 0;
                position: relative;
                top: initial;
                margin-right: 8px;
                
              }
  
              &.my-favorites:before {
                background-image: url(https://s3.amazonaws.com/imse-production-010821/images/icon-favorite.svg);
              }
  
              &.my-saved-materials:before {
                background-image: url(https://s3.amazonaws.com/imse-production-010821/images/icon-in-library.svg);
              }
  
              &.my-training-materials:before {
                background-image: url(https://s3.amazonaws.com/imse-production-010821/images/icon-traning-material.svg);
              }
            }
          }

          .button {
            margin-top: 64px;
            float: right;
          }
        }

        > div > a {
          padding: 0;
          border: none;
          text-transform: initial;

          h3 {
            padding-bottom: 3px;
            border-bottom: 1px solid #ffffff;
          }

          &:hover {
            h3 {
              border-color: #2f86d8;
            }
          }
        }
      }
      svg {
        background-color: #ffffff;
        border-radius: 50%;
      }

      > a {
        padding: 10px 8px 12px 8px !important;
        height: 66px;
      }
    }
  }
}

/*-----------------------------------*\
	STICKY
\*-----------------------------------*/
.sticky-navigation {
  display: none;
  z-index: 9999999;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: hsla(0, 0%, 100%, 0.85);
  transition: all 0.2s linear;
  transform: translateY(-100%);

  @media screen and (min-width: $tablet) {
    display: block;
  }

  &.is-stuck {
    transform: translateY(0);
  }

  img.logo {
    display: block;
    width: auto;
    height: 72px;
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95vw;
    max-width: 1500px;
    margin: 0 auto;

    > div {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: var(--Medium);

        li.current-page-ancestor,
        li.current_page_item,
        li.current-menu-item {
          a {
            border-top: 4px solid var(--Green);
          }
        }

        a {
          padding: var(--Large) var(--Medium);
          border-top: 4px solid transparent;
          transition: all 0.2s linear;

          &:hover,
          &:focus {
            border-top: 4px solid var(--Green);
          }
        }
      }

      a.phone {
        margin-right: var(--Medium);
      }

      a.cart.has-items {
        position: relative;

        &::before {
          display: block;
          position: absolute;
          top: 15px;
          right: 15px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          content: '';
          background-color: var(--Red);
        }
      }

      img {
        vertical-align: middle;
      }
    }
  }
}

/*-----------------------------------*\
	STICKY - DROPDOWN
\*-----------------------------------*/
.sticky-navigation ul li a:focus,
.sticky-navigation ul li a:hover,
.sticky-navigation ul li:hover a {
  + ul.sub-menu {
    max-height: 600px;
    border-top: 6px solid var(--Green);
  }
}

.sticky-navigation ul li ul.sub-menu:focus-within {
  max-height: 600px;
  border-top: 6px solid var(--Green);
}

.sticky-navigation ul.sub-menu {
  display: block;
  z-index: 999999;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 325px;
  max-height: 0vh;
  margin: 0;
  overflow: hidden;
  background-color: var(--White);
  transition: max-height 0.5s linear;

  &::after {
    display: none;
  }

  li {
    border-bottom: 1px solid var(--Grey);

    a {
      display: block;
      padding: 10px var(--Medium);
      border-top: none !important;
      color: var(--Black);
      text-transform: capitalize;

      &:hover,
      &:focus {
        background-color: var(--GreyLight);
      }
    }
  }
}

/*-----------------------------------*\
	SIMPLE HEADER
\*-----------------------------------*/
.simple-header {
  z-index: 3;
  position: relative;
  padding: 24px 0;
  display: block !important;

  .header-top {
    position: absolute;
    width: 660px;
    top: -160px;
    right: -140px;

    @media screen and (max-width: $laptop) {
      display: none;
    }
  }

  img.logo {
    display: block;
    width: auto;
    height: 88px;
    margin-top: 0;

    @media screen and (min-width: $laptop) {
      margin-top: 0;
    }

    @media screen and (min-width: $desktop) {
      margin-top: 0;
    }
  }
}
