.products {
  .primary-navigation {
    height: 100px;
  }

  .secondary-nav {
    z-index: 2;
    position: relative;

    .woocommerce-products-header {
      box-shadow: none;
      padding: 0 !important;
      width: 100%;
      max-width: 100%;

      @media screen and (min-width: $tablet) {
        box-shadow: 0 17px 15px -9px #00000045;
      }

      & > div {
        margin: 0 auto;
        width: 95vw;
        max-width: 1500px;
        padding: 0;
      }
    }
  }

  .prodcts-landing {
    grid-column: 1 / span 4;
    max-width: 1175px;
    width: 100%;
    margin: 0 auto;
    padding-top: 48px;
  }

  .woocommerce-products-header {
    .flex-wrapper {
      display: flex;
      flex-direction: row;
      position: initial;
      justify-content: space-between;
      width: 100%;
      margin: 0 auto;

      @media screen and (min-width: $tablet) {
        flex-direction: row;
        align-items: center;
      }

      .left-col,
      .right-col {
        @media screen and (min-width: $tablet) {
          flex: 1;
        }
      }

      .left-col {
        margin-bottom: var(--xLarge);

        @media screen and (min-width: $tablet) {
          margin-bottom: 0;
        }
      }

      .right-col {
        width: 100%;

        form {
          display: flex;

          input[type=text] {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            background-color: var(--White);
            border-bottom-color: var(--GreyLight);
          }

          input[type=submit] {
            width: 50%;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }

      &.top-nav-items {
        justify-content: space-around;
        box-shadow: none;
        flex-direction: column-reverse;

        @media screen and (min-width: $tablet) {
          box-shadow: 0 4px 13px 0 #00000045;
          flex-direction: row;
        }

        .scrollable {
          // overflow-x: auto;
          max-width: 900px;
          display: none;

          @media screen and (min-width: $tablet) {
            display: flex;
          }
        }

        &.no-shadow {
          box-shadow: none !important;
          justify-content: flex-end;
          max-width: 1500px;
          padding: 0;

          div.search {
            .autocomplete {
              margin-left: 0;

              @media screen and (min-width: $tablet) {
                margin-left: 42px;
              }

              input {
                padding: 8px 16px;
              }
            }
            button.submit {
              padding: 7px 12px !important;
            }
          }
        }

        .nav-item {
          font-weight: bold;
          cursor: pointer;
        }

        #menu-primary-navigation {
          display: flex;
          margin: auto;
          padding: 0;

          li {
            &:before {
              display: none;
            }
            &.menu-item-has-children {
              padding: 20px 16px;
              margin: 0 10px;
              white-space: nowrap;
              border-bottom: 3px solid #ffffff;

              &:hover {
                border-color: #7ac042;
              }
            }

            &.active {
              border-color: #7ac042;
            }

            a {
              cursor: pointer;
            }
          }

          .menu-item-has-children {
            a {
              color: #4f4f4f;
              text-decoration: none;
              cursor: pointer;
              padding: 0;
              text-transform: capitalize;
              font-weight: normal;

              &.title {
                font-weight: bold;
              }
            }

            .sub-menu {
              display: grid;
              grid-column: 2 / auto;
              grid-template-columns: auto auto;
              z-index: 999999;
              position: absolute;
              margin-top: 0px;
              padding: 0px;
              background: rgb(255, 255, 255);
              box-shadow: 0 4px 13px 0 #00000045;
              top: 64px;
              left: -35px;
              border-top: 5px solid #7ac042;
              border-radius: 2px;
              padding: 15px 10px;

              &:before {
                content: ' ';
                width: 58px;
                height: 17px;
                background-image: url('https://s3.amazonaws.com/imse-qa-new/images/arrow_up.svg');
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                top: -19px;
                left: 45px;
              }

              .menu-cols {
                padding: 0 20px;
                min-width: 280px;

                p {
                  margin: 5px auto;
                  padding: 5px 8px 5px 8px;
                  border-radius: 4px;
                  font-weight: bold;
                  background-color: var(--Blue);
                  color: #ffffff !important;
                }
                ul {
                  padding: 0;
                  margin: 0;


                  li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 3px 0;
                    cursor: pointer;

                    span.close {
                      margin-left: 15px;
                    }

                    &.active {
                      a {
                        color: #7ac042;
                      }
                    }
                  }
                }
              }

            }
          }
        }
      }
    }
  }
  .product-mobile-menu {
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin-top: 15px;
    width: 100%;

    & > div {
      margin: 0 5px;
    }

    nav {
      position: absolute;
      width: 100%;
      left: 0;
      top: 50px;
    }

    @media screen and (min-width: $tablet) {
      display: none;
    }
  }
  .product-mobile-title {
    display: block;
    text-align: center;
    @media screen and (min-width: $tablet) {
      display: none;
    }
  }
  .product-content-item {
    grid-column: 1 / span 4;
    position: relative;
    margin: 0 -20px;

    @media screen and (min-width: $phone) {
      margin: 0;
    }

    > div {
      margin: 0 auto 10px;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .button {
      border-radius: 2px;
      padding: 8px 22px;
      font-size: 20px;
      margin: 0;
      width: fit-content;

      &.blue {
        background-color: #013879;
        color: #ffffff;
      }
    }

    .product-card {
      height: 100%;
      padding-left: 65px;
      padding-right: 20px;
      display: flex;
      width: 100%;
      align-items: start;
      flex-direction: column;
      justify-content: center;

      @media screen and (min-width: $tablet) {
        width: 50%;
      }
      
      p {
        font-size: 28px;
        font-weight: bold;
        margin-top: 0;
        line-height: 1.3;
        color: #ffffff;
        font-family: 'lexia', serif;

        @media screen and (min-width: $tablet) {
          font-size: 42px;
        }

        &.blue {
          color: var(--BlueDark);
        }
      }
    }

    .banner-section {
      background-color: #221e20;
      margin-bottom: 0;

      .slick-track
      {
        display: flex !important;
        height: 100%;
      }

      .slick-slide
      {
        height: inherit !important;
      }

      .slick-arrow {
        min-width: 0 !important;
        margin: 0 !important;
        box-shadow: none;
        width: 40px;
        height: 100%;
        z-index: 99;

        &::before {
          font-size: 50px;
          color: #2f86d8;
          box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 9px;
          width: 40px;
          height: 40px;
          background-color: #ffffff;
        }

        &.slick-next {
          right: 8px;
        }

        &.slick-prev {
          left: 8px;
        }
      }

      .slick-list {
        height: 100%;
      }

      ul {
        padding: 0;
        width: 100%;
        height: 288px;
        margin: auto;

        @media screen and (min-width: $tablet) {
          height: 358px;
        }

        li {
          background: #ffffff;
          margin: 0;
          padding: 0;
          width: 1180px;
          position: relative;
          
          &:first-child .woocommerce-LoopProduct-link {
            background-image: url('https://s3.amazonaws.com/imse-production-010821/media/product-images/products-new-printable-1.jpg');
            background-size: contain;
          }
          &:last-child .woocommerce-LoopProduct-link {
            background-image: url('https://s3.amazonaws.com/imse-production-010821/media/product-images/products-new-printable-1.jpg');
            background-size: contain;
          }
          .product-card {
            h5 {
              color: #ffffff;
              font-size: 28px;
              margin: auto;
            }
            h1 {
              font-weight: bold;
              color: #ffffff;
              margin: 20px auto;
              font-size: 72px;
            }
          }
        }
      }
    }

    .product-categories {
      display: grid;
      grid-template-columns: auto;
      gap: 10px;
      list-style: none;
      padding: 0;
      margin: 50px 15px;

      @media screen and (min-width: $phone) {
        grid-template-columns: auto auto;
      }

      @media screen and (min-width: $laptop) {
        grid-template-columns: auto auto auto;
      }

      li {
        max-width: 378px;
        width: 100%;
        height: 340px;
        padding: 0;
        text-align: center;        
        display: flex;
        justify-content: center;
        border-radius: 20px;
        overflow: hidden;
        margin: 0 auto;

        @media screen and (min-width: $phone) {
          height: 378px;
        }

        a {
          text-align: center;
          font-weight: bold;
          font-size: 20px;
          line-height: 1.2;
          text-decoration: none;
          position: absolute;
          bottom: 16px;
          box-shadow: none;
          outline: none;
        }
        &:before {
          background-size: cover;
          background-position: bottom;
          width: 100%;
          height: 100%;
          margin: auto;
          background-color: inherit;
          background-repeat: no-repeat;
          z-index: -1;
          border-radius: 0;
          top: 0;
        }
        &:nth-child(1):before {
          background-image: url('https://imse-qa-new.s3.amazonaws.com/images/products/Student+Practice+Books.jpg')
        }
        &:nth-child(2):before {
          background-image: url('https://imse-qa-new.s3.amazonaws.com/images/products/Decodable+Readers.jpg')
        }
        &:nth-child(3):before {
          background-image: url('https://imse-qa-new.s3.amazonaws.com/images/products/Classroom+Kit.jpg')
        }
        &:nth-child(4):before {
          background-image: url('https://imse-qa-new.s3.amazonaws.com/images/products/Classroom+Posters.jpg')
        }
        &:nth-child(5):before {
          background-image: url('https://imse-qa-new.s3.amazonaws.com/images/products/Printable+Resources.jpg')
        }
        &:nth-child(6):before {
          background-image: url('https://imse-qa-new.s3.amazonaws.com/images/products/Teacher+Guides.jpg')
        }
        &:hover {
          a {
            bottom: 25px;
          }
        }
      }
    }

    .product-new-printable-1 {
      background-image: url('https://imse-qa-new.s3.amazonaws.com/images/products-new-printable-1.png');
      height: 358px;
    }

    .product-new-printable-2 {
      background-image: url('https://imse-qa-new.s3.amazonaws.com/images/products-new-printable-2.png');
      height: 358px;

      .product-card {
        left: 0;
        top: auto;
        bottom: 0;
        width: calc(100% - 24px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 40px 30px 60px;
        background-color: #1a468be6;
        margin: 12px 12px 20px;
        
        p {
          margin: 0;
        }
      }
    }

    .product-async-courses {
      background-image: url('https://imse-qa-new.s3.amazonaws.com/images/products-asynchronous-course.png');
      height: 280px;
      background-size: 175% 100%;

      @media screen and (min-width: $tablet) {
        height: 358px;
        background-size: cover;
      }
    }

    .product-help-parents {
      background-image: url('https://imse-qa-new.s3.amazonaws.com/images/products-for-parents.png');
      height: 340px;

      @media screen and (min-width: $tablet) {
        height: 462px;
      }

      @media screen and (max-width: $tablet) {
        background-size: cover;
        background-position: bottom;
      }

      .product-card {
        align-items: center;
        text-align: center;
        padding: 50px;

        @media screen and (min-width: $tablet) {
          align-items: start;
        }

        p {
          font-size: 38px;

          @media screen and (min-width: $tablet) {
            font-size: 42px;
          }
        }
      }
    }

    .product-new-products {
      background-image: url('https://imse-qa-new.s3.amazonaws.com/images/products-new.png');
      height: 462px;

      .product-card {
        left: 0;
        top: auto;
        bottom: 0;
        width: calc(100% - 24px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 40px 30px 60px;
        background-color: #e9f3f8;
        margin: 12px 12px 20px;

        p {
          margin: 0;
        }
      }
    }

    .product-get-organized-1 {
      background-image: url('https://imse-qa-new.s3.amazonaws.com/images/products-get-started-1.png');
      height: 462px;
      display: flex;
      .product-card {
        position: relative;
        margin: auto;
        display: flex;
        flex-direction: column;
        left: 0;
        top: -20px;
        a {
          margin: auto
        }
      }
    }

    .product-get-organized-2 {
      background-image: url('https://imse-qa-new.s3.amazonaws.com/images/products-get-started-2.png');
      height: 462px;

      display: flex;
      .product-card {
        position: relative;
        margin: auto;
        display: flex;
        flex-direction: column;
        left: 0;
        top: -34px;
        a {
          margin: auto
        }
      }
    }

    .product-classroom-materials {
      background-image: url('https://imse-qa-new.s3.amazonaws.com/images/products-classroom-materials.png');
      height: 290px;

      @media screen and (min-width: $tablet) {
        height: 462px;
      }

      @media screen and (max-width: $tablet) {
        background-size: cover;
        background-position: bottom;
      }
      
      .product-card {
        align-items: center;
        text-align: center;
        padding: 50px;

        @media screen and (min-width: $tablet) {
          align-items: start;
        }

        p {
          font-size: 38px;

          @media screen and (min-width: $tablet) {
            font-size: 42px;
          }
        }
      }
    }

    .product-top-sellers {
      background-image: url('https://imse-qa-new.s3.amazonaws.com/images/products-top-sellers.png');
      height: 358px;
      .product-card {
        top: 110px;
      }
    }

    .product-digital {
      display: flex;
      background-size: 100%;
      
      &.pd-1 {
        background-image: url('https://imse-qa-new.s3.amazonaws.com/images/products-digital-1.png');
      }
      &.pd-2 {
        background-image: url('https://imse-qa-new.s3.amazonaws.com/images/products-digital-2.png');
        margin-bottom: 0;
      }
      &.pd-3 {
        background-image: url('https://imse-qa-new.s3.amazonaws.com/images/products-digital-3.png');
      }

      .product-card {
        position: relative;
        left: 0;
        top: auto;
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 52px 20px 72px;

        &:before {
          content: ' ';
          width: 237px;
          height: 88px;
          background-image: url('https://s3.amazonaws.com/imse-qa-new/images/products-logo.png');
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
        }

        p {
          margin: auto;
        }

        span {
          color: #ffffff;
          font-size: 22px;
        }

        > div {
          margin-left: 280px;
        }
      }
    }

    .product-back-to-school {
      text-align: center;
      margin-bottom: 50px;
      
      .content-title {
        font-weight: bold;
        text-align: center;
        font-family: "Karla", sans-serif;
        color: var(--BlueDark);
      }
      .slick-next {
        right: -48px;
      }
      .slick-prev {
        left: -48px;
      }
      .slick-arrow::before {
        font-size: 50px;
        color: #2f86d8;
        box-shadow: #0000004d;
        border-radius: 50%;
        display: flex;
        align-items: center;
        padding-top: 9px;
        width: 40px;
        height: 40px;
        background-color: #ffffff;
      }
      ul {
        width: 1058px;
        margin: 0 25px;

        @media screen and (min-width: $tablet) {
          margin: auto;
        }

        .slick-list {
          overflow: visible;
          @media screen and (min-width: $tablet) {
            overflow: hidden;
          }
        }

        li {
          img {
            border: none;
          }

          h2 {
            margin: 20px auto 5px;
            color: #a3a3a3;
            font-weight: 500;
            font-size: 20px;
          }

          p {
            color: #6e6e6e;
            margin: 0;
            font-size: 28px;
            font-weight: bold;
          }

          .button {
            border-radius: 2px;
            padding: 8px 16px;
            font-size: 18px;
            margin: 10px auto 20px;
            border: 1px solid var(--BlueDark);
          }
        }
      }
    }
  }
  
  .pagination {
    display: none; 
    grid-column: 2 / span 3; 
    justify-content: center; 
    align-items: center; 
    flex-wrap: wrap; 
    margin-bottom: 40px;

    @media screen and (min-width: $tablet) {
      justify-content: space-between; 
    }
  }

  .featured-products {
    grid-column: 1 / span 4;
    padding-top: 20px;

    &.carousel {
      padding: 0 30px;
    }

    &.list {
      // background-color: #efefef;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;

      .title-bar {
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {
          margin: 0;
          font-family: "Karla", sans-serif;
          font-weight: 700;
          text-align: center;
        }

        select {
          width: 150px;
          display: none;
        }
      }

      .category-bar {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 15px;

        ul {
          display: flex;
          flex-wrap: wrap;
          margin: 0 20px 0 0;
          padding: 0;

          li {
            background-color: var(--GreyDark);
            color: #ffffff;
            border-radius: 6px;
            padding: 0 15px;
            line-height: 2;
            margin-right: 15px;
            margin-top: 10px;

            span {
              margin-left: 8px;
              font-weight: bold;
              cursor: pointer;
            }
            &:before {
              display: none;
            }
          }
        }

        select {
          width: auto;
          min-width: 160px;
          border-bottom: none;
          margin-top: -16px;
        }
      }
    }

    @media screen and (min-width: $tablet) {
      grid-column: 2 / span 3;

      &.list {
        padding-top: 48px;
        padding-left: 0;
        padding-right: 0;
        .title-bar {
          justify-content: space-between;
          h3 {
            text-align: left;
          }

          #sortPro {
            display: block;
          }
        }
      }
    }

    .page-title-header {
      text-align: center;
    }

    .digital-products {
      .slick-arrow {
        min-width: 0 !important;
        margin: 0 !important;
        box-shadow: none;
        width: 40px;
        height: 100%;
        z-index: 99;

        &::before {
          font-size: 50px;
          color: #7ac042;
          box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 9px;
          width: 40px;
          height: 40px;
          background-color: #ffffff;
        }

        &.slick-next {
          right: -56px;
        }

        &.slick-prev {
          left: -56px;
        }
      }

      li {
        margin: 10px;
        width: 200px;

        p {
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
  }
}
