/*----------------------------------------------------------------*\
	PAGINATION
\*----------------------------------------------------------------*/
nav.pagination,
.woocommerce-pagination {
	margin: var(--xLarge) auto;
	text-align: center;

	.page-numbers:not(span) {
		&::before,
		&::after {
			content: ' ';
    	display: table;
		}

		&::after {
			clear: both;
		}

		> li {
			display: inline-block;
		}
	}

	.page-numbers:not(ul) {
		display: inline-block;
		margin: 0 var(--Medium) 0 0;
		padding: 10px var(--Medium);
		border: none;
		border-radius: 4px;
		color: var(--BlueDark);
		font-weight: 800;
		font-size: 16px;
		font-family: 'Karla', sans-serif;
		text-align: center;
		text-decoration: none;
		cursor: pointer;
		background-color: var(--White);
		box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.27);
		transition: all 0.2s linear;

		&:hover,
		&:focus {
			color: var(--White);
			background-color: var(--BlueDark);
		}

		&.current {
			background-color: var(--Blue);
			color: var(--White);

			&:hover,
			&:focus {
				color: var(--BlueDark);
				background-color: var(--White);
			}
		}
	}
}

.woocommerce-pagination {
	grid-column: 2/span 3;
}