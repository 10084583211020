/*-----------------------------------*\
	POST HEADER
\*-----------------------------------*/
header.post-head {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 45vh;
  min-height: 200px;

  h1 {
    z-index: 10;
    position: absolute;
    bottom: var(--xLarge);
    left: 50%;
    width: 90vw;
    margin: 0;
    padding: var(--Medium) var(--Large);
    text-align: center;
    background-color: var(--White);
    transform: translateX(-50%);

    @media (min-width: $tablet) {
      left: 0;
      width: auto;
      min-width: 45vw;
      // max-width: 85vw;
      padding: 24px 96px;
      text-align: right;
      transform: none;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &.columns-1 img {
    width: 100%;
    margin-right: 0;
  }

  &.columns-2 img:not(:first-of-type),
  &.columns-3 img:not(:first-of-type) {
    display: none;
  }

  &.global-search {
    height: 130px;
    background-image: url(https://s3.amazonaws.com/imse-qa-new/images/testimonials-bg-blue.png);

    form {
      display: flex;
      align-items: center;
      background-color: #ffffff;
      padding: 4px 4px 4px 20px;
      border-radius: 32px;
      width: 90%;
      max-width: initial;

      input {
        background-color: #ffffff;
        border: none;
        border-radius: 32px;
        padding: 12px;
        font-size: 20px;
        font-weight: 500;
      }

      .icon-record {
        width: 22px;
        margin-right: 16px;
        cursor: pointer;
      }

      button {
        min-width: auto;
        padding: 13px;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        margin: auto;
        background-color: #00bceb;

        img {
          filter: invert(100);
        }
      }
    }
  }


  @media (min-width: $tablet) {

    &.columns-2 img:not(:first-of-type),
    &.columns-3 img:not(:first-of-type) {
      display: block;
    }

    &.columns-2 img {
      width: calc((100% - 2em) / 2);
    }

    &.columns-3 img {
      width: 33%;
      width: calc((100% - 3em) / 3);
    }

    &.columns-2 img:nth-of-type(2n),
    &.columns-3 img:nth-of-type(3n) {
      margin-right: 0;
    }
  }
}

/*-----------------------------------*\
	ACTION HEADER
\*-----------------------------------*/
header.action-header {
  position: relative;
  margin-bottom: 40vh;
  padding-top: var(--Medium);

  h1 {
    z-index: 10;
    position: relative;
    max-width: 1200px;
    margin: 30px auto;
    text-align: center;
  }

  p.subheader {
    z-index: 10;
    position: relative;
    max-width: 650px;
    margin: 0 auto;
    padding-bottom: var(--xLarge);
    font-size: 24px;
    line-height: 1.2;
    text-align: center;

    @media (min-width: $tablet) {
      font-size: 32px;
    }
  }

  a.button {
    display: table;
    z-index: 10;
    position: relative;
    position: relative;
    margin: 0 auto;
  }

  svg {
    z-index: 5;
    position: absolute;
    top: calc(100% - 20px);
    left: 50%;
    width: 100%;
    min-width: 1200px;
    margin-bottom: 0;
    transform: translateX(-50%) translateY(-42%);
  }

  img {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 50vh;
    margin-top: -10vh;
    object-fit: cover;
  }

  +main {
    position: relative;
    top: -96px;
    margin-bottom: -96px;

    >article>section:first-child:not(.card-grid) {
      margin-top: 300px;
    }
  }
}

.header-secondary {
  >div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    align-items: center;
    position: relative;
    padding: var(--xLarge);
    color: white;
  }

  .img-content {
    width: 100%;
    height: auto;
  }

  .title {
    line-height: 1;
    font-size: 2.5rem;
    margin: 0;
    color: #ffffff;
  }

  .description {
    font-size: 1.5rem;
    line-height: 1;
  }

  .img-content,
  .text-content {
    flex: 1;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;

    .button {
      &.primary {
        color: #ffffff;
        text-decoration: none;
        padding: 0.6rem 2rem;
        border: 3px solid #ffffff;
        border-radius: 2rem;
        font-weight: bold;
        font-size: 14px;
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: transparent;

        &:hover {
          background-color: #ffffff;
          color: var(--BlueDark);
        }
      }

      &.secondary {
        border: none;
        background-color: transparent;
        box-shadow: none;
        color: white;
      }
    }
  }
}