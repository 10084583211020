/*-----------------------------------*\
	COVER
\*-----------------------------------*/
.cover {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 80%;
	max-width: 960px;
	margin: 88px auto;
	padding: var(--xLarge) var(--Large) 80px var(--Large);
	text-align: center;
	background-color: var(--GreyLight);
	z-index: inherit;

	&::before {
		display: block;
		z-index: -1;
		position: absolute;
		bottom: -150px;
		left: -200px;
		width: 500px;
		height: 500px;
		content: "";
		background-image: url(../images/sand.png);
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: contain;
		transform: rotate(230deg);
	}

	&::after {
		display: block;
		z-index: -1;
		position: absolute;
		right: -200px;
		bottom: -75px;
		width: 500px;
		height: 500px;
		content: "";
		background-image: url(../images/sand.png);
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: contain;
		transform: rotate(42deg);
	}

	> div {
		z-index: 2;
		position: relative;
		max-width: 600px;

		> *:first-child {
			margin-top: 0;
		}

		> *:last-child {
			margin-bottom: 0;
		}
	}

	> a.button {
		position: absolute;
		top: 100%;
		left: 50%;
		font-family: Karla;
		font-weight: bold;
		font-size: 12px;
		letter-spacing: 0.1em;
		line-height: inherit;
		transform: translateX(-50%) translateY(-50%);
	}

	&.cert-cards {
		padding: var(--xLarge) var(--Large);
		box-shadow: 0 7px 27px 0 #00000021;
		background: #ffffff;

		&.gray-background {
			background-color: var(--GreyLight);
		}

		&.purple-item {
			&::before {
				background-image: url(../images/purple-sand.png);
			    transform: rotate(180deg);
			}

			&::after {
				background-image: url(../images/purple-sand.png);
				transform: rotate(0);
			}
		}

		&.yellow-item {
			&::before {
				background-image: url(../images/yellow-sand.png);
			    transform: rotate(180deg);
			}

			&::after {
				background-image: url(../images/yellow-sand.png);
				transform: rotate(0);
			}
		}

		&.blue-item {
			&::before {
				background-image: url(../images/blue-sand.png);
			    transform: rotate(180deg);
			}

			&::after {
				background-image: url(../images/blue-sand.png);
				transform: rotate(0);
			}
		}
		
		div {
			max-width: inherit;
		}

		a.jump {
			letter-spacing: 0.1em;
			color: #ffffff;
			text-decoration: none;
			position: relative;
			padding: 14px 20px;


			&:after {
				z-index: -1;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				letter-spacing: 0.5px;
				content: "";
				background-image: url(../images/brushstroke.png);
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100% 100%;
			}
		}
	}
}

main>article>section.cover:not(.is-full-width):last-child {
	margin-bottom: 200px;
}

/*-----------------------------------*\
	COVER + BACKGROUND
\*-----------------------------------*/
.card-grid+.cover.is-full-width {
	min-height: 60vh;
	margin-top: -20vh;
	padding: calc(20vh - 80px) 0 0 0;
	z-index: 0;

	@media screen and (min-width: $tablet) {
		min-height: 80vh;
	}
}

.cover.is-full-width {
	z-index: 0;
	position: relative;
	width: 100%;
	max-width: 100%;
	min-height: 60vh;
	padding: 0 0 0 0;
	text-align: left;
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: cover;

	&:before,
	&:after {
		display: none;
	}

	> div {
		width: 90%;
		max-width: 1200px;
		margin: 0 auto;

		> div {
			max-width: 400px;
			margin: 0 auto;
			text-align: center;

			@media screen and (min-width: $tablet) {
				margin: 0;
				text-align: left;
			}

			> *:first-child {
				margin-top: 0;
			}
		}
	}
}

main>article>section.cover.is-full-width:last-child {
	margin-bottom: -64px;
}

.parent-style{
	max-width: 1200px;
	justify-content: space-between;
	&:before,
	&:after {
		display: none;
	}

	& > div {
		text-align: left;
	}

	> a.button {
		position: inherit;
		top: inherit;
		left:inherit;
		transform: translateX(-50%) translateY(-50%);
	}
}

.no-background {
	background-color: transparent;
	background-image: none;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

	&:before,
	&:after {
		display: none;
	}
}

.background {
	max-width: 1200px;
	&:before,
	&:after {
		bottom: 0;
	}

	& > div {
		text-align: left;
	}

	> a.button {
		position: absolute;
		top: 80%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
	}
}

/* 
    bottom: 50px;
    left: -312.2px;
*/

.no-gray-background{
	background-color: transparent;
	background-image: none;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

	&:before,
	&:after {
		bottom: 50px;
	}

	& h3 {
		font-size: 32px;
	}

	& p {
		font-size: 22px;
		justify-content: center;
	}
}