/*-----------------------------------*\
	LAZYLOAD IMAGES
\*-----------------------------------*/
.blur-up {
	transition: all 0.35s linear;
	filter: blur(8px);
}

.blur-up.lazyloaded {
	filter: blur(0);

	-webkit-filter: blur(0);
}

/*-----------------------------------*\
	SAND ELEMENTS ON SINGLE IMAGE
\*-----------------------------------*/
section.gallery.columns-1 {
	position: relative;
	z-index: -1;

	&::before {
		display: block;
		z-index: -1;
		position: absolute;
		bottom: -150px;
		left: -200px;
		width: 500px;
		height: 500px;
		content: "";
		background-image: url(../images/sand.png);
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: contain;
		transform: rotate(230deg);
	}

	&::after {
		display: block;
		z-index: -1;
		position: absolute;
		top: -200px;
		right: -200px;
		width: 500px;
		height: 500px;
		content: "";
		background-image: url(../images/sand.png);
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: contain;
		transform: rotate(49deg);
	}

	&+section.card-grid.image-top-cards {

		&:before,
		&:after {
			display: none;
		}
	}
}

main>article>section.gallery.columns-1:first-child {
	margin-top: 96px;
}

/*-----------------------------------*\
	GALLERY
\*-----------------------------------*/
.gallery {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 96px auto;

	& figure {
		min-height: 10px;
		margin: 0;

		& img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: 50% 50%;
		}
	}
}

/*-----------------------------------*\
	COLUMNS
\*-----------------------------------*/
section.gallery {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 32px;
	margin: 96px auto;

	& .card {
		width: 100%;
		margin-right: 0;
	}

	&.columns-4 {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (min-width: $phone) {
		&.columns-2 {
			grid-template-columns: 1fr 1fr;
		}

		&.columns-3 {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}

	@media screen and (min-width: $tablet) {
		&.columns-4 {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
	}
}