// WYSIWYG
.editor {
  &.is-anchor {
    height: 1px;
    margin: -64px auto 0;
  }
  p {
    a {
      &.button {
        margin: 0 var(--Medium) var(--Medium) 0;
      }
    }
  }
}
/*-----------------------------------*\
	2 COLUMNS
\*-----------------------------------*/
.editor-2-column-container.bordered {
  border-top: 1px solid var(--GreyLight);
  border-bottom: 1px solid var(--GreyLight);
}

.editor-2-column {
  & > div {
    width: 100%;

    & > *:first-child {
      margin-top: 0;
    }
  }

  @media screen and (min-width: $tablet) {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    & > div {
      width: 45%;
    }

    &.bordered > div {
      width: 50%;
      
      &:first-child {
        border-right: 1px solid var(--GreyLight);
      }
    }
  }
}

/*-----------------------------------*\
	3 COLUMNS
\*-----------------------------------*/
.editor-3-column {
  & > div {
    width: 100%;

    & > *:first-child {
      margin-top: 0;
    }
  }

  @media screen and (min-width: $tablet) {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    & > div {
      width: 31%;
    }
  }
}


/*-----------------------------------*\
  Custom CSS for training-descriptions
\*-----------------------------------*/

#training-description-phonological-awareness,
#training-description-morphology,
#training-description-compog {
  @media screen and (min-width: $tablet) {
    & > div:first-child {
      width: 55%;
    }
    & > div:last-child {
      width: 38%;
    }
  }
}

#async-courses {
  @media screen and (min-width: $tablet) {
    & > div:first-child {
      width: 55%;
    }
    & > div:last-child {
      width: 35%;
    }
  }
}

#training-impact {
  .training-approaches {
    display: flex;
    flex-direction: row;
    justify-content: center;

    p {
      margin: 0 24px;
      text-align: center;
      &::before {
        display: block;
        position: relative;
        width: 100px;
        height: 70px;
        content: '';
        background-repeat: no-repeat;
        background-position: center;
      }

      &.approach-1::before {
        background-image: url(https://s3.amazonaws.com/imse-qa-new/images/traing-1.png);
      }
      &.approach-2::before {
        background-image: url(https://s3.amazonaws.com/imse-qa-new/images/traing-2.png);
      }
      &.approach-3::before {
        background-image: url(https://s3.amazonaws.com/imse-qa-new/images/traing-3.png);
      }
      &.approach-4::before {
        background-image: url(https://s3.amazonaws.com/imse-qa-new/images/traing-4.png);
      }
      &.approach-5::before {
        background-image: url(https://s3.amazonaws.com/imse-qa-new/images/traing-5.png);
      }
    }
  }
}

div.relative-position {
  position: relative;

  .morphology-badge {
    position: absolute;
    top: -200px;
    left: 92px;
    background: #e5e5e5;
    padding: 15px 50px 20px 25px;
  }

  .morphology-badge-compog {
    position: absolute;
    top: -200px;
    right: -90px;
    background: #e5e5e5;
    padding: 15px 50px 20px 25px;
  }
}

/*-----------------------------------*\
  Custom CSS for program courses
\*-----------------------------------*/
.editor-2-column-container.program-course {

  .editor-2-column.program-course {
    align-items: center;

    & > div h1 {
      font-size: 32px;
    }

    & > div h1,
    & > div p {
      text-align: center;
    }

    & > div img {
      margin-bottom: 30px;
    }

    @media screen and (min-width: $tablet) {
      & > div h1 {
        font-size: 36px;
        line-height: 1.3;
      }

      & > div h1,
      & > div p {
        text-align: left;
      }

      & > div img {
        max-width: 80%;
        margin-bottom: 0;
      }

      & > div:first-child {
        width: 40%;
        text-align: center;
        margin: 0 auto;
      }
      & > div:last-child {
        width: 60%;
      }
    }
  }
}

