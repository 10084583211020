/*-----------------------------------*\
	MEDIA QUERIES IN CORRECT ORDER
\*-----------------------------------*/
div {
	@media screen and (min-width: $phoneold) {
		box-sizing: inherit;
	}

	@media screen and (min-width: $phone) {
		box-sizing: inherit;
	}

	@media screen and (min-width: $tablet) {
		box-sizing: inherit;
	}

	@media screen and (min-width: $laptop) {
		box-sizing: inherit;
	}

	@media screen and (min-width: $desktop) {
		box-sizing: inherit;
	}
}

/*-----------------------------------*\
	HTML/BODY
\*-----------------------------------*/
html {
	scroll-behavior: smooth;
	box-sizing: border-box;
	color: var(--GreyDark);
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.618;
	font-family: 'Karla', sans-serif;
	background: var(--White);
	overflow-x: hidden;
}

body {
	overflow-x: hidden;

	&.loading {
		&:before {
			content: " ";
			width: 100%;
			height: 100%;
			position: fixed;
			top: 0;
			left: 0;
			background: #65656552;
			display: block;
			z-index: 10000000;
		}
		&:after {
			content: "";
			width: 48px;
			height: 48px;
			position: fixed;
			top: calc(50% - 24px);
			left: calc(50% - 24px);
			background-image: url(https://imse-production-010821.s3.us-east-1.amazonaws.com/images/dots-spinner.svg);
			background-repeat: no-repeat;
			background-size: 100%;
			z-index: 10000001;
		}
	}

	height: 100vh;
}

html,
body {
	margin: 0;
	padding: 0;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

*:focus {
	outline: 1px solid var(--Blue);
	box-shadow: 0 0 5px var(--Blue);
}

/*-----------------------------------*\
	MAIN & ARTICLE
\*-----------------------------------*/
main {
	position: relative;
	max-width: 100vw;
	padding: 5px 0;

	> article {
		margin: var(--xLarge) auto;

		> *:first-child {
			margin-top: 0;
		}

		> *:last-child {
			margin-bottom: 0;
		}
	}
}

/*-----------------------------------*\
	SECTION SIZES
\*-----------------------------------*/
.standard {
	width: 90%;
	max-width: 960px;
	margin-right: auto;
	margin-left: auto;
}

.is-narrow {
	width: 90%;
	max-width: 640px;
	margin-right: auto;
	margin-left: auto;
}

.is-wide {
	width: 90%;
	max-width: 1024px;
	margin-right: auto;
	margin-left: auto;
}

.is-extra-wide {
	width: 90%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.is-full-width {
	width: 100%;
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;
}

section {
	margin: var(--xLarge) auto;
	z-index: 4;

	> *:first-child {
		margin-top: 0;
	}

	> *:last-child {
		margin-bottom: 0;
	}
}

/*-----------------------------------*\
	DEFAULT WP CLASSES
\*-----------------------------------*/
.alignright {
	text-align: right;
}

.alignleft {
	text-align: left;
}

.aligncenter {
	text-align: center;
}

img {
	max-width: 100%;
	height: auto;
}

img.alignright {
	display: block;
	float: right;
	max-width: 100%;
	height: auto;
}

img.alignleft {
	display: block;
	float: left;
	max-width: 100%;
	height: auto;
}

img.aligncenter {
	display: block;
	max-width: 100%;
	height: auto;
	margin: 1em auto;
}

.wp-caption {
	margin-bottom: 1.5em;
	padding-top: 5px;
	text-align: center;
}

.wp-caption img {
	margin: 0;
	padding: 0;
	border: 0 none;
}

.wp-caption p.wp-caption-text {
	margin: 0;
	font-size: 10px;
	line-height: 1.5;
}

.wp-smiley {
	max-height: 1em;
	margin: 0 !important;
}

/*-----------------------------------*\
	SKIP NAV
\*-----------------------------------*/
.skip-to-content {
	z-index: 999999;
	position: absolute;
	top: 0;
	left: 0;
	transition: all 0.35s linear;
	transform: translateY(-100%);

	&:focus {
		transform: translateY(0);
	}
}

/*-----------------------------------*\
	E5 CREDIT
\*-----------------------------------*/
#element5-credit {
	position: relative;

	a {
		position: absolute;
		right: 0;
		transform: translateY(-125%);
	}
}

.map-text-popup {
	width: 60em !important;
	padding: 0 !important;
	.swal2-html-container {
		margin: 0;
	}
	.swal2-close {
		margin-top: -30px;
		margin-right: -38px;
		background: #ffffff;
		min-width: 38px;
		height: 38px;
		border-radius: 50%;
		padding: 0;
		font-size: 2rem;
		color: #0a5b99;
		font-weight: 100;
		&:hover {
			background: inherit;
		    color: inherit;
		    opacity: 0.9;
		}
	}
}

.auth-popup {
	width: 40em !important;
	padding: 0 !important;

	.swal2-html-container {
		margin: 0;
	}

	h3 {
		color: var(--Black);
		text-align: center;
	}

	.swal2-close {
		margin-top: -30px;
		margin-right: -38px;
		background: #ffffff;
		min-width: 38px;
		height: 38px;
		border-radius: 50%;
		padding: 0;
		font-size: 2rem;
		color: #0a5b99;
		font-weight: 100;
		&:hover {
			background: inherit;
		    color: inherit;
		    opacity: 0.9;
		}
	}

	button {
		margin: 24px 0 0 0;
	}
}

.mapTextWrapper {
	padding: var(--Medium) var(--xLarge) var(--Large);
	text-align: left;

	h3 {
		color: var(--BlueDark);
    font-weight: bold;
	}

	img {
		width: 180px;
		margin-right: var(--xLarge);
	}

	ul {
		text-align: left;
		padding-left: var(--Medium);
	}

	.d-flex {
		display: flex;
    align-items: center;
		flex-direction: row;

		@media screen and (max-width: $tablet) {
			flex-direction: column;
		}
	}

	.button {
		margin: var(--Medium) auto 0;
	}
}

.textWrapper {
	padding: var(--Large);
	text-align: left;
	display: flex;
	flex-direction: column;

	img {
		margin: auto;
	}

	h3 {
		text-align: center;
		font-size: 24px;
		font-family: 'Karla', sans-serif;
		margin-top: var(--Medium);
		line-height: 1.3;
		color: var(--BlueDark);
	}

	h4 {
		text-align: center;
		color: var(--Black);
		margin-top: 0;
	}

	.d-flex {
		display: flex;
		justify-content: space-between;
	}
	
	&.security-modal {
		padding: var(--Large) var(--xLarge) var(--Large);

		h3 {
			margin-bottom: var(--Large);
		}

		.d-flex {
			display: flex;
	    align-items: center;
			flex-direction: row;
			justify-content: space-between;

			@media screen and (max-width: $tablet) {
				flex-direction: column;
			}
		}
	}
	
	&.security-modal {
		padding: var(--Large) var(--xLarge) var(--Large);

		h3 {
			margin-bottom: var(--Large);
		}

		.d-flex {
			display: flex;
	    align-items: center;
			flex-direction: row;
			justify-content: space-between;

			@media screen and (max-width: $tablet) {
				flex-direction: column;
			}
		}
	}
}

.field-item {
	position: relative;

	input {
		padding-right: 68px !important;
	}

	.char-count {
		font-size: 0.9em;
		color: grey;
		margin-left: 10px;
		position: absolute;
		right: 24px;
		top: 42px;
	}
}

video,
iframe {
  width: 100%;
  height: auto;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

table.basic {
	margin: 0;

	th,
	td {
		padding-right: 2vw;
	}
}

.flexbox {
	display: flex;
	flex-direction: column;
	width: 100%;

	@media screen and (min-width: $tablet) {
		flex-direction: row;
	}

	> div {
		flex: 1;
	}
}

.jqvmap-label
{
	position: absolute;
	display: none;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background: #292929;
	color: white;
	font-family: sans-serif, Verdana;
	font-size: smaller;
	padding: 3px;
  pointer-events:none;
}
.jqvmap-pin {
  pointer-events:none;
}
.jqvmap-zoomin, .jqvmap-zoomout
{
	position: absolute;
	left: 10px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background: #000000;
	padding: 3px;
	color: white;
	width: 10px;
	height: 10px;
	cursor: pointer;
	line-height: 10px;
	text-align: center;
}
.jqvmap-zoomin
{
	top: 10px;
}
.jqvmap-zoomout
{
	top: 30px;
}
.jqvmap-region
{
  cursor: pointer;
}
.jqvmap-ajax_response
{
  width: 100%;
  height: 500px;
}
@media screen and (max-width: $tablet) {
	.textWrapper {		
		.d-flex {
			flex-direction: column;
		}
	}	
}