.conversion-form {
    background-image: url(../images/header-blue-bg1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    position: relative;
    margin-top: -136px;
    padding-top: 110px;

    
    @media screen and (max-width: $laptop) {
        background-size: cover;
    }

    .header-wave2 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    .text_sidebar {
        section.card-grid {
            padding-top: 130px;

            @media screen and (max-width: $laptop) {
                padding-top: 0;
            }
        }
        .flex-center {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        form {
            margin: 0;
            width: 100%;
            .field-item {
                margin-bottom: 10px;
            }
            .field-item.submit {
                text-align: center;
                button {
                    margin-top: 20px;
                    margin-bottom: 0;
                    font-weight: bold !important;
                }
            }
        }
    }

    &.imse-preview-guide {
        background-image: url(../images/header-blue-bg2.png);
    }
    &.imse-morph-preview-guide {
        background-image: url(../images/header-purple-bg.png);
    }
}

.start-section {
    padding-bottom: 40px;
}
