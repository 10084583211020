/*----------------------------------------------------------------*\
	SEARCH RESULTS
\*----------------------------------------------------------------*/
body.search-results {
  section.card-grid .card {
    padding: 32px 32px 52px 32px;

    &::before {
      display: none;
    }

    p {
      margin: 0 0 var(--Large) 0;
      word-break: break-word;
    }

    span.search-term {
      padding: 0 var(--xSmall);
      border-radius: 3px;
      font-weight: 600;
      background-color: var(--Yellow);
    }

    a.button {
      z-index: 2;
      position: relative;
      margin-bottom: 0;
    }
  }
}
