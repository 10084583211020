/*----------------------------------------------------------------*\
	ARCHIVE TRAINING TEMPLATE
\*----------------------------------------------------------------*/
body.page-template-archive-training {
	main {
		section.main-section-1 {
			.sec1-div2 {
                top: 0;
                position: sticky;
                z-index: 2;
                width: calc(100% + 20px);
                margin: 0 -10px;
                padding: 1.6rem;
                background: #ffffff;

                &.is-sticky {
                    box-shadow: 0 2px 4px #d9d9d9;
                }

				@media screen and (max-width: $phone) {
					position: relative;
				}
            }
			form {
				display: grid;
				grid-template-columns: 1fr;
				grid-template-rows: auto;
				grid-gap: 8px;
				width: 100%;
				max-width: 100%;

				@media screen and (min-width: $phone) {
					grid-template-columns: 1.8fr 1fr 1fr 1fr 1fr;
					grid-gap: 16px;
				}
			}

			ul {
				margin: var(--Large) 0 0 0;
				padding: 0;
				list-style: none;

				> li {
					display: grid;
					position: relative;
					grid-template-columns: 1fr;
					grid-template-rows: auto;
					grid-gap: 0;
					justify-content: flex-start;
					align-items: center;
					margin: 0 0 24px 0;
					padding: 0;
					list-style: none;
					font-weight: 800;
					box-shadow: none;

					&.is-featured {
						border: 3px solid var(--Blue);
					}

					&:before {
						display: none;
					}

					&:first-child {
						display: none;

						@media screen and (min-width: $tablet) {
							display: grid;
							grid-template-columns: 2.5fr 1.5fr 1fr 1.5fr 1fr;
						}

						> div {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							height: 100%;
							padding: 12px 14px;
							text-align: left;
						}
					}

					&:not(:first-child):hover, &:not(:first-child):focus {
						box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
						transform: scale(0.995);

						> a > div {
							&:first-child {
								text-decoration: underline;
							}
						}
					}

					> a {
						display: grid;
						position: relative;
						grid-template-columns: 1fr;
						grid-template-rows: auto;
						grid-gap: 0;
						justify-content: flex-start;
						align-items: center;
						margin: 0;
						padding: 0;
						border-left: 8px solid var(--Grey);
						list-style: none;
						box-shadow: 0 7px 27px 0 rgba(0, 0, 0, 0.13);
						transition: all 0.15s linear;
						text-decoration: none;
						color: inherit;

						@media screen and (min-width: $tablet) {
							grid-template-columns: 2.5fr 1.5fr 1fr 1.5fr 1fr;
						}

						&:before {
							display: none;
						}

						&.is-comprehensive {
							border-color: var(--Blue);
						}
						
						&.is-phonological {
							border-color: var(--Yellow);
						}
							
						&.is-intermediate {
							border-color: var(--Purple);
						}
						
						&.is-educational {
							border-color: var(--Green);
						}
						
						> div {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							height: 100%;
							padding: 0 14px 8px 14px;
							text-align: left;
							font-weight: normal;

							@media screen and (min-width: $tablet) {
								padding: 18px 14px;
							}

							&:first-child {
								padding: 8px 14px;
								color: var(--Blue);
								font-weight: 600;

								@media screen and (min-width: $tablet) {
									padding: 18px 14px;
								}
							}

							&:nth-child(3) {
								font-weight: 600;
							}

							span {
								font-weight: 600;

								&.available {
									color: var(--Green);
								}

								&.wait {
									color: var(--Red);
								}

								&.featured {
									color: var(--White);
									background: var(--BlueDark);
									padding: 0 14px;
									border-radius: 2rem;
								}
							}
						}
					}
				}

				> a {
					// color: inherit;
					// text-decoration: none;

					// &:hover,
					// &:focus {
					// 	li {
					// 		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
					// 		transform: scale(0.995);

					// 		> div:first-child {
					// 			text-decoration: underline;
					// 		}
					// 	}
					// }

					li {
						display: grid;
						position: relative;
						grid-template-columns: 1fr;
						grid-template-rows: auto;
						grid-gap: 0;
						justify-content: flex-start;
						align-items: center;
						margin: 0 0 24px 0;
						padding: 0;
						border-left: 8px solid var(--Grey);
						list-style: none;
						box-shadow: 0 7px 27px 0 rgba(0, 0, 0, 0.13);
						transition: all 0.15s linear;

						@media screen and (min-width: $tablet) {
							grid-template-columns: 2.5fr 1.5fr 1fr 1.5fr 1fr;
						}

						&:before {
							display: none;
						}

						&.is-comprehensive {
							border-color: var(--Blue);
						}

						&.is-intermediate {
							border-color: var(--Purple);
						}

						> div {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							height: 100%;
							padding: 0 14px 8px 14px;
							text-align: left;

							@media screen and (min-width: $tablet) {
								padding: 18px 14px;
							}

							&:first-child {
								padding: 8px 14px;
								color: var(--Blue);
								font-weight: 600;

								@media screen and (min-width: $tablet) {
									padding: 18px 14px;
								}
							}

							&:nth-child(3) {
								font-weight: 600;
							}

							span {
								font-weight: 600;

								&.available {
									color: var(--Green);
								}

								&.wait {
									color: var(--Red);
								}
							}
						}
					}
				}
			}

			a.load-more {
				display: table;
				margin: var(--xLarge) auto;
			}
		}
	}
}

/*----------------------------------------------------------------*\
	SINGLE TRAINING TEMPLATE
\*----------------------------------------------------------------*/
body.page-template-single-training {
	header .price-card {
		position: relative;
		margin: 64px auto;

		&::before {
			display: block;
			z-index: -1;
			position: absolute;
			bottom: -211px;
			left: -354px;
			width: 500px;
			height: 500px;
			content: "";
			background-image: url(../images/sand.png);
			background-repeat: no-repeat;
			background-position: 50%;
			background-size: contain;
			transform: rotate(230deg);

			@media screen and (min-width: $phone) {
				bottom: -120px;
				left: -280px;
			}
		}

		&::after {
			display: block;
			z-index: -1;
			position: absolute;
			top: -160px;
			right: -321px;
			width: 500px;
			height: 500px;
			content: "";
			background-image: url(../images/sand.png);
			background-repeat: no-repeat;
			background-position: 50%;
			background-size: contain;
			transform: rotate(103deg);
		}

		.description .details {
			display: grid;
			grid-template-columns: 1fr;
			grid-gap: 0;

			@media screen and (min-width: $phone) {
				grid-template-columns: 1fr 1.5fr 1.5fr;
				grid-gap: 40px;
			}

			h2 {
				font-weight: 800;
				font-size: 16px;
				line-height: 1.1;
				font-family: 'Karla', sans-serif;
			}

			.button {
				max-width: 100%;
			}

			#loginPopup {
				display: none;
				z-index: 100;
				position: absolute;
				top: 190px;
				left: -11px;
				width: 100%;
				height: 100%;
				padding-top: 100px;
				overflow: inherit;
				background-color: none;

				.form-popup {
					z-index: 100;
					max-width: 230px;
					margin: 0 auto;
					border-radius: 3px;
					background: #fff;
					box-shadow: rgba(0, 0, 0, 0.4) 0 10px 26px;
					transition: transform 0.15s ease;
					transform: scale(0.98, 0.98) translateY(5px);

					-webkit-user-select: none;
					-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

					ul {
						padding: 10px !important;
						list-style: none;

						li {
							padding: 10px 5px;

							&::before {
								display: none;
							}

							a {
								color: #000;
								text-decoration: none;

								img {
									width: 19px;
									margin-right: 10px;
								}
							}
						}
					}
				}
			}
		}
	}

	main {
		display: grid;
		position: relative;
		grid-template-columns: 1fr;
		width: 90%;
		max-width: 1200px;
		margin: 0 auto;

		@media screen and (min-width: $tablet) {
			grid-template-columns: 2fr 1fr;
			grid-gap: 64px;
		}

		> article {
			margin-top: 0;

			p,
			p span,
			ul,
			ul li,
			ul li span {
				color: var(--GreyDark) !important;
				font-size: 16px !important;
				line-height: 1.618 !important;
				font-family: Karla, sans-serif !important;
			}

			form {
				max-width: 100%;
				margin: 0;

				.gfield_radio {
					display: flex;
					flex-wrap: wrap;

					li {
						margin-right: 24px;

						&:last-child {
							margin-right: 0;
						}
					}
				}

				button {
					margin-top: 32px;
				}
			}

			section.registration {
				padding-top: var(--xLarge);
				padding-bottom: var(--xLarge);

				.gform_wrapper {
					margin: 0;
				}

				#register {
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-start;

					label {
						width: 100%;
					}

					input {
						width: calc(100% - 150px);
						max-width: 100px;
						margin-right: 24px;
					}

					button {
						margin: 0;
					}
				}

				.stack-fields {
					display: flex;
    			flex-direction: column;
				}

				.button-group {
					display: flex;
					flex-direction: column;

					@media screen and (min-width: $tablet) {
						flex-direction: row;
						flex-wrap: wrap;
					}

					input {
						margin-top: 12px;

						@media screen and (min-width: $tablet) {
							flex: 1 0 46%;
						}
					}
				}
			}
		}
	}

	aside {
		.sidebar {
			top: 8em;
		}

		.essentialsBanner {
			transition: ease all 0.2s;

			@media screen and (max-width: $tablet) {
				display: none !important;
			}

		}
		
		.essentialsBanner img {
			width: 100%;
			max-width: 370px;
		}
		
		
		.card {
			position: relative;
			margin-bottom: 40px;
			padding: 0 32px 32px 32px;
			background-color: var(--White);
			box-shadow: 0 7px 27px 0 rgba(0, 0, 0, 0.13);

			&::before {
				display: block;
				position: relative;
				left: -32px;
				width: calc(100% + 64px);
				height: 24px;
				margin-bottom: 32px;
				content: '';
				background-image: url(../images/pattern_blue_small.jpg);
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
			}

			&:nth-child(2n)::before {
				background-image: url(../images/pattern_red_small.jpg);
			}

			&:nth-child(3n)::before {
				background-image: url(../images/pattern_yellow_small.jpg);
			}

			> *:first-child {
				margin-top: 0;
			}

			> *:last-child {
				margin-bottom: 0;
			}

			h2 {
				margin: 0;
				font-size: 28px;
			}

			.hours {
				margin: 0;
				font-weight: 300;
				font-size: 32px;
				line-height: 1.2;
				font-family: 'lexia', serif;
			}
		}

		.credits {
			p:first-of-type {
				color: var(--Grey);
			}

			p:last-child {
				color: var(--Grey);
				font-size: 14px;
			}
		}

		.share {
			img {
				width: auto;
				height: 72px;
			}

			.gform_wrapper {
				margin: 0;
			}
		}
	}
}

.comprehensive-accordions {
	.accordions details {
		border-color: var(--Blue) !important;
	}
}

.morphology-accordions {
	.accordions details {
		border-color: var(--Purple) !important;
	}
}

.phonological-accordions {
	.accordions details {
		border-color: var(--Yellow) !important;
	}
}

body.swal2-height-auto {
	padding-right: 0 !important;
}

.swal2-title {
    font-size: 1.3rem !important;
    font-family: 'Karla', sans-serif;
    line-height: 1.2;
    font-weight: initial !important;
    padding: 1.6rem 1.4rem 0 1.4rem !important;
    color: var(--BlueDark) !important;
}

.swal2-html-container {
	line-height: 1.4 !important;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel {
	background-color: #ffffff !important;
	padding: 8px;
	box-shadow: 0 4px 13px 0 #00000045;
	color: var(--BlueDark) !important;
	font-weight: 600;
	font-size: 0.875rem !important;
	&:hover {
		background-color: var(--BlueDark) !important;
		color: #ffffff !important;
	}
}

.video-player-popup {
	padding: 0 !important;
	width: 48rem !important;

	.swal2-html-container {
		margin: 0;
	}
	.swal2-close {
		margin-top: -30px;
		margin-right: -38px;
		background: #ffffff;
		min-width: 38px;
		height: 38px;
		border-radius: 50%;
		padding: 0;
		font-size: 2rem;
		color: #0a5b99;
		font-weight: 100;
		&:hover {
			background: inherit;
		    color: inherit;
		    opacity: 0.9;
		}
	}
}

.text-viewer-popup {
	padding: 0 !important;

	.swal2-html-container {
		margin: 0;
	}
	.swal2-close {
		margin-top: -30px;
		margin-right: -38px;
		background: #ffffff;
		min-width: 38px;
		height: 38px;
		border-radius: 50%;
		padding: 0;
		font-size: 2rem;
		color: #0a5b99;
		font-weight: 100;
		&:hover {
			background: inherit;
		    color: inherit;
		    opacity: 0.9;
		}
	}
}

.certification-levels {
	.level-item {
		display: flex;
		align-items: center;
		cursor: pointer;

		img {
		    position: relative;
		    left: 0px;
		    transition: left ease 0.3s;
		    z-index: 0;
		}

		.level-card {
			box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.27);
			padding: 6px 6px 10px 16px;
		    max-width: 250px;
	        min-width: 250px;
		    border-radius: 4px;
		    background: #ffffff;
		    z-index: 1;
		    position: relative;
		    &:before {
                content: " ";
                width: 13px;
                height: 13px;
                background: var(--Red);
                position: absolute;
                left: -7px;
                top: calc(50% - 5px);
                display: block;
                border-radius: 50%;
                border: 3.5px solid #ffc5c1;
		    }

            .d-flex {
                display: flex;
                width: 100%;
                justify-content: space-between;
                button {
                    margin: auto 0 0;
                    font-size: 12px;
                    padding: 6px 10px;
                    min-width: auto;
                    display: none;
                }
            }
		}

		.title {
			margin: 8px 0;
			line-height: 1;
		}

		ul {
			padding: 0;
			margin: 0;
			li {
				padding: 2px 0 2px 8px;
				font-size: 12px;
				line-height: 1;
				&::before {
					top: 7px;
				    left: 0;
				    width: 3px;
				    height: 3px;
				}
			}
		}

		&.red {
			img {
			    margin: 0px 0px 0px 80px;
			}
			.level-card {
			    margin-top: -14px;
                padding-bottom: 20px;
			}
			.title {
				color: var(--Red);
			}
			ul>li::before {
				background-color: var(--Red);
			}
		}
		&.blue {
			img {
		    	margin: 4px 0px 4px 41px;
			}
			.title {
				color: var(--BlueDark);
			}
			ul>li::before {
				background-color: var(--BlueDark);
			}
            .level-card {
                margin-top: 2px;
                &:before {
                    background: var(--BlueDark);
                    border-color: #b8d8f7;
                }
            }
		}
		&.green {
			.title {
				color: var(--Green);
			}
            .level-card {
                margin-top: -4px;
            }
			ul>li::before {
				background-color: var(--Green);
			}
            .level-card::before {
                background: var(--Green);
                border-color: #c8ff97;
            }
		}

		&:hover {
			img {
			    left: 25px;
			}
			.level-card {
				box-shadow: 0 4px 30px 0 #505050;
			}
            button {
                display: block !important;
            }
            .level-card:before {
                width: 18px;
                height: 18px;
                left: -10px;
                top: calc(50% - 8px);
            }
		}
	}
}

.accordion-btn-group {
	display: flex;
    flex-direction: row;
    justify-content: space-around;

    p {
	    display: flex;
	    flex-direction: column;

	    label {
			background: var(--BlueDark);
			color: #ffffff;
			padding: 5px 10px;
    	    margin: 0 4px;
	    }

	    a {
	    	margin-right: 0 !important;
	    }
    }
}

.waitlist-page {
	max-width: 1200px;

	h1 {
		text-align: center;
		font-size: 30px;
		font-weight: 500;
		font-family: 'Karla';
		margin-bottom: 36px;
	}

	.training__detail-label {
		font-weight: bold;
		border-bottom: 1px solid #D4D4D4;
		padding-bottom: 16px;
	}

	.training__detail {
		padding: 0;

		@media screen and (min-width: $tablet) {
			margin-top: 100px;
			display: flex;
			justify-content: space-between;
		}

		li {
			padding: 0;
			&:before {
				display: none;
			}
		}
	}

	.training__detail_schedule {
		margin-top: 50px;

		.training__detail-text {
			p:not(:first-child) {
				margin: 0;
			}
		}
	}

	.course-description {
		margin-top: 100px;

		@media screen and (min-width: $tablet) {
			.field-item {
			    display: flex;
			    align-items: center;

				label {
				    margin-bottom: 0;
		    		min-width: 125px;
				}
			}
		}

		.action-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 36px;

			a {
				color: #A8A8A8;
				font-weight: 500;
				padding: 8px;
			}

			button {
				margin-right: 0;
				padding: 11.8px;
				font-weight: 500;
				background-color: #2F86D8;
				color: #ffffff;
			}
		}

	}

}
