/*-----------------------------------*\
	CARDS
\*-----------------------------------*/
section.card-grid {
  & .card {
    display: flex;
    position: relative;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 24px 16px 24px;
    background-color: var(--White);
    box-shadow: 0 7px 27px 0 rgba(0, 0, 0, 0.13);

    @media screen and (min-width: $phone) {
      padding: 0 40px 40px 40px;
    }

    &::before {
      display: block;
      position: relative;
      left: -24px;
      width: calc(100% + 48px);
      height: 24px;
      margin-bottom: 32px;
      content: '';
      background-image: url(../images/pattern_red_small.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @media screen and (min-width: $phone) {
        left: -40px;
        width: calc(100% + 80px);
      }
    }

    &:nth-child(2n)::before {
      background-image: url(../images/pattern_yellow_small.jpg);
    }

    &:nth-child(3n)::before {
      background-image: url(../images/pattern_blue_small.jpg);
    }

    & > * {
      flex: 0 0 auto;
    }

    & > *:first-child {
      margin-top: 0;
    }

    & > *:last-child {
      margin-bottom: 0;
    }

    & h2 {
      font-size: 24px;

      @media screen and (min-width: $phone) {
        font-size: 28px;
      }
    }

    & .description {
      flex: 1 1 auto;
      margin-bottom: var(--Large);
    }

    & .button {
      margin: 0;

      &.is-text {
        display: table;
        margin: -20px 0 0;
        padding: 0;

        &:first-child {
          margin: 0;
        }
      }
    }

    & a.cover-link {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      &:hover,
      &:focus {
        & + div .button {
          color: var(--White);
          background-color: var(--BlueDark);

          &.is-text {
            color: var(--BlueDark);
            background-color: transparent;
          }
        }
      }
    }
  }

  &.image-top-cards {
    position: relative;

    &::before {
      display: block;
      z-index: -1;
      position: absolute;
      bottom: -150px;
      left: -200px;
      width: 500px;
      height: 500px;
      content: "";
      background-image: url(../images/sand.png);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: contain;
      transform: rotate(230deg);
    }

    &::after {
      display: block;
      z-index: -1;
      position: absolute;
      top: -200px;
      right: -200px;
      width: 500px;
      height: 500px;
      content: "";
      background-image: url(../images/sand.png);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: contain;
      transform: rotate(-123deg);
    }

    & .card {
      background-color: var(--GreyLight);

      &::before {
        display: none;
      }

      & figure {
        position: relative;
        left: -24px;
        width: calc(100% + 48px);
        margin: 0;

        @media screen and (min-width: $phone) {
          left: -40px;
          width: calc(100% + 80px);
        }

        & img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      & h2 {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 800;
        font-family: 'Karla', sans-serif;
        word-break: break-word;
      }

      & .button {
        margin: var(--Medium) var(--Medium) 0 0;

        @media screen and (min-width: $phone) {
          margin: 0 var(--Medium) 0 0;
        }
      }

      & a.cover-link {
        &:hover,
        &:focus {
          & + div .button {
            color: var(--White);
            background-color: var(--RedDark);

            &.is-text {
              color: var(--BlueDark);
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

/*-----------------------------------*\
	COLUMNS
\*-----------------------------------*/
section.card-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  margin: 96px auto;

  & .card {
    width: 100%;
    margin-right: 0;
    z-index: 4;
  }

  @media screen and (min-width: $tablet) {
    &.columns-2 {
      grid-template-columns: 1fr 1fr;
    }

    &.columns-3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

section.purple-ribbon {
  & .card {
    &:nth-child(n)::before {
      background-image: url(../images/pattern_yellow_small.jpg);
    }

    &:nth-child(2n)::before {
      background-image: url(../images/patter_purple.jpg.jpg);
    }

    &:nth-child(3n)::before {
      background-image: url(../images/pattern_blue_small.jpg);
    }
  }
}

section.fw-compact{
  grid-gap: 0 !important;
}
