.home {
    .header-action-wrapper p.subheader {
        max-width: 825px !important;
    }
    
    .cover {
        padding: var(--xLarge) var(--Large) var(--Large);

        &::before {
            left: -400px;
            width: 600px;
            height: 600px;
			transform: rotate(-6deg);
		}
    
        &::after {
            left: 740px;
            width: 600px;
            height: 600px;
			transform: scaleX(-1) rotate(173deg);
		}
    
        > div {
            max-width: 820px;
        }
    }

    .no-gray-background{
        &:before,
        &:after {
            bottom: -120px;
        }
    }

    .text-block{
        max-width: 550px;
    }

    section.card-grid.is-full-width {
            grid-gap: 0px;
            & .card {
                box-shadow: 0 7px 5px 0 rgba(0, 0, 0, 0.23);
                h2 {
                    align-self: center;
                }
                div{
                    text-align: center;
                    align-self: center;
                }

            }
    }
}