.type-ahead-suggestions {
  border: 1px solid var(--Grey);
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.type-ahead-suggestions div {
  padding: 16px 28px;
}

.type-ahead-suggestions div:not(:last-child) {
  border-bottom: 1px solid var(--Grey);
}

.type-ahead-suggestions div:hover, .type-ahead-suggestions div.type-ahead-suggestion-hover {
  color: var(--Blue);
  cursor: pointer;
}