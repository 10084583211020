body.cannot-scroll,
html.cannot-scroll {
	overflow: hidden;
}

/*-----------------------------------*\
	PRIMARY MOBILE NAV
\*-----------------------------------*/
.primary-navigation-mobile {
	display: block;
	z-index: 1;
	position: relative;
	background-color: white;

	@media screen and (min-width: $tablet) {
		display: none;
	}

	&::before {
		display: block;
		width: 100%;
		height: 24px;
		content: '';
		background-image: url(../images/pattern_yellow_wide.jpg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	img.logo {
		display: block;
		width: auto;
		height: 64px;

		@media screen and (min-width: $phoneold) {
			height: 80px;
		}

		@media screen and (min-width: $phone) {
			height: 88px;
		}
	}

	nav {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		width: 96vw;
		margin: -12px 0 0 0;

		@media screen and (min-width: $phone) {
			margin: -16px 0 0 0;
		}

		& > div {
			display: flex;
		}
		
		&>div a {
			display: inline-block;
			margin-left: var(--Small);
			border-radius: 50%;
			text-decoration: none;

			&.has-items {
				position: relative;

				&::before {
					display: block;
					position: absolute;
					top: 13px;
					right: 14px;
					left: auto;
					width: 8px;
					height: 8px;
					margin: 0;
					border-radius: 50%;
					content: "";
					background-color: var(--Red);
				}
			}

			img {
				width: 48px;
				height: 48px;

				@media screen and (min-width: $phone) {
					width: 64px;
					height: 64px;
				}
			}
		}

		button {
			min-width: 0;
			margin: 0;
			margin-left: var(--Small);
			padding: 0;
			border-radius: 50%;
			text-decoration: none;
			background-color: transparent;
			box-shadow: none;
			display: flex;

			img {
				width: 48px;
				height: 48px;

				@media screen and (min-width: $phone) {
					width: 64px;
					height: 64px;
				}

				&:last-child {
					display: none;
				}
			}

			&.is-opened {
				img:first-child {
					display: none;
				}

				img:last-child {
					display: block;
				}
			}
		}
	}
}

/*-----------------------------------*\
	STICKY MOBILE NAV
\*-----------------------------------*/
.sticky-navigation-mobile {
	display: block;
	z-index: 9999999;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	background-color: hsla(0, 0%, 100%, 0.85);
	transition: all 0.2s linear;
	transform: translateY(-100%);

	@media screen and (min-width: $tablet) {
		display: none;
	}

	&.is-stuck {
		transform: translateY(0);
	}

	img.logo {
		display: block;
		width: auto;
		height: 64px;

		@media screen and (min-width: $phoneold) {
			height: 80px;
		}

		@media screen and (min-width: $phone) {
			height: 88px;
		}
	}

	nav {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		width: 96vw;
		margin: 0;
		padding: var(--Small) 0;

		> div a {
			margin-left: var(--Small);
			border-radius: 50%;
			text-decoration: none;

			img {
				width: 48px;
				height: 48px;

				@media screen and (min-width: $phone) {
					width: 64px;
					height: 64px;
				}
			}
		}

		button {
			min-width: 0;
			margin: 0;
			margin-left: var(--Small);
			padding: 0;
			border-radius: 50%;
			text-decoration: none;
			background-color: transparent;
			box-shadow: none;

			img {
				width: 48px;
				height: 48px;

				@media screen and (min-width: $phone) {
					width: 64px;
					height: 64px;
				}

				&:last-child {
					display: none;
				}
			}

			&.is-opened {
				img:first-child {
					display: none;
				}

				img:last-child {
					display: block;
				}
			}
		}
	}
}

/*-----------------------------------*\
	OPEN NAV
\*-----------------------------------*/
.active-mobile-menu {
	z-index: 100;
	position: fixed;
	top: 116px;
	right: 0;
	left: 0;
	max-height: 0;
	padding: 0;
	overflow: hidden;
	background-color: hsla(0, 0%, 100%, 0.95);
	transition: all 0.25s linear;

	@media screen and (min-width: $phoneold) {
		top: 130px;
	}

	@media screen and (min-width: $phone) {
		top: 132px;
	}

	@media screen and (min-width: $tablet) {
		display: none;
	}

	&.is-opened {
		display: block;
		max-height: calc(100vh - 84px);
		overflow: scroll;

		@media screen and (min-width: $phone) {
			max-height: calc(100vh - 100px);
		}
	}

	.search {
		max-width: 500px;
		margin: var(--Medium) auto 0 auto;
		padding: 0 var(--Medium);

		form {
			position: relative;
			max-width: 100%;

			input {
				padding: var(--xSmall) var(--Large) var(--Small) var(--Small);
				border-bottom: 1px solid var(--Grey);
				border-radius: 0;
				font-weight: 400;
				background-color: transparent;
			}

			button {
				position: absolute;
				top: 0;
				right: 0;
				min-width: 0;
				margin: 0;
				padding: 0;
				background-color: transparent;
				box-shadow: none;

				img {
					width: 24px;
					height: 24px;
				}
			}
		}
	}

	nav {
		max-width: 500px;
		margin: 0 auto;
		padding: 0 var(--Medium);

		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			margin: 24px 0;

			> li {
				width: calc(50% - 8px);
				margin: var(--Small) 0;

				&.menu-item-has-children {
					width: 100%;
					background-color: var(--White);
					box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.27);

					> a {
						position: relative;
						text-align: left;
						box-shadow: none;

						&:after {
							display: inline-block;
							position: absolute;
							top: 50%;
							right: var(--Small);
							width: 32px;
							height: 32px;
							content: '';
							background-image: url(../images/select_arrow.svg);
							background-repeat: no-repeat;
							background-position: center;
							background-size: contain;
							transition: all 0.15s linear;
							transform: translateY(-50%);
						}
					}
				}
			}

			a {
				display: block;
				padding: var(--Medium);
				border-radius: 4px;
				color: var(--BlueDark);
				font-weight: 800;
				font-size: 16px;
				font-family: Karla, sans-serif;
				text-align: center;
				text-decoration: none;
				cursor: pointer;
				background-color: var(--White);
				box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.27);
				transition: all 0.2s linear;
			}

			ul.sub-menu {
				position: relative;
				top: -2px;
				max-height: 0;
				margin: 0;
				overflow: hidden;

				li {
					width: 100%;
					margin: 0;

					h3 {
						margin: auto;
						padding: 16px var(--Medium);
						font-weight: 800;
						font-size: 16px;
						font-family: Karla, sans-serif;
						color: #2f86d8;
						background: #f3f3f3;
						margin-bottom: -2px;
						z-index: 1;
						position: relative;
						border-top: 1px solid var(--Grey);
					}

					a {
						padding: 12px var(--Medium);
						border-top: 1px solid var(--Grey);
						border-radius: 0;
						color: var(--Black);
						text-align: left;
						text-transform: capitalize;
						background-color: transparent;
						box-shadow: none;
					}
				}
			}

			li.is-opened {
				ul.sub-menu {
					max-height: 100vh;
					overflow: scroll;
				}

				a::after {
					transform: translateY(-50%) rotate(-180deg);
				}
			}
		}
	}

	.login {
		display: flex;
		justify-content: center;
		padding: var(--Large) 40px;
		background-color: var(--Blue);
		margin-bottom: 32px;

		a.button {
			position: relative;
			margin: 0;

			span {
				position: absolute;
				top: -4px;
				right: 0;
				left: 0;
				color: var(--White);
				font-weight: 400;
				transform: translateY(-100%);
			}
		}
	}
}