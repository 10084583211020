/*-----------------------------------*\
	TUTOR LIST
\*-----------------------------------*/
section.tutor-list {
    margin: var(--Small) auto var(--Large);

	label {
		margin-right: var(--Small);
	}

	form {
		text-align: center;
	}

	select {
		width: auto;
		min-width: 200px;
		margin-bottom: var(--Large);
	}

	.tutor {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		grid-gap: var(--Medium);
		align-items: center;
		width: 100%;
		padding: var(--Small) var(--Medium);
		border-bottom: 2px solid var(--GreyLight);

		@media screen and (min-width: $phone) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
			grid-template-rows: 1fr;
		}

		p {
			margin: 0;

			&.email,
			&.cert {
				white-space: nowrap;
			}
		}

		a.button {
			min-width: 0;
			margin: 0;
			padding: 0;
		}
	}

	#no-results {
		padding: var(--Medium);
		border-radius: 4px;
		background-color: var(--White);
		box-shadow: 0 7px 27px 0 rgba(0, 0, 0, 0.13);
	}
}