/* Flexnav Base Styles */

$nav_text: #0A5B99;
$top_level: #E4E4E4;
$second_level: #E4E4E4;
$third_level: #E4E4E4;
$fourth_level: #E4E4E4;

.flexnav {
	-webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  transition: none;
	-webkit-transform-style: preserve-3d; // stop webkit flicker
  overflow: hidden;
	margin: 0 auto;
	width: 100%;
  max-height: 0;
  
  &.opacity {
    opacity: 0;
  }
	
	&.flexnav-show {
		position: absolute;
		box-shadow: 0 20px 15px rgba(0, 0, 0, 0.4);
		margin: 10px -15px;
		z-index: 9;
		width: calc(100% + 30px);
		max-height: 2000px;
    opacity: 1;
		-webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
	}
	&.one-page {
		position: fixed;
		top: 50px;
		right: 5%;
		max-width: 200px;
	}
	
	li {
		font-size: 100%;
		position: relative;
		overflow: hidden;

		&.item-with-ul {
			background-color: #E4E4E4;
		}

		&.active a {
			background-color: $nav_text;
			color: white;
		}
	}
	li a {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: .96em;
		z-index: 2;
		overflow: hidden;
		font-size: 15px;
		text-transform: none;
		color: $nav_text;
		background: #E4E4E4;
		border-bottom: 1px solid #0A5B99;

		&.group {
			padding-left: 15px;
			color: rgba(0, 0, 0, 0.54);
		}

		&.active {
			background-color: $nav_text;
			color: white;
		}
	}
	li ul {
		width: 100%;   
		
		li {
			font-size: 100%;
			position: relative;
			overflow: hidden;		
		}
	}
	li ul.flexnav-show {
		li {
			overflow: visible;

			&.active a {
				background-color: $nav_text;
				color: white;
			}
		}
	}	
	
	li ul li a {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: $second_level;
	}
	ul li ul li a {
		background: $third_level;
	}
	ul li ul li ul li a {
		background: $fourth_level;
	}
	.touch-button {
		position: absolute;
		z-index: 999;
		top: 0;
		right: 0;
		width: 50px;
		height: 50px;
		display: inline-block;
		text-align: center;
		&:hover {
			cursor: pointer;
		}
		.navicon {
			position: relative;
			top: 1.4em;
			font-size: 12px;
			color: #666;
		}
	}
}
.menu-button {
	position: relative;
	display: block;
	padding: 0.6em 1em;
	color: white;
	background-color: $nav_text;
	transition: 0.2s all;
	cursor: pointer;
	border-bottom: 1px solid rgba(0,0,0,.2);

	.touch-button .navicon {
		color: white;
	}

	&.active {
		background: $top_level;
		color: $nav_text;

		.touch-button .navicon {
			color: $nav_text;
		}
	}
	
	&.one-page {
		position: fixed;
		top: 0;
		right: 5%;
		padding-right: 45px;
	}
	
	.touch-button {
		background: transparent;
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		width: 50px;
		height: 45px;
		display: inline-block;
		text-align: center;
				
		.navicon {
			font-size: 16px;
			position: relative;
			top: 0.6em;
		}
	}
}

@media all and (min-width: 800px) {
	body.one-page {
		padding-top: 70px;
	}
	.flexnav {
		overflow: visible;
		&.opacity {
		  opacity: 1;
		}
		&.one-page {
			top: 0;
			right: auto;
			max-width: 1080px;
		}
		li {
			position: relative;
			list-style: none;
			float: left;
			display: block;	
			background-color: #a6a6a2;
			overflow: visible;
      width: 20%;
		}
		li a {
			border-left: 1px solid #acaca1;
			border-bottom: none;
		}
		li > ul {
			position: absolute; 
			top: auto;
			left: 0;
			
			li {
				width: 100%;
			}
		}
		li ul li > ul {
			margin-left: 100%;
			top: 0;
		}
		li ul li:hover > ul,
		li ul li > ul.flexnav-show {
		}		
		li ul li a {
			border-bottom: none;
		}
		li ul.open {
			display: block;
			opacity: 1;
			visibility: visible;
			z-index: 1;
			li {
				overflow: visible;
				max-height: 100px;
			}	
			ul.open {
				margin-left: 100%;
				top: 0;
			}
		}
	}
	.menu-button {
		display: none;
	}
}

.oldie {
	body.one-page {
		padding-top: 70px;
	}
	.flexnav {
		overflow: visible;
		&.one-page {
			top: 0;
			right: auto;
			max-width: 1080px;
		}
		li {
			position: relative;
			list-style: none;
			float: left;
			display: block;	
			background-color: #a6a6a2;	
      width: 20%;
			min-height: 50px;
			overflow: visible;
		}
		li:hover > ul { 
			display: block;
			width: 100%;
			overflow: visible;
			li {
				width: 100%;
				float: none;
			}
		}
		li a {
			border-left: 1px solid #acaca1;
			border-bottom: none;
			overflow: visible;	
		}
		li > ul {
			background:#acaca1;
			position: absolute; 
			top: auto;
			left: 0;
			display: none;
			z-index: 1;
			overflow: visible;
		}
		li ul li ul { 
			top: 0;		
		}
		li ul li a {
			border-bottom: none;
		}
		li ul.open {
			display: block;
			width: 100%;
			overflow: visible;
			li {
				width: 100%;
			}
			ul.open {
				margin-left: 100%;
				top: 0;
  				display: block;
				width: 100%;
				overflow: visible;
			}
		}
		ul li:hover ul {
			margin-left: 100%;
			top: 0;
		}
	}
	.menu-button {
		display: none;
	}
	&.ie7 .flexnav li {
		width: 19.9%;
	}
}